<template>
  <div class="row row-sm text-start mt-5">
    <div class="col-lg-12">
      <div class="py-2 col-12 row">
        <div class="col-12 col-md-6">
          <div class="py-2 d-flex align-items-center">
            <h2 class="main-content-label mb-1">Api Keys</h2>
          </div>
        </div>
        <div
          class="col-12 col-md-6 d-flex justify-content-end align-items-center"
        >
          <button type="button" class="btn btn-primary" @click="generateApi">
            Generate Api
          </button>
        </div>
      </div>
      <div class="card custom-card overflow-hidden">
        <div class="card-body">
          <div class="table-responsive export-table">
            <table
              id="datatable"
              class="table table-bordered text-nowrap key-buttons border-bottom"
            >
              <thead>
                <tr class="text-center">
                  <th class="border-bottom-0">Token</th>
                  <th class="border-bottom-0">Created Date</th>
                  <th class="border-bottom-0">Control</th>
                </tr>
              </thead>
              <tbody v-if="loading">
                <tr class="old">
                  <td valign="top" colspan="3" class="dataTables_empty">
                    <SpinnerLoader :start="loading" />
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="Object.values(getApiKeys).length">
                <tr
                  class="text-center"
                  v-for="apiKey in getApiKeys"
                  :key="apiKey.id"
                >
                  <td>
                    <input
                      :id="'myinput_' + apiKey.id"
                      type="text"
                      style="display: none"
                      readonly
                      :value="apiKey.api_key"
                    />
                    {{ apiKey.api_key.slice(0, 20) + "..." }}

                    <div class="toolpop">
                      <i
                        class="fas fa-copy"
                        @click="copy(apiKey.id)"
                        @mouseover="defineCopy(apiKey.id)"
                        @mouseout="backToOrigin(apiKey.id)"
                        ><span
                          class="toolpoptext"
                          :id="'myToolpop_' + apiKey.id"
                          >Copy to clipboard</span
                        ></i
                      >
                    </div>
                  </td>
                  <td>
                    {{ apiKey.created_at.split(".")[0].replace("T", " ") }}
                  </td>
                  <td>
                    <a
                      href="javascript:void(0)"
                      class="mr-3"
                      @click="deletecurrentApi(apiKey.id)"
                      ><i class="fas fa-trash"></i
                    ></a>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr class="old">
                  <td valign="top" colspan="3" class="dataTables_empty">
                    No matching records found
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scope>
.custom-switch-indicator {
  background: #ccc;
}
.custom-switch-input:checked ~ .custom-switch-indicator {
  background: var(--primary-bg-color);
}
.toolpop {
  position: relative;
  display: inline-block;
}

.toolpop .toolpoptext {
  visibility: hidden;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 11px;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.toolpop .toolpoptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.pop {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}
</style>
<script>
import SpinnerLoader from "@/components/SpinnerLoader.vue";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "bootstrap";

var $ = require("jquery");
// @ is an alias to /src
export default {
  name: "ApiKeys",
  components: {
    SpinnerLoader,
  },
  data() {
    return {
      successMessage: "",
      serverError: [],
      loading: true,
    };
  },
  created() {
    this.$store.dispatch("changeNavActive", "api-keys");
    document.title = "ProtoAnalysis";
    this.$store.dispatch("retrieveApiKeys");
    $("#datatable").DataTable();
  },
  computed: {
    getApiKeys() {
      return this.$store.getters.getApiKeys;
    },
  },
  watch: {
    getApiKeys() {
      this.loading = false;
    }
  },
  methods: {
    deletecurrentApi(api_id) {
      if (confirm("Are You sure that you want delete this api key?!")) {
        this.loading = true;
        this.$store
          .dispatch("deleteApiKey", {
            id: api_id,
          })
          .then((response) => {
            this.$notify({ type: "success", text: response.message[0] });
            this.$store.dispatch("retrieveApiKeys").then(() => {
              this.loading = false;
            });
          })
          .catch((error) => {
            this.loading = false;
            var serverError =
              error.response.data.message == null
                ? [error.response.data.errors]
                : [error.response.data.message];
            this.$notify({ type: "wronge", text: serverError });
          });
      }
    },
    generateApi() {
      this.loading = true;
      this.$store
        .dispatch("addApiKey")
        .then((response) => {
          this.$notify({ type: "success", text: response.message[0] });
          this.$store.dispatch("retrieveApiKeys");
        })
        .catch((error) => {
          this.loading = false;
          var serverError =
            error.response.data.message == null
              ? [error.response.data.errors]
              : [error.response.data.message];
          this.$notify({ type: "wronge", text: serverError });
        });
    },
    copy(ind) {
      var text = document.getElementById("myinput_" + ind).value;
      var listener = function (ev) {
        ev.clipboardData.setData("text/plain", text);
        ev.preventDefault();
      };
      document.addEventListener("copy", listener);
      document.execCommand("copy");
      document.removeEventListener("copy", listener);
      var toolpop = document.getElementById("myToolpop_" + ind);
      toolpop.innerHTML = "Copied: " + text.slice(0, 10);
      toolpop.style.visibility = "visible";
      toolpop.style.opacity = 1;
    },
    defineCopy(ind) {
      var toolpop = document.getElementById("myToolpop_" + ind);
      toolpop.innerHTML = "Copy to clipboard";
      toolpop.style.visibility = "visible";
      toolpop.style.opacity = 1;
    },
    backToOrigin(ind) {
      var toolpop = document.getElementById("myToolpop_" + ind);
      toolpop.style.visibility = "hidden";
      toolpop.style.opacity = 0;
    },
  },
};
</script>
