<template>
  <div class="d-flex justify-content-center align-items-center" v-if="start">
    <img
      src="./../assets/img/preloader.gif"
      style="width: 300px"
      alt="Loader"
    />
  </div>
</template>
<style scope>
.spin {
  z-index: 1;
  height: 200px;
  position: relative;
  background-color: #fff;
}
.ring {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 100%;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #1155f4 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
<script>
export default {
  name: "SpinnerLoader",
  props: ["start"],
};
</script>
