<template>
  <div :class="
    (isAdmin ? 'col-xl-10' : 'col-xl-12') +
    ' col-lg-12 col-md-12 col-sm-12 px-0'
  ">
    <div class="card px-3 ps-4">
      <div class="card-header pb-0">
        <div class="d-flex justify-content-between">
          <h3 class="card-title mg-b-0">Kyc Applicant Overview</h3>
        </div>
      </div>
      <div class="card-body px-0 pb-0" v-if="visible">
        <div :class="'row index1 ' + (isAdmin ? '' : 'justify-content-center')">
          <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xxl-3" v-if="getKycApplicantOverView.APPROVED != undefined">
            <div class="row border-end border-top bd-xs-e-0 p-3">
              <div class="col-12 py-0">
                <div class="row d-flex align-items-center justify-content-center">
                  <div class="col-12">
                    <h6 class="mb-2 pb-3 tx-12">
                      <span class="approve-color status-style"></span>APPROVED
                    </h6>
                    <div class="d-flex pb-3 justify-content-center">
                      <h2 class="tx-18 font-weight-semibold mb-0">
                        {{
                          getKycApplicantOverView.APPROVED.count > 0
                          ? parseFloat(
                            getKycApplicantOverView.APPROVED.percentage
                          ).toFixed(1) + "%"
                          : getKycApplicantOverView.APPROVED.percentage
                        }}
                      </h2>
                    </div>
                  </div>
                  <div class="col-12 pb-0 mt-0">
                    <router-link :to="(isAdmin ? '/admin' : '') + '/kyc?status=APPROVED'"
                      class="d-flex pb-2 justify-content-center badge bg-success-transparent text-success font-weight-semibold ms-auto rounded-pill lh-maincard"
                      style="font-size: 14px"><i class="fa fa-caret-up me-1"></i>View
                      {{ getKycApplicantOverView.APPROVED.count }}
                      applicant</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xxl-3" v-if="getKycApplicantOverView.REJECTED != undefined">
            <div class="row border-end border-top bd-xs-e-0 p-3">
              <div class="col-12 py-0">
                <div class="row d-flex align-items-center justify-content-center">
                  <div class="col-12">
                    <h6 class="mb-2 pb-3 tx-12">
                      <span class="rejected-color status-style"></span>REJECTED
                    </h6>
                    <div class="d-flex pb-3 justify-content-center">
                      <h2 class="tx-18 font-weight-semibold mb-0">
                        {{
                          getKycApplicantOverView.REJECTED.count > 0
                          ? parseFloat(
                            getKycApplicantOverView.REJECTED.percentage
                          ).toFixed(1) + "%"
                          : getKycApplicantOverView.REJECTED.percentage
                        }}
                      </h2>
                    </div>
                  </div>
                  <div class="col-12 pb-0 mt-0">
                    <router-link :to="(isAdmin ? '/admin' : '') + '/kyc?status=REJECTED'"
                      class="d-flex pb-2 justify-content-center badge bg-success-transparent text-success font-weight-semibold ms-auto rounded-pill lh-maincard"
                      style="font-size: 14px"><i class="fa fa-caret-up me-1"></i>View
                      {{ getKycApplicantOverView.REJECTED.count }}
                      applicant</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xxl-3" v-if="getKycApplicantOverView.RETRY != undefined">
            <div class="row border-end border-top bd-xs-e-0 p-3">
              <div class="col-12 py-0">
                <div class="row d-flex align-items-center justify-content-center">
                  <div class="col-12">
                    <h6 class="mb-2 pb-3 tx-12">
                      <span class="retry-color status-style"></span>RETRY
                    </h6>
                    <div class="d-flex pb-3 justify-content-center">
                      <h2 class="tx-18 font-weight-semibold mb-0">
                        {{
                          getKycApplicantOverView.RETRY.count > 0
                          ? parseFloat(
                            getKycApplicantOverView.RETRY.percentage
                          ).toFixed(1) + "%"
                          : getKycApplicantOverView.RETRY.percentage
                        }}
                      </h2>
                    </div>
                  </div>
                  <div class="col-12 pb-0 mt-0">
                    <router-link :to="(isAdmin ? '/admin' : '') + '/kyc?status=RETRY'"
                      class="d-flex pb-2 justify-content-center badge bg-success-transparent text-success font-weight-semibold ms-auto rounded-pill lh-maincard"
                      style="font-size: 14px"><i class="fa fa-caret-up me-1"></i>View
                      {{ getKycApplicantOverView.RETRY.count }}
                      applicant</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xxl-3"
            v-if="getKycApplicantOverView.PROCESSING != undefined">
            <div class="row border-top bd-xs-e-0 p-3">
              <div class="col-12 py-0">
                <div class="row d-flex align-items-center justify-content-center">
                  <div class="col-12">
                    <h6 class="mb-2 pb-3 tx-12">
                      <span class="processing-color status-style"></span>PROCESSING
                    </h6>
                    <div class="d-flex pb-3 justify-content-center">
                      <h2 class="tx-18 font-weight-semibold mb-0">
                        {{
                          getKycApplicantOverView.PROCESSING.count > 0
                          ? parseFloat(
                            getKycApplicantOverView.PROCESSING.percentage
                          ).toFixed(1) + "%"
                          : getKycApplicantOverView.PROCESSING.percentage
                        }}
                      </h2>
                    </div>
                  </div>
                  <div class="col-12 pb-0 mt-0">
                    <router-link :to="(isAdmin ? '/admin' : '') + '/kyc?status=PROCESSING'"
                      class="d-flex pb-2 justify-content-center badge bg-success-transparent text-success font-weight-semibold ms-auto rounded-pill lh-maincard"
                      style="font-size: 14px"><i class="fa fa-caret-up me-1"></i>View
                      {{ getKycApplicantOverView.PROCESSING.count }}
                      applicant</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xxl-3"
            v-if="getKycApplicantOverView.PRECHECKED != undefined">
            <div class="row border-top bd-xs-e-0 p-3">
              <div class="col-12 py-0">
                <div class="row d-flex align-items-center justify-content-center">
                  <div class="col-12">
                    <h6 class="mb-2 pb-3 tx-12">
                      <span class="prechecked-color status-style"></span>PRECHECKED
                    </h6>
                    <div class="d-flex pb-3 justify-content-center">
                      <h2 class="tx-18 font-weight-semibold mb-0">
                        {{
                          getKycApplicantOverView.PRECHECKED.count > 0
                          ? parseFloat(
                            getKycApplicantOverView.PRECHECKED.percentage
                          ).toFixed(1) + "%"
                          : getKycApplicantOverView.PRECHECKED.percentage
                        }}
                      </h2>
                    </div>
                  </div>
                  <div class="col-12 pb-0 mt-0">
                    <router-link :to="(isAdmin ? '/admin' : '') + '/kyc?status=PRECHECKED'"
                      class="d-flex pb-2 justify-content-center badge bg-success-transparent text-success font-weight-semibold ms-auto rounded-pill lh-maincard"
                      style="font-size: 14px"><i class="fa fa-caret-up me-1"></i>View
                      {{ getKycApplicantOverView.PRECHECKED.count }}
                      applicant</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xxl-3"
            v-if="getKycApplicantOverView.REQUIRES_ACTION != undefined">
            <div class="row border-top bd-xs-e-0 p-3">
              <div class="col-12 py-0">
                <div class="row d-flex align-items-center justify-content-center">
                  <div class="col-12">
                    <h6 class="mb-2 pb-3 tx-12">
                      <span class="requires-action-color status-style"></span>REQUIRES ACTION
                    </h6>
                    <div class="d-flex pb-3 justify-content-center">
                      <h2 class="tx-18 font-weight-semibold mb-0">
                        {{
                          getKycApplicantOverView.REQUIRES_ACTION.count > 0
                          ? parseFloat(
                            getKycApplicantOverView.REQUIRES_ACTION.percentage
                          ).toFixed(1) + "%"
                          : getKycApplicantOverView.REQUIRES_ACTION.percentage
                        }}
                      </h2>
                    </div>
                  </div>
                  <div class="col-12 pb-0 mt-0">
                    <router-link :to="(isAdmin ? '/admin' : '') + '/kyc?status=REQUIRES_ACTION'"
                      class="d-flex pb-2 justify-content-center badge bg-success-transparent text-success font-weight-semibold ms-auto rounded-pill lh-maincard"
                      style="font-size: 14px"><i class="fa fa-caret-up me-1"></i>View
                      {{ getKycApplicantOverView.REQUIRES_ACTION.count }}
                      applicant</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xxl-3" v-if="getKycApplicantOverView.PENDING != undefined">
            <div class="row border-top bd-xs-e-0 p-3">
              <div class="col-12 py-0">
                <div class="row d-flex align-items-center justify-content-center">
                  <div class="col-12">
                    <h6 class="mb-2 pb-3 tx-12">
                      <span class="pending-color status-style"></span>PENDING
                    </h6>
                    <div class="d-flex pb-3 justify-content-center">
                      <h2 class="tx-18 font-weight-semibold mb-0">
                        {{
                          getKycApplicantOverView.PENDING.count > 0
                          ? parseFloat(
                            getKycApplicantOverView.PENDING.percentage
                          ).toFixed(1) + "%"
                          : getKycApplicantOverView.PENDING.percentage
                        }}
                      </h2>
                    </div>
                  </div>
                  <div class="col-12 pb-0 mt-0">
                    <router-link :to="(isAdmin ? '/admin' : '') + '/kyc?status=PENDING'"
                      class="d-flex pb-2 justify-content-center badge bg-success-transparent text-success font-weight-semibold ms-auto rounded-pill lh-maincard"
                      style="font-size: 14px"><i class="fa fa-caret-up me-1"></i>View
                      {{ getKycApplicantOverView.PENDING.count }}
                      applicant</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xxl-3"
            v-if="getKycApplicantOverView.DOCUMENTS_REQUESTED != undefined">
            <div class="row border-top bd-xs-e-0 p-3">
              <div class="col-12 py-0">
                <div class="row d-flex align-items-center justify-content-center">
                  <div class="col-12">
                    <h6 class="mb-2 pb-3 tx-12">
                      <span class="document-requested-color status-style"></span>DOCUMENTS REQUESTED
                    </h6>
                    <div class="d-flex pb-3 justify-content-center">
                      <h2 class="tx-18 font-weight-semibold mb-0">
                        {{
                          getKycApplicantOverView.DOCUMENTS_REQUESTED.count > 0
                          ? parseFloat(
                            getKycApplicantOverView.DOCUMENTS_REQUESTED.percentage
                          ).toFixed(1) + "%"
                          : getKycApplicantOverView.DOCUMENTS_REQUESTED.percentage
                        }}
                      </h2>
                    </div>
                  </div>
                  <div class="col-12 pb-0 mt-0">
                    <router-link :to="
                      (isAdmin ? '/admin' : '') + '/kyc?status=DOCUMENTS_REQUESTED'
                    "
                      class="d-flex pb-2 justify-content-center badge bg-success-transparent text-success font-weight-semibold ms-auto rounded-pill lh-maincard"
                      style="font-size: 14px"><i class="fa fa-caret-up me-1"></i>View
                      {{ getKycApplicantOverView.DOCUMENTS_REQUESTED.count }}
                      applicant</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SpinnerLoader :start="!visible" />
    </div>
  </div>
</template>
<style>
.status-style {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 6px;
  height: 6px;
  vertical-align: middle;
  border-radius: 50%;
  margin-right: 5px;
}
.approve-color {
  background-color: #19bc9b;
}
.rejected-color {
  background-color: #ef5656;
}
.retry-color {
  background-color: #ef8b53;
}
.processing-color {
  background-color: #41495b99;
}
.prechecked-color {
  background-color: #41495b99;
}
.requires-action-color {
  background-color: #9077d8;
}
.pending-color {
  background-color: #eea70d;
}
.document-requested-color {
  background-color: #41495b99;
}

</style>
<script>
import SpinnerLoader from "@/components/SpinnerLoader.vue";
export default {
  name: "KycApplicantOverview",
  components: {
    SpinnerLoader,
  },
  props: {
    clientId: Number,
  },
  data() {
    return {
      visible: true,
      filters: {
      }
    };
  },
  computed: {
    getKycApplicantOverView() {
      return this.$store.getters.getKycApplicantOverView;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
  watch: {
    clientId() {
      this.filters['client_id'] = this.clientId;
      this.$store.dispatch("retrieveKycApplicantOverView", {
        filters: this.filters,
      });
    },
    getKycApplicantOverView() {
      this.visible = true;
    },
  },
  mounted() {
    this.visible = false;
    this.filters['client_id'] = this.clientId;
    this.$store.dispatch("retrieveKycApplicantOverView", {
      filters: this.filters,
    });
  },
};
</script>
