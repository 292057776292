<template>
  <div
    class="modal fade"
    id="editWebHookModal"
    tabindex="-1"
    aria-labelledby="editWebHookModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="editWebHookModalLabel">Edit WebHook</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form action="#" @submit.prevent="editWebHook">
            <div v-if="successMessage" class="success-message">
              {{ successMessage }}
            </div>
            <div v-if="serverError.length > 0" class="server-error">
              <ul
                style="list-style-type: none; margin: 0px; padding: 0px"
                v-for="(item, index) in serverError"
                :key="index"
              >
                <li>{{ item }}</li>
              </ul>
            </div>

            <div cass="mb-3 text-start">
              <label for="hook_secret_key" class="col-form-label">Secret Key:</label>
              <input
                type="text"
                class="form-control"
                id="hook_secret_key"
                name="hook_secret_key"
                placeholder="Enter Secret Key"
                v-model="hook_secret_key"
                required
              />
            </div>
            <div class="mb-3 text-start">
              <label for="hook_link" class="col-form-label">Link:</label>
              <input
                type="text"
                class="form-control"
                id="hook_link"
                name="hook_link"
                placeholder="Enter Link"
                v-model="hook_link"
                required
              />
            </div>

            <div class="modal-footer">
              <button
                id="closeEditModal"
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                :disabled="!buttonVisiable"
                class="btn btn-primary"
              >
                Edit
              </button>
              <div></div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
var $ = require("jquery");
export default {
  name: "EditWebHook",
  props: {
    webHook: { type: Object, required: true },
  },
  data() {
    return {
      hook_id: 0,
      hook_secret_key: "",
      hook_link: "",
      successMessage: "",
      serverError: [],
      buttonVisiable: true,
    };
  },
  watch: {
    webHook() {
      this.hook_id = this.webHook.id;
      this.hook_secret_key = this.webHook.secret_key;
      this.hook_link = this.webHook.link;
    },
  },
  methods: {
    editWebHook() {
      this.buttonVisiable = false;
      this.$store
        .dispatch("editWebHook", {
          id: this.hook_id,
          secret_key: this.hook_secret_key,
          link: this.hook_link,
        })
        .then((response) => {
          this.successMessage = response.message[0];
          setTimeout(() => {
            this.successMessage = "";
            this.$emit("refresh", true);
            $("#closeEditModal").click();
          }, 2000);
          this.buttonVisiable = true;
        })
        .catch((error) => {
          if (Array.isArray(error.response.data.message))
            this.serverError = error.response.data.message;
          else if (error.response.data.message)
            this.serverError = [error.response.data.message];
          else if (typeof error.response.data.errors == "string")
            this.serverError = [error.response.data.errors];
          else this.serverError = Object.values(error.response.data.errors);
          setTimeout(() => {
            this.serverError = [];
          }, 2000);
          this.buttonVisiable = true;
        });
    },
  },
};
</script>
