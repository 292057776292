<template>
  <div class="row row-sm text-start mt-5">
    <div class="col-lg-12">
      <div class="py-2 col-12 row">
        <div class="col-12 col-md-6 d-flex justify-content-start">
          <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <h2 class="main-content-label mb-1">Error Logs</h2>
            </div>
          </nav>
        </div>
      </div>
      <div class="card custom-card overflow-hidden">
        <div class="card-body">
          <div class="table-responsive export-table">
            <PaginationComponent
              :page="this.filters.page"
              :limit="this.filters.limit"
              :getTotalRows="this.getTotalErrorLogs"
              :nameComponent="this.nameComponent"
              @changePage="filterChanged"
            />
            <!-- pagination and total -->
            <!-- table -->
            <DataTableErrorLog
              :getErrorLogs="this.getErrorLogs"
              :visible="visible"
            />

            <!-- pagination and total -->
            <PaginationComponent
              :page="this.filters.page"
              :limit="this.filters.limit"
              :getTotalRows="this.getTotalErrorLogs"
              :nameComponent="this.nameComponent"
              @changePage="filterChanged"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scope>
.assignment_icon {
  font-size: 30px !important;
  color: #cbcbcb !important;
}
.wrap_item_name {
  white-space: pre-wrap;
}
.select_whithout_arrow {
  background-image: none !important;
  font-size: 13px;
  font-weight: 900;
}
.select_whithout_arrow option {
  font-size: 13px;
}
.dropdown-scrollbar {
  height: 150px;
  overflow: scroll;
}
.btn-paging {
  background-color: #e1e1e1;
  border: 1px solid #aaa;
  font-weight: 600;
}

.btn-paging:hover {
  background-color: #fff;
}
.total_rows {
  font-size: 14px;
  color: #858f99;
}
.dt-button {
  background-color: #e5e5e5;
  border: 1px solid #d7d3d3;
  color: #7e7d7d;
  border-radius: 8px;
  font-weight: 100;
  font-size: 13px;
}
.dt-button:hover {
  background-color: #fff;
  color: #7e7d7d;
}
</style>

<script>
import DataTableErrorLog from "@/components/ErrorLogs/DataTableErrorLog.vue";
import PaginationComponent from "@/components/PaginationComponent.vue";

export default {
  name: "AdminErrorLogs",
  components: {
    DataTableErrorLog,
    PaginationComponent,
  },
  data() {
    return {
      filters: {
        page: 1,
        limit: 50,
      },
      visible: false,
      nameComponent: "ErrorLogs",
    };
  },
  computed: {
    getErrorLogs() {
      return this.$store.getters.getErrorLogs;
    },
    getTotalErrorLogs() {
      return this.$store.getters.getTotalErrorLogs;
    },
  },
  mounted() {
    document.title = "ProtoAML";
    this.$store.dispatch("changeNavActive", "error-logs");
    this.$store.dispatch("retrieveErrorLogs", {
        filters: this.filters,
      });
  },
  methods: {
    filterChanged(value) {
      this.visible = !this.visible;
      this.filters[value[0]] = value[1];
      if(value[0] != 'page') {
        this.filters['page'] = 1;  
      }
      this.$store.dispatch("retrieveErrorLogs", {
        filters: this.filters,
      });
    },
  },
};
</script>
