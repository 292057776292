<template>
  <div class="table-responsive export-table">
    <table
      id="datatable_clients"
      class="table table-bordered text-nowrap key-buttons border-bottom"
      style="text-align: center"
    >
      <thead>
        <tr class="text-center">
          <th class="border-bottom-0">Company Name</th>
          <th class="border-bottom-0">Company Email</th>
          <th class="border-bottom-0">Alerts</th>
          <th class="border-bottom-0">Transections</th>
          <th class="border-bottom-0">Access</th>
          <th class="border-bottom-0">Status</th>
          <th class="border-bottom-0">Control</th>
        </tr>
      </thead>
      <tbody v-if="loading">
        <tr class="old">
          <td valign="top" colspan="7" class="dataTables_empty">
            <SpinnerLoader :start="loading" />
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="Object.values(getClients).length > 0">
        <tr class="text-center" v-for="client in getClients" :key="client.id">
          <td>{{ client.name }}</td>
          <td>{{ client.email }}</td>
          <td>0</td>
          <td>0</td>
          <td v-if="client.roles.length > 0">
            <span v-for="item in client.roles" :key="item.id" class="access">{{
              item.name.toUpperCase()
            }}</span>
          </td>
          <td v-else>—</td>
          <td>
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                :checked="client.active == 1"
                @click="changeActive(client.id)"
              />
            </div>
          </td>
          <td>
            <a
              href="#"
              class="mr-3"
              data-bs-toggle="modal"
              data-bs-target="#editClientModal"
              @click="currentClient(client)"
              ><i class="fas fa-edit"></i
            ></a>
            <a
              href="#"
              class="mr-3"
              data-bs-toggle="modal"
              data-bs-target="#ClientLevelsModal"
              @click="currentClient(client)"
              ><img
                src="../../assets/img/promotions.png"
                style="width: 21px; margin-left: 10px"
            /></a>
            <a
              href="#"
              class="mr-3"
              style="margin-left: 10px"
              data-bs-toggle="modal"
              data-bs-target="#showClientModalKeys"
              @click="currentClient(client)"
              ><i class="fas fa-key"></i
            ></a>
            <a
              :href="'/admin/switch/'+client.id"
              class="mr-3"
              target="_blank"
              style="margin-left: 10px"
              >
              <i class="fa-solid fa-repeat"></i></a>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr class="old">
          <td valign="top" colspan="7" class="dataTables_empty">
            No matching records found
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<style scoped>
.dataTables_scrollBody {
  min-height: 130px;
}
</style>
<script>
import SpinnerLoader from "@/components/SpinnerLoader.vue";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "bootstrap";

var $ = require("jquery");
export default {
  name: "DataTableClient",
  props: {
    refreshTableNow: Boolean,
    getClients: Object,
  },
  components: {
    SpinnerLoader,
  },
  data() {
    return {
      table: null,
      loading: true,
    };
  },
  watch: {
    getClients() {
      if (this.table == null)
        setTimeout(() => {
          this.table = $("#datatable_clients").DataTable({
            scrollX: true,
          });
        }, 100);
      this.loading = false;
    },
    refreshTableNow() {
      this.loading = true;
    },
  },
  methods: {
    currentClient(client) {
      this.$emit("currentClient", client);
    },
    changeActive(id) {
      this.$store
        .dispatch("changeActive", {
          id: id,
        })
        .then((response) => {
          this.$notify({ type: "success", text: response.message[0] });
        })
        .catch((error) => {
          var serverError =
            error.response.data.message == null
              ? [error.response.data.errors]
              : [error.response.data.message];
          this.$notify({ type: "wronge", text: serverError });
        });
    },
  },
};
</script>
