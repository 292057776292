<template>
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Add Client</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form action="#" @submit.prevent="storeClient">
            <div v-if="successMessage" class="success-message">
              {{ successMessage }}
            </div>
            <div v-if="serverError.length > 0" class="server-error">
              <ul
                style="list-style-type: none; margin: 0px; padding: 0px"
                v-for="(item, index) in serverError"
                :key="index"
              >
                <li>{{ item }}</li>
              </ul>
            </div>
            <div class="mb-3 text-start">
              <label for="client_name" class="col-form-label">Name:</label>
              <input
                type="text"
                class="form-control"
                id="client_name"
                name="client_name"
                placeholder=" Enter Name"
                v-model="client_name"
                required
              />
            </div>
            <div class="mb-3 text-start">
              <label for="client_email" class="col-form-label">Email:</label>
              <input
                type="email"
                class="form-control"
                id="client_email"
                name="client_email"
                placeholder="Enter Email"
                v-model="client_email"
                required
              />
            </div>
            <div class="mb-3 text-start">
              <label for="client_prefix" class="col-form-label">Prefix:</label>
              <input
                type="text"
                class="form-control"
                id="client_prefix"
                name="client_prefix"
                placeholder=" Enter Prefix"
                v-model="client_prefix"
                required
              />
            </div>
            <div class="mb-3 text-start">
              <label
                for="client_withdraw_alert_threshold"
                class="col-form-label"
                >Withdraw Alert Threshold (USD):</label
              >
              <input
                type="number"
                class="form-control"
                id="client_withdraw_alert_threshold"
                name="client_withdraw_alert_threshold"
                placeholder=" Enter Withdraw Alert Threshold"
                v-model="client_withdraw_alert_threshold"
              />
            </div>
            <div class="mb-3 text-start">
              <label for="client_deposit_alert_threshold" class="col-form-label"
                >Deposit Alert Threshold (USD):</label
              >
              <input
                type="number"
                class="form-control"
                id="client_deposit_alert_threshold"
                name="client_deposit_alert_threshold"
                placeholder=" Enter Deposit Alert Threshold"
                v-model="client_deposit_alert_threshold"
              />
            </div>

            <div class="mb-3 text-start">
              <label for="client_alert_type" class="col-form-label"
                >Alert Type:</label
              >
              <Select2Component @selectValue="getSelectValue" />
            </div>

            <div class="mb-3 text-start">
              <label for="client_provider_key" class="col-form-label"
                >Provider Key:</label
              >
              <input
                type="text"
                class="form-control"
                id="client_provider_key"
                name="client_provider_key"
                placeholder=" Enter Provider Key"
                v-model="client_provider_key"
                required
              />
            </div>
            <div class="row" v-if="Object.values(getRoles).length">
              <label for="client_provider_key" class="col-form-label col-12"
                >Permission:</label
              >
              <div
                v-for="(item, index) in getRoles"
                :key="index"
                class="col-lg-4"
              >
                <label class="ckbox"
                  ><input type="checkbox" :id="'role_' + index" /><span>{{
                    item.name
                  }}</span></label
                >
              </div>
            </div>

            <div class="modal-footer">
              <button
                id="closeAddModal"
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                :disabled="!buttonVisiable"
                class="btn btn-primary"
              >
                Add Client
              </button>
              <div></div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var $ = require("jquery");
import Select2Component from "@/components/Select2Component.vue";
//var vue_select = require("vue-select2");
export default {
  name: "AddClient",
  components: {
    Select2Component,
  },
  computed: {
    getRoles() {
      return this.$store.getters.getRoles;
    },
  },
  data() {
    return {
      client_name: "",
      client_email: "",
      client_prefix: "",
      client_withdraw_alert_threshold: "",
      client_deposit_alert_threshold: "",
      client_alert_type: [],
      client_provider_key: "",
      client_roles: [],
      successMessage: "",
      serverError: [],
      buttonVisiable: true,
    };
  },
  methods: {
    getSelectValue(value) {
      this.client_alert_type = Object.values(value);
    },
    storeClient() {
      this.client_roles = [];
      for (var i = 0; i < Object.values(this.getRoles).length; i++) {
        if ($("#role_" + i).is(":checked")) {
          this.client_roles.push(this.getRoles[i].id);
        }
      }
      this.buttonVisiable = false;
      this.$store
        .dispatch("storeClient", {
          name: this.client_name,
          email: this.client_email,
          prefix: this.client_prefix,
          withdraw_alert_threshold: this.client_withdraw_alert_threshold,
          deposit_alert_threshold: this.client_deposit_alert_threshold,
          alert_types: this.client_alert_type,
          provider_key: this.client_provider_key,
          client_roles: this.client_roles,
        })
        .then((response) => {
          this.successMessage = response.message[0];
          setTimeout(() => {
            this.client_name = "";
            this.client_email = "";
            this.client_prefix = "";
            this.client_withdraw_alert_threshold = "";
            this.client_deposit_alert_threshold = "";
            this.client_alert_type = [];
            this.client_provider_key = "";
            this.client_roles = [];
            this.successMessage = "";
          }, 2000);
          this.buttonVisiable = true;
          this.$emit("refresh", true);
          $("#closeAddModal").click();
        })
        .catch((error) => {
          if (Array.isArray(error.response.data.message))
            this.serverError = error.response.data.message;
          else if (error.response.data.message)
            this.serverError = [error.response.data.message];
          else if (typeof error.response.data.errors == "string")
            this.serverError = [error.response.data.errors];
          else this.serverError = Object.values(error.response.data.errors);
          setTimeout(() => {
            this.serverError = [];
          }, 2000);
          this.buttonVisiable = true;
        });
    },
  },
};
</script>