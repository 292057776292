import axios from "axios";
import router from "../router";

//axios.defaults.baseURL = "http://192.168.1.140/api";
// axios.defaults.baseURL = "http://localhost:81/api";
axios.defaults.baseURL = "https://openapi.dash.protocol33.io/api";

axios.interceptors.response.use(undefined, error => {
  if (error.response === undefined  || error.response.status === 401) {
    // Handle unauthorized error
    // You could redirect the user to the login page or show an error message
    return router.push({
      name: "logout",
    });
  }
  // Return a rejected Promise to trigger the catch block
  return Promise.reject(error);
});