import axios from "axios";
import route from "../router/index";

/* Helpers */
function defaultSetup(context) {
  axios.defaults.headers.common["Authorization"] =
    "Bearer " + context.state.token;
  axios.defaults.headers.common["Accept"] = "application/json";
}
function filtering(credentials) {
  let query = ""
  if(credentials != undefined) {
    let filters = {
      ...credentials.filters,
      ...credentials.param,
    }
    filters = Object.keys(filters != undefined ? filters : []).map((key) => {
      if (filters[key] != 0 && filters[key] != "" && filters[key] != null)
        return `${key}=${encodeURIComponent(filters[key])}`
    });
    filters = filters.filter((el) => el != undefined)
    query = "?" + filters.join("&")
  }
  return query
}
function getURL(is_admin, requestName) {
  return is_admin ? ("/admins/" + requestName) : ("/clients/" + requestName)
}

const actions = {
  /* Auth */
  retrieveToken(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .post("/" + credentials.type + "/login", {
          email: credentials.email,
          password: credentials.password,
        })
        .then((response) => {
          const token = response.data.body.token;
          const type = "" + response.data.body.type + "s";
          const roles = response.data.body.roles;
          const offM = response.data.body.off_menu;
          context.commit("retrieveToken", [token, type, roles, offM]);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  destroyToken(context) {
    defaultSetup(context)
    if (context.getters.loggedIn) {
      return new Promise((resolve, reject) => {
        axios
          .post("/" + context.state.type + "/logout")
          .then((response) => {
            context.commit("destroyToken");
            resolve(response.data);
          })
          .catch((error) => {
            context.commit("destroyToken");
            reject(error);
          });
      });
    }
  },
  switchToClient(context, credentials) {
    /* you can do logout from exists client */
    axios
      .post("/admins/switch", {
        clientId: credentials.clientId,
      })
      .then((response) => {
        const token = response.data.body.token;
        const type = "" + response.data.body.type + "s";
        const roles = response.data.body.roles;
        context.commit("switchToClient", [token, type, roles]);
        if (context.getters.accessKyt) context.dispatch('changeModule', "KYT");
        else if (context.getters.accessKyc) context.dispatch('changeModule', "KYC");
        //context.dispatch('retrieveProfile');
        route.push({
          name: "dashboard",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  },
  backToAdmin(context, credentials) {
    defaultSetup(context)
    if (context.getters.loggedIn) {
      axios
        .post("/clients/logout")
        .then(() => {
          context.commit("backToAdmin");
          if (credentials && credentials.backBy == "close-button")
            route.push({
              name: "admin-clients",
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },

  /* Password + Profile */
  sendEmail(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .post("/users/password/forgot", {
          email: credentials.email,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  resetPassword(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .post("/users/password/reset", {
          token: credentials.token_reset,
          email: credentials.email,
          password: credentials.password,
          password_confirmation: credentials.confirm_password,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updatePassword(context, credentials) {
    defaultSetup(context)
    return new Promise((resolve, reject) => {
      axios
        .put("/password/update", {
          current_password: credentials.current_password,
          new_password: credentials.new_password,
          new_password_confirmation: credentials.new_password_confirmation,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  retrieveProfile(context) {
    defaultSetup(context)
    axios
      .get("/profile")
      .then((response) => {
        context.commit("retrieveProfile", response.data.body);
      })
      .catch((error) => {
        console.log(error)
      });
  },

  /* Client Section */
  storeClient(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .post("/admins/clients", {
          name: credentials.name,
          email: credentials.email,
          prefix: credentials.prefix,
          withdraw_alert_threshold: credentials.withdraw_alert_threshold,
          deposit_alert_threshold: credentials.deposit_alert_threshold,
          alert_types: credentials.alert_types,
          api_key: credentials.provider_key,
          roles: credentials.client_roles,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  editClient(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .put("/admins/clients/" + credentials.id, {
          name: credentials.name,
          email: credentials.email,
          prefix: credentials.prefix,
          withdraw_alert_threshold: credentials.withdraw_alert_threshold,
          deposit_alert_threshold: credentials.deposit_alert_threshold,
          alert_types: credentials.alert_types,
          api_key: credentials.provider_key,
          roles: credentials.client_roles,
          new_password: credentials.new_password,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  changeActive(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .put("/admins/clients/" + credentials.id + "/active")
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  retrieveClients(context, credentials) {
    defaultSetup(context)
    const query = filtering(credentials)
    axios
      .get("/admins/clients" + query)
      .then((response) => {
        context.commit("retrieveClients", response.data.body.clients);
      })
      .catch((error) => {
        console.log(error)
      });
  },
  retrieveClientLevels(context, credentials) {
    defaultSetup(context)
    axios
      .get("/admins/clients/" + credentials.clientId + "/kyc/levels")
      .then((response) => {
        context.commit("retrieveClientLevels", response.data.body.levels);
      })
      .catch((error) => {
        console.log(error)
      });
  },
  changeActiveClientLevelApplicant(context, credentials) {
    defaultSetup(context)
    return new Promise((resolve, reject) => {
      axios
        .put("/admins/clients/kyc/" + credentials.levelId + "/active")
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  addKycLevelToClient(context, credentials) {
    defaultSetup(context)
    return new Promise((resolve, reject) => {
      axios
        .post("/admins/clients/" + credentials.clientId + "/kyc", {
          name: credentials.name,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  retrieveRoles(context) {
    defaultSetup(context)
    axios
      .get("/admins/roles")
      .then((response) => {
        context.commit("retrieveRoles", response.data.body.roles);
      })
      .catch((error) => {
        console.log(error)
      });
  },
  retrieveLevels(context) {
    defaultSetup(context)
    axios
      .get("/levels")
      .then((response) => {
        context.commit("retrieveLevels", response.data.body.levels);
      })
      .catch((error) => {
        console.log(error)
      });
  },

  /* Alert Section */
  retrieveAlerts(context, credentials) {
    defaultSetup(context)
    const query = filtering(credentials);
    const url = getURL(this.getters.isAdmin, 'alerts');
    axios
      .get(url + query)
      .then((response) => {
        var allAlerts = response.data.body.alerts;
        var totalAlert = response.data.body.total;
        if (
          credentials.param &&
          credentials.param["status"] &&
          credentials.param["status"] == 0
        ) {
          allAlerts = allAlerts.filter((allAlert) => allAlert.status == 0);
          totalAlert = allAlerts.length;
        }
        context.commit("retrieveAlerts", [allAlerts, totalAlert]);
      })
      .catch((error) => {
        console.log(error)
      });
  },
  changeStatusAlerts(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .post("/admins/alerts/" + credentials.id + "/" + credentials.new_status)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  retrieveAllAudit(context, credentials) {
    defaultSetup(context)
    axios
      .get("/admins/alerts/" + credentials.alertId + "/audit")
      .then((response) => {
        context.commit("retrieveAllAudit", response.data.body[0]);
      })
      .catch((error) => {
        console.log(error)
      });
  },
  sendAssignRequestAlert(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .post("/admins/alerts/" + credentials.alertId + "/assign", {
          user_id: credentials.user_id,
          comment: credentials.comment,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  sendReviewRequestAlert(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .post("/admins/alerts/" + credentials.alertId + "/review", {
          status: credentials.status,
          comment: credentials.comment,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  retrieveAllAdmin(context) {
    defaultSetup(context)
    axios
    .get("/admins")
    .then((response) => {
      //console.log(response.data.body[0]);
      context.commit("retrieveAllAdmin", response.data.body[0]);
    })
    .catch((error) => {
      console.log(error)
    });
  },
  exportAllAlerts(context,credentials) {
    defaultSetup(context)
    const query = filtering(credentials);
    return new Promise((resolve, reject) => {
      axios.get('/admins/alerts/export'+query, { responseType: 'arraybuffer' })
    .then((response) => {
      // Create a new Blob object from the CSV data
      const blob = new Blob([response.data], { type: 'text/csv' });

      // Create a new link and set its href and download attributes
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      const date = new Date();
      const full_date = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear() + '-' + date.getHours() + '_' + date.getMinutes() + '_' + date.getSeconds();
      link.download = 'ProtoAnlysis-AlertsExport-'+full_date+'.csv';

      // Append the link to the DOM and trigger a click event to download the CSV file
      document.body.appendChild(link);
      link.click();
      resolve(response)
    })
    .catch((error) => {
      reject(error)
    });
  });
  },

  /* User Section */
  changeKycRiskUser(context, credentials) {
    defaultSetup(context)
    return new Promise((resolve, reject) => {
      axios
        .post("/admins/kyc/client-users/" + credentials.id + "/change-risk", {
          new_kyc_risk_score: credentials.new_kyc_risk,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  generateLinkKyc(context, credentials) {
    defaultSetup(context)
    return new Promise((resolve, reject) => {
      axios
        .post("/users/" + credentials.userId + "/kyc/link", {
          levelName: credentials.levelName,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  retrieveUsers(context, credentials) {
    defaultSetup(context)
    const query = filtering(credentials)
    const url = getURL(this.getters.isAdmin, 'client-users')
    axios
      .get(url + query)
      .then((response) => {
        context.commit("retrieveUsers", [
          response.data.body.users,
          response.data.body.total,
        ]);
      })
      .catch((error) => {
        console.log(error)
      });
  },
  retrieveUsersKyc(context, credentials) {
    defaultSetup(context)
    const query = filtering(credentials)
    const url = getURL(this.getters.isAdmin, 'kyc/client-users')
    axios
      .get(url + query)
      .then((response) => {
        context.commit("retrieveUsersKyc", [
          response.data.body.users,
          response.data.body.total,
        ]);
      })
      .catch((error) => {
        console.log(error)
      });
  },
  exportAllUsers(context,credentials) {
    defaultSetup(context)
    const query = filtering(credentials);
    return new Promise((resolve, reject) => {
      axios.get('/admins/client-users/export'+query, { responseType: 'arraybuffer' })
      .then((response) => {
        // Create a new Blob object from the CSV data
        const blob = new Blob([response.data], { type: 'text/csv' });

        // Create a new link and set its href and download attributes
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        const date = new Date();
        const full_date = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear() + '-' + date.getHours() + '_' + date.getMinutes() + '_' + date.getSeconds();
        link.download = 'ProtoAnlysis-UsersExport-'+full_date+'.csv';

        // Append the link to the DOM and trigger a click event to download the CSV file
        document.body.appendChild(link);
        link.click();
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      });
    });
  },
  exportAllUsersKyc(context,credentials) {
    defaultSetup(context)
    const query = filtering(credentials);
    return new Promise((resolve, reject) => {
      axios.get('/admins/kyc/client-users/export'+query, { responseType: 'arraybuffer' })
      .then((response) => {
        // Create a new Blob object from the CSV data
        const blob = new Blob([response.data], { type: 'text/csv' });

        // Create a new link and set its href and download attributes
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        const date = new Date();
        const full_date = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear() + '-' + date.getHours() + '_' + date.getMinutes() + '_' + date.getSeconds();
        link.download = 'ProtoAnlysis-UsersKYCExport-'+full_date+'.csv';

        // Append the link to the DOM and trigger a click event to download the CSV file
        document.body.appendChild(link);
        link.click();
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      });
    });
  },

  /* Error Logs Section */
  retrieveErrorLogs(context, credentials) {
    defaultSetup(context)
    const query = filtering(credentials)
    const url = getURL(this.getters.isAdmin, 'logs')
    axios
      .get(url + query)
      .then((response) => {
        context.commit("retrieveErrorLogs", [
          response.data.body.logs,
          response.data.body.total,
        ]);
      })
      .catch((error) => {
        console.log(error)
      });
  },

  /* Transfer Section */
  retrieveTransfers(context, credentials) {
    defaultSetup(context)
    const url = getURL(this.getters.isAdmin, 'transfers')
    const query = filtering(credentials);
    axios
      .get(url + query)
      .then((response) => {
        context.commit("retrieveTransfers", [
          response.data.body.transfers,
          response.data.body.total,
        ]);
      })
      .catch((error) => {
        console.log(error)
      });
  },
  retrieveTransferSummary(context, credentials) {
    defaultSetup(context)
    return new Promise((resolve, reject) => {
      axios
        .get("/transfers/" + credentials.externalId)
        .then((response) => {
          context.commit("retrieveTransferSummary", response.data);
          if (response.data.updatedAt == null) resolve("Not Yet!!");
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  retrieveSingleUserTransferSummary(context, credentials) {
    defaultSetup(context)
    return new Promise((resolve, reject) => {
      axios
        .get("/transfers/" + credentials.externalId)
        .then((response) => {
          context.commit("retrieveSingleUserTransferSummary", response.data);
          if (response.data.updatedAt == null) resolve("Not Yet!!");
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  exportAllTransfers(context,credentials) {
    defaultSetup(context)
    const query = filtering(credentials);
    return new Promise((resolve, reject) => {
      axios.get('/admins/transfers/export'+query, { responseType: 'arraybuffer' })
    .then((response) => {
      // Create a new Blob object from the CSV data
      const blob = new Blob([response.data], { type: 'text/csv' });

      // Create a new link and set its href and download attributes
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      const date = new Date();
      const full_date = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear() + '-' + date.getHours() + '_' + date.getMinutes() + '_' + date.getSeconds();
      link.download = 'ProtoAnlysis-TransfersExport-'+full_date+'.csv';

      // Append the link to the DOM and trigger a click event to download the CSV file
      document.body.appendChild(link);
      link.click();
      resolve(response)
    })
    .catch((error) => {
      reject(error)
    });
  });
  },


  /* Single User Section */
  retrieveSingleUser(context, credentials) {
    defaultSetup(context)
    axios
      .get(
        (this.getters.isAdmin ? "admins" : "clients") +
        "/client-users/" +
        credentials.singleUserId
      )
      .then((response) => {
        context.commit("retrieveSingleUser", response.data.body.user);
      })
      .catch((error) => {
        console.log(error)
      });
  },
  retrieveSingleUserAlerts(context, credentials) {
    defaultSetup(context)
    const query = filtering(credentials)
    const url = getURL(this.getters.isAdmin, 'client-users/')
    axios
      .get(
        url +
        credentials.singleUserId +
        "/alerts" +
        query
      )
      .then((response) => {
        context.commit("retrieveSingleUserAlerts", [
          response.data.body.user_alerts,
          response.data.body.user_totalAlerts,
        ]);
      })
      .catch((error) => {
        console.log(error)
      });
  },
  retrieveSingleUserTransfers(context, credentials) {
    defaultSetup(context)
    const query = filtering(credentials)
    const url = getURL(this.getters.isAdmin, 'client-users/')
    axios
      .get(
        url +
        credentials.singleUserId +
        "/transfers" +
        query
      )
      .then((response) => {
        context.commit("retrieveSingleUserTransfers", [
          response.data.body.user_transfers,
          response.data.body.user_totalTransfers,
        ]);
      })
      .catch((error) => {
        console.log(error)
      });
  },
  retrieveSingleUserAddresses(context, credentials) {
    defaultSetup(context)
    const query = filtering(credentials)
    const url = getURL(this.getters.isAdmin, 'client-users/')
    axios
      .get(
        url +
        credentials.singleUserId +
        "/addresses" +
        query
      )
      .then((response) => {
        context.commit("retrieveSingleUserAddresses", [
          response.data.body.user_addresses,
          response.data.body.user_totalAddresses,
        ]);
      })
      .catch((error) => {
        console.log(error)
      });
  },
  retrieveSingleUserKycDetails(context, credentials) {
    defaultSetup(context)
    const url = getURL(this.getters.isAdmin, 'client-users/')
    axios
      .get(
        url +
        credentials.singleUserId +
        "/kyc-details"
      )
      .then((response) => {
        context.commit(
          "retrieveSingleUserKycDetails",
          response.data.body.kycDetails
        );
      })
      .catch((error) => {
        console.log(error)
      });
  },
  retrieveSingleErrorLog(context, credentials) {
    defaultSetup(context)
    axios
      .get("/admins/logs/" + credentials.singleErrorId)
      .then((response) => {
        context.commit("retrieveSingleErrorLog", response.data.body[0]);
      })
      .catch((error) => {
        console.log(error)
      });
  },
  retrieveSingleAlert(context, credentials) {
    defaultSetup(context)
    var typeRequest = this.getters.isAdmin ? "admins" : "clients";
    axios
      .get("/" + typeRequest + "/alerts/" + credentials.singleAlertId)
      .then((response) => {
        if (Array.isArray(response.data.body))
          context.commit("retrieveSingleAlert", response.data.body[0]);
        else context.commit("retrieveSingleAlert", response.data.body);
      })
      .catch((error) => {
        console.log(error)
      });
  },
  retrieveAllSingleUserComments(context, credentials) {
    defaultSetup(context)
    axios
      .get("/admins/client-users/" + credentials.userId + "/comments")
      .then((response) => {
        context.commit("retrieveAllSingleUserComments", response.data.body[0]);
      })
      .catch((error) => {
        console.log(error)
      });
  },
  addCommentToSingleUser(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .post("/admins/client-users/" + credentials.userId + "/comment", {
          status: credentials.status,
          comment: credentials.comment,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /* API Key Section */
  retrieveApiKeys(context) {
    defaultSetup(context)
    axios
      .get("/key")
      .then((response) => {
        context.commit("retrieveApiKeys", response.data.body.keys);
      })
      .catch((error) => {
        console.log(error)
      });
  },
  addApiKey() {
    return new Promise((resolve, reject) => {
      axios
        .post("/key")
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteApiKey(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/key/" + credentials.id)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /* WebHook Section */
  retrieveWebHooks(context) {
    defaultSetup(context)
    var typeRequest = this.getters.isAdmin ? "admins" : "clients";
    axios
      .get("/" + typeRequest + "/webhook")
      .then((response) => {
        context.commit("retrieveWebHooks", response.data.body);
      })
      .catch((error) => {
        console.log(error)
      });
  },
  storeWebHook(context, credentials) {
    var typeRequest = this.getters.isAdmin ? "admins" : "clients";
    return new Promise((resolve, reject) => {
      axios
        .post("/" + typeRequest + "/webhook", {
          secret_key: credentials.secret_key,
          link: credentials.link,
          user_id: credentials.user_id
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  editWebHook(context, credentials) {
    var typeRequest = this.getters.isAdmin ? "admins" : "clients";
    return new Promise((resolve, reject) => {
      axios
        .patch("/" + typeRequest + "/webhook/" + credentials.id, {
          secret_key: credentials.secret_key,
          link: credentials.link
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /* Search */
  async search(context, credentials) {
    defaultSetup(context)
    const query = filtering(credentials)
    await axios
      .get("/" + credentials.type + "/search" + query)
      .then((response) => {
        context.commit("search", [
          response.data.body.transfers,
          response.data.body.clientUsers,
          response.data.body.alerts,
          response.data.body.addresses,
        ]);
      })
      .catch((error) => {
        console.log(error)
      });
  },

  /* Dashboard Section */
  retrieveAlertsOverView(context, credentials) {
    defaultSetup(context)
    const query = filtering(credentials)
    const url = getURL(this.getters.isAdmin, 'dashboard/alerts-overview')
    axios
      .get(
        url +
        query
      )
      .then((response) => {
        context.commit(
          "retrieveAlertsOverView",
          response.data.body.alertsOverview
        );
      })
      .catch((error) => {
        console.log(error)
      });
  },
  retrieveUserOverView(context, credentials) {
    defaultSetup(context)
    const query = filtering(credentials)
    const url = getURL(this.getters.isAdmin, 'dashboard/user-overview')
    axios
      .get(
        url +
        query
      )
      .then((response) => {
        context.commit("retrieveUserOverView", response.data.body.userOverview);
      })
      .catch((error) => {
        console.log(error)
      });
  },
  retrieveKycApplicantOverView(context, credentials) {
    defaultSetup(context)
    const query = filtering(credentials)
    const url = getURL(this.getters.isAdmin, 'dashboard/applicant-overview')
    axios
      .get(
        url +
        query
      )
      .then((response) => {
        context.commit(
          "retrieveKycApplicantOverView",
          response.data.body.applicantOverview
        );
      })
      .catch((error) => {
        console.log(error)
      });
  },
  retrieveLastestAlerts(context, credentials) {
    defaultSetup(context)
    const query = filtering(credentials)
    const url = getURL(this.getters.isAdmin, 'dashboard/lastest-alerts')
    axios
      .get(
        url +
        query
      )
      .then((response) => {
        context.commit(
          "retrieveLastestAlerts",
          response.data.body.latestAlerts
        );
      })
      .catch((error) => {
        console.log(error)
      });
  },
  retrieveClientsOverview(context) {
    defaultSetup(context)
    const query = "";
    axios
      .get("/admins/dashboard/clients-overview" + query)
      .then((response) => {
        context.commit(
          "retrieveClientsOverview",
          response.data.body.clientsOverview
        );
      })
      .catch((error) => {
        console.log(error)
      });
  },
  retrieveTransferVolumeperCategory(context, credentials) {
    defaultSetup(context)
    const query = filtering(credentials)
    const url = getURL(this.getters.isAdmin, 'dashboard/transfer-volume-per-category')
    axios
      .get(
        url +
        query
      )
      .then((response) => {
        context.commit(
          "retrieveTransferVolumeperCategory",
          response.data.body.transferVolumePerCategory
        );
      })
      .catch((error) => {
        console.log(error)
      });
  },

  /* Pre Screen */
  registerPreScreening(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .post("/users/" + credentials.userId + "/withdrawal-addresses", {
          asset: credentials.asset,
          address: credentials.address,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },


  /* custom functions */
  changeTokenToAdmin(context) {
    context.commit("changeTokenToAdmin");
  },
  changeTokenToClient(context) {
    context.commit("changeTokenToClient");
  },
  changeNavActive(context, nav) {
    context.commit("changeNavActive", nav);
  },
  changeModule(context, module) {
    context.commit("changeModule", module);
  },
  changeStatusBothModule(context, status) {
    context.commit("changeStatusBothModule", status);
  },
  turnSpinnerOn(context) {
    context.commit("turnSpinnerOn");
  },
  storeRouteName(context, routeName) {
    context.commit("storeRouteName", routeName);
  },
  changeSelectedClientFilter(context, selectedClient) {
    context.commit("changeSelectedClientFilter", selectedClient);
  },

  /* Kyc Service */
  getKycServiceStatus(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .get("/kyc/applicant/" + credentials.refer)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default actions;
