<template>
  <div class="main-footer" style="z-index: 1">
    <div class="col-md-12 col-sm-12 text-center">
      <div class="container-fluid pd-t-0-f ht-100p">
        Copyright © 2022
        <router-link
          :to="{ name: this.isAdmin ? 'admin-dashboard' : 'dashboard' }"
          class="text-primary"
          >ProtoAML</router-link
        >. Designed with <span class="fa fa-heart text-danger"></span> by
        <a href="javascript:void(0);"> ProtoAML </a> All rights reserved
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DownFooter",
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
};
</script>
