<template>
  <div
    class="modal fade"
    id="ClientLevelsModal"
    tabindex="-1"
    aria-labelledby="ClientLevelsModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="ClientLevelsModalLabel">
            Client Applicant Levels
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <AddApplicantLevel
            :client="this.client"
            @refreshLevels="refreshLevelsTable"
          />
          <div class="table-responsive export-table mt-3">
            <table
              class="table table-bordered text-nowrap key-buttons border-bottom"
              style="text-align: center"
            >
              <thead>
                <tr class="text-center">
                  <th class="border-bottom-0">Level Name</th>
                  <th class="border-bottom-0">Active</th>
                </tr>
              </thead>
              <tbody v-if="loading">
                <tr class="old">
                  <td valign="top" colspan="2" class="dataTables_empty">
                    <SpinnerLoader :start="loading" />
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="Object.values(client_levels).length">
                <tr
                  class="text-center"
                  v-for="level in client_levels"
                  :key="level.id"
                >
                  <td>{{ level.name }}</td>
                  <td>
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault"
                        :checked="level.active == 1"
                        @click="changeActive(level.id)"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr class="old">
                  <td valign="top" colspan="6" class="dataTables_empty">
                    No matching records found
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="modal-footer">
            <button
              id="closeEditModal"
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AddApplicantLevel from "@/components/UserManagement/AddApplicantLevel.vue";
import SpinnerLoader from "@/components/SpinnerLoader.vue";
//var $ = require("jquery");
export default {
  name: "ClientLevels",
  components: { AddApplicantLevel, SpinnerLoader },
  props: {
    client: { type: Object, required: true },
  },
  data() {
    return {
      client_levels: {},
      loading: false,
    };
  },
  watch: {
    client() {
      this.client_levels = this.client.kyc_levels;
    },
    getClientLevels() {
      this.client_levels = this.getClientLevels;
      this.loading = false;
    },
  },
  computed: {
    getClientLevels() {
      return this.$store.getters.getClientLevels;
    },
  },
  methods: {
    refreshLevelsTable() {
      this.loading = true;
      this.$store.dispatch("retrieveClientLevels", {
        clientId: this.client.id,
      });
    },
    changeActive(levelId) {
      this.$store
        .dispatch("changeActiveClientLevelApplicant", {
          levelId: levelId,
        })
        .then((response) => {
          this.$notify({ type: "success", text: response.message[0] });
        })
        .catch((error) => {
          var serverError =
            error.response.data.message == null
              ? [error.response.data.errors]
              : [error.response.data.message];
          this.$notify({ type: "wronge", text: serverError });
        });
    },
  },
};
</script>
