<template>
  <div
    class="modal fade"
    id="editClientModal"
    tabindex="-1"
    aria-labelledby="editClientModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="editClientModalLabel">Edit Client</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form action="#" @submit.prevent="editClient">
            <div v-if="successMessage" class="success-message">
              {{ successMessage }}
            </div>
            <div v-if="serverError.length > 0" class="server-error">
              <ul
                style="list-style-type: none; margin: 0px; padding: 0px"
                v-for="(item, index) in serverError"
                :key="index"
              >
                <li>{{ item }}</li>
              </ul>
            </div>

            <div cass="mb-3 text-start">
              <label for="client_name" class="col-form-label">Name:</label>
              <input
                type="text"
                class="form-control"
                id="edit_client_name"
                name="client_name"
                placeholder="Enter Name"
                v-model="clientName"
                required
              />
            </div>
            <div class="mb-3 text-start">
              <label for="client_email" class="col-form-label">Email:</label>
              <input
                type="email"
                class="form-control"
                id="edit_client_email"
                name="client_email"
                placeholder="Enter Email"
                v-model="clientEmail"
                required
              />
            </div>
            <div class="mb-3 text-start">
              <label for="client_prefix" class="col-form-label">Prefix:</label>
              <input
                type="text"
                class="form-control"
                id="edit_client_prefix"
                name="client_prefix"
                placeholder="Enter Prefix"
                v-model="clientPrefix"
                required
              />
            </div>
            <div class="mb-3 text-start">
              <label for="client_alert_threshold" class="col-form-label"
                >Withdraw Alert Threshold (USD):</label
              >
              <input
                type="number"
                class="form-control"
                id="edit_client_withdraw_alert_threshold"
                name="client_withdraw_alert_threshold"
                placeholder="Enter Withdraw Alert Threshold"
                v-model="clientWithdrawAlertThreshold"
              />
            </div>
            <div class="mb-3 text-start">
              <label for="client_alert_threshold" class="col-form-label"
                >Deposit Alert Threshold (USD):</label
              >
              <input
                type="number"
                class="form-control"
                id="edit_client_deposit_alert_threshold"
                name="client_deposit_alert_threshold"
                placeholder="Enter Deposit Alert Threshold"
                v-model="clientDepositAlertThreshold"
              />
            </div>
            <div class="mb-3 text-start">
              <label for="client_alert_type" class="col-form-label"
                >Alert Type:</label
              >
              <Select2Component
                @selectValue="getSelectValue"
                :types="this.client.alert_types"
              />
            </div>
            <div class="mb-3 text-start">
              <label for="client_provider_key" class="col-form-label"
                >Provider Key:</label
              >
              <input
                type="text"
                class="form-control"
                id="edit_client_provider_key"
                name="client_provider_key"
                placeholder="Enter provider_key"
                v-model="clientProviderKey"
                required
              />
            </div>
            <div class="mb-3 text-start">
              <label for="client_provider_key" class="col-form-label"
                >Reset Password:</label
              >
              <input
                id="pass3"
                v-model="clientNewPassword"
                name="clientNewPassword"
                type="password"
                style="margin-right: 6px"
                class="form-control"
                placeholder="Enter New Password"
              />
              <i
                @click="showPass(3)"
                class="fa fa-eye eye-pos eye3"
                aria-hidden="true"
              ></i>
            </div>

            <div class="row" v-if="Object.values(getRoles).length">
              <label for="client_provider_key" class="col-form-label col-12"
                >Permission:</label
              >
              <div v-for="item in getRoles" :key="item.id" class="col-lg-4">
                <label class="ckbox"
                  ><input type="checkbox" :id="'role_edit_' + item.id" /><span
                    >{{ item.name }}</span
                  ></label
                >
              </div>
            </div>

            <div class="modal-footer">
              <button
                id="closeEditModal"
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
              Close
              </button>
              <button
                type="submit"
                :disabled="!buttonVisiable"
                class="btn btn-primary"
              >
                Edit Client
              </button>
              <div></div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.eye-pos {
  position: absolute;
  right: 30px;
  bottom: 20%;
}
</style>
<script>
import Select2Component from "@/components/Select2Component.vue";
var $ = require("jquery");
export default {
  name: "EditClient",
  components: { Select2Component },
  props: {
    client: { type: Object, required: true },
  },
  computed: {
    getRoles() {
      return this.$store.getters.getRoles;
    },
  },
  data() {
    return {
      clientId: 0,
      clientName: "",
      clientEmail: "",
      clientPrefix: "",
      clientWithdrawAlertThreshold: 0,
      clientDepositAlertThreshold: 0,
      clientAlertType: [],
      clientProviderKey: "",
      clientRoles: [],
      clientNewPassword: "",
      successMessage: "",
      serverError: [],
      buttonVisiable: true,
    };
  },
  watch: {
    client() {
      this.clientRoles = [];
      for (let k = 0; k < Object.values(this.getRoles).length; k++) {
        document.getElementById(
          "role_edit_" + this.getRoles[k].id
        ).checked = false;
      }
      this.clientName = this.client.name;
      this.clientEmail = this.client.email;
      this.clientPrefix = this.client.prefix;
      this.clientWithdrawAlertThreshold = this.client.withdraw_alert_threshold;
      this.clientDepositAlertThreshold = this.client.deposit_alert_threshold;
      this.clientAlertType = this.client.alert_types;
      this.clientProviderKey = this.client.api_key;
      for (let i = 0; i < Object.values(this.client.alert_types).length; i++) {
        this.clientAlertType[i] = this.client.alert_types[i].alert_type_id;
      }
      for (let j = 0; j < Object.values(this.client.roles).length; j++) {
        this.clientRoles.push(this.client.roles[j].id);
      }
      for (let k = 0; k < this.clientRoles.length; k++) {
        document.getElementById(
          "role_edit_" + this.clientRoles[k]
        ).checked = true;
      }
    },
  },
  methods: {
    showPass(num) {
      var el = document.getElementById("pass" + num);
      el.type = el.type == "text" ? "password" : "text";
      $('.eye'+num).css('text-decoration',el.type == "text" ? 'line-through':'unset');
    },
    getSelectValue(value) {
      this.clientAlertType = Object.values(value);
    },
    editClient() {
      this.client_roles = [];
      for (var i = 0; i < Object.values(this.getRoles).length; i++) {
        if ($("#role_edit_" + this.getRoles[i].id).is(":checked")) {
          this.client_roles.push(this.getRoles[i].id);
        }
      }
      this.buttonVisiable = false;
      this.$store
        .dispatch("editClient", {
          id: this.client.id,
          name: this.clientName,
          email: this.clientEmail,
          prefix: this.clientPrefix,
          withdraw_alert_threshold: this.clientWithdrawAlertThreshold,
          deposit_alert_threshold: this.clientDepositAlertThreshold,
          alert_types: this.clientAlertType,
          provider_key: this.clientProviderKey,
          client_roles: this.client_roles,
          new_password: this.clientNewPassword,
        })
        .then((response) => {
          this.successMessage = response.message[0];
          setTimeout(() => {
            this.successMessage = "";
            this.$emit("refresh", true);
            $("#closeEditModal").click();
          }, 2000);
          this.buttonVisiable = true;
        })
        .catch((error) => {
          if (Array.isArray(error.response.data.message))
            this.serverError = error.response.data.message;
          else if (error.response.data.message)
            this.serverError = [error.response.data.message];
          else if (typeof error.response.data.errors == "string")
            this.serverError = [error.response.data.errors];
          else this.serverError = Object.values(error.response.data.errors);
          setTimeout(() => {
            this.serverError = [];
          }, 2000);
          this.buttonVisiable = true;
        });
    },
  },
};
</script>