<template>
  <div class="row row-sm text-start mt-5 w-100">
    <div class="col-lg-12">
      <div class="py-2 col-12 row">
        <div class="col-12 col-md-6 d-flex justify-content-start">
          <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <h2 class="main-content-label mb-1">Alerts</h2>
            </div>
          </nav>
        </div>
      </div>
      <div class="my-2 col-12 justify-content-center">
        <div class="row justify-content-start align-items-end filter-s px-0 mt-2">
          <SortByComponent :arrayData="sortByList" :sortBySelected="'severity'" :uniqId="'alert'" @changeSortBy="SortChanged" />
          <!-- <FilterComponent
            v-if="sortByTypes.length > 0"
            :title="'Sort Type'"
            :arrayData="sortByTypes"
            :variableName="'sortType'"
            @changeFilter="filterChanged"
          /> -->
        </div>
      </div>
      <div class="card custom-card overflow-hidden">
        <div class="card-body">
          <div class="table-responsive export-table">
            <PaginationComponent
              :page="this.filters.page"
              :limit="this.filters.limit"
              :getTotalRows="this.getTotalSingleUserAlerts"
              :nameComponent="this.nameComponent"
              @changePage="filterChanged"
            />
            <!-- table -->
            <DataTableAlert
              :getAlerts="this.getSingleUserAlerts"
              :visible="visible"
              @alertIdValue="getAlertId"
            />

            <PaginationComponent
              :page="this.filters.page"
              :limit="this.filters.limit"
              :getTotalRows="this.getTotalSingleUserAlerts"
              :nameComponent="this.nameComponent"
              @changePage="filterChanged"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataTableAlert from "@/components/Alerts/DataTableAlert";
import PaginationComponent from "@/components/PaginationComponent.vue";
import SortByComponent from "@/components/SortByComponent.vue";

export default {
  name: "AlertsComponent",
  props: {
    userId: String,
    loading: Boolean,
  },
  components: {
    DataTableAlert,
    PaginationComponent,
    SortByComponent,
  },
  data() {
    return {
      filters: {
        page: 1,
        limit: 10,
        sortBy: "severity",
        sortType: "desc",
      },
      visible: false,
      nameComponent: "Alerts",
      sortByList: [
        {
          id: 'severity',
          name: 'Severity'
        },
        {
          id: 'created_at',
          name: 'Created At'
        },
        {
          id: 'alertable_type',
          name: 'Alert Type'
        },
        {
          id: 'amount',
          name: 'Amount'
        }
      ],
      sortByTypes: [
        {
          id: 'asc',
          name: 'Ascending'
        },
        {
          id: 'desc',
          name: 'Descending'
        },
      ],
    };
  },
  computed: {
    getSingleUserAlerts() {
      return this.$store.getters.getSingleUserAlerts;
    },
    getTotalSingleUserAlerts() {
      return this.$store.getters.getTotalSingleUserAlerts;
    },
  },
  watch: {
    loading() {
      this.visible = !this.visible;
      this.$store.dispatch("retrieveSingleUserAlerts", {
        singleUserId: this.userId,
        filters: this.filters,
      });
    }
  },
  mounted() {
    this.$store.dispatch("retrieveSingleUserAlerts", {
      singleUserId: this.userId,
      filters: this.filters,
    });
  },
  methods: {
    filterChanged(value) {
      this.visible = !this.visible;
      this.filters[value[0]] = value[1];
      this.$store.dispatch("retrieveSingleUserAlerts", {
        singleUserId: this.userId,
        filters: this.filters,
      });
    },
    SortChanged(value) {
      this.visible = !this.visible;
      this.filters['sortBy'] = value.sortBy;
      this.filters['sortType'] = value.sortType;
      this.$store.dispatch("retrieveSingleUserAlerts", {
        singleUserId: this.userId,
        filters: this.filters,
      });
    },
    getAlertId(value) {
      this.$emit("parentAlertId", value);
    },
  },
};
</script>

<style scope>
.btn-info {
  font-size: 12px;
}
.btn-approve {
  color: #569618;
  background: transparent;
  border-color: #569618;
  display: inline-block;
  width: 105px;
}
.btn-approve:hover,
.btn-approve:active,
.btn-approve:focus {
  color: #569618 !important;
  background: #c0ff84 !important;
  border-color: #569618 !important;
  box-shadow: 0 0 0 0.2rem white !important;
}
.btn-on-hold:hover,
.btn-on-hold:active,
.btn-on-hold:focus {
  color: orangered !important;
  background: transparent !important;
  border-color: orangered !important;
  box-shadow: 0 0 0 0.2rem white !important;
}
.btn-on-hold {
  color: orangered;
  background: transparent;
  border-color: orangered;
  display: inline-block;
  width: 105px;
}
.dropdown-menu .own-dropdown {
  border-bottom: #cccccb 1px solid;
}
.dropdown-menu .own-dropdown:first-child {
  color: #569618;
}
.dropdown-menu .own-dropdown:nth-child(2) {
  color: orangered;
}
.dropdown-menu .own-dropdown:nth-child(3) {
  color: deepskyblue;
}
.assignment_icon {
  font-size: 30px !important;
  color: #cbcbcb !important;
}
.wrap_item_name {
  white-space: pre-wrap;
}
.select_whithout_arrow {
  background-image: none !important;
  font-size: 13px;
  font-weight: 900;
}
.select_whithout_arrow option {
  font-size: 13px;
}
.severity_severe {
  width: 80px;
  display: inline-block;
  padding: 8px 15px;
  background-color: rgb(255, 240, 240);
  color: rgb(162, 9, 7);
}
.severity_high {
  width: 80px;
  display: inline-block;
  padding: 8px 15px;
  background-color: rgb(255, 236, 224);
  color: rgb(194, 78, 0);
}
.severity_medium {
  width: 80px;
  display: inline-block;
  padding: 8px 15px;
  background-color: rgb(255, 249, 219);
  color: rgb(153, 107, 15);
}
.severity_low {
  width: 80px;
  display: inline-block;
  padding: 8px 15px;
  background-color: rgb(220 227 203);
  color: rgb(156 159 12);
}
.severity_other {
  width: 80px;
  display: inline-block;
  padding: 8px 15px;
  background-color: rgb(199 205 184);
  color: rgb(108 110 6);
}
.dropdown-scrollbar {
  height: 150px;
  overflow: scroll;
}
.btn-paging {
  background-color: #e1e1e1;
  border: 1px solid #aaa;
  font-weight: 600;
}

.btn-paging:hover {
  background-color: #fff;
}
.total_rows {
  font-size: 14px;
  color: #858f99;
}
.dt-button {
  background-color: #e5e5e5;
  border: 1px solid #d7d3d3;
  color: #7e7d7d;
  border-radius: 8px;
  font-weight: 100;
  font-size: 13px;
}
.dt-button:hover {
  background-color: #fff;
  color: #7e7d7d;
}
</style>
