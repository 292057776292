<template>
  <ApplicantsUser :applicants="
    this.getSingleUserKycDetails.kyc_applicants
      ? this.getSingleUserKycDetails.kyc_applicants
      : []
  " />
  <div class="col-12 col-md-12 d-flex justify-content-start mt-5">
    <h3 class="px-md-4">User Kyc Information</h3>
  </div>
  <div v-if="visible" class="col-12 col-md-12 d-flex justify-content-start border-bottom-gray">
    <div class="card col-12 col-md-6">
      <div class="card-body border-0" data-select2-id="12">
        <div class="form-group">
          <div class="row row-sm text-start">
            <div class="col-md-3">
              <p class="form-label text-dark">ID</p>
            </div>
            <div class="col-md-9 d-flex justify-content-start">
              <p class="form-label">
                {{
                  isAdmin
                  ? this.getSingleUserKycDetails.uid
                                : this.getSingleUserKycDetails.partner_id
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row row-sm text-start">
            <div class="col-md-3">
              <p class="form-label text-dark">Kyc Status</p>
            </div>
            <div class="col-md-9 d-flex justify-content-start">
              <p class="form-label" v-if="
                Object.values(getSingleUserKycDetails.kyc_applicants).length
              ">
                <span v-if="
                  Object.values(getSingleUserKycDetails.kyc_applicants)
                    .length == 1
                "
                  :style="
                  this.$getKycApplicantStatusColor(getSingleUserKycDetails.kyc_applicants[0].review_status,getSingleUserKycDetails.kyc_applicants[0].review_answer,getSingleUserKycDetails.kyc_applicants[0].review_reject_type)">
                  {{
                    this.$getKycApplicantStatusName(getSingleUserKycDetails.kyc_applicants[0].review_status,getSingleUserKycDetails.kyc_applicants[0].review_answer,getSingleUserKycDetails.kyc_applicants[0].review_reject_type)
                  }}
                  <a href="#" class="mr-3" style="color: red; margin-left: 2px; margin-right: 2px" v-if="
                    getSingleUserKycDetails.kyc_applicants[0].review_answer ==
                    'RED' && getSingleUserKycDetails.kyc_applicants[0].review_status == 'completed'
                  " data-bs-toggle="modal" data-bs-target="#ShowApplicants">
                    <i class="fa fa-info-circle" style="font-size: 14px"></i>
                  </a>
                  <div v-if="getSingleUserKycDetails.kyc_applicants[0].edited" class="toolpop">
                    <p
                      style="margin-bottom: 0px;"
                      @mouseover="defineCopy(getSingleUserKycDetails.kyc_applicants[0].id)"
                      @mouseout="backToOrigin(getSingleUserKycDetails.kyc_applicants[0].id)"
                    >
                      <span class="edited-tag" >
                        {{ getSingleUserKycDetails.kyc_applicants[0].edited ? 'E' : '' }}
                      </span>
                      <span class="toolpoptext" :id="'myToolpop_EditedApplicantUser_'+getSingleUserKycDetails.kyc_applicants[0].id">
                        this applicant is duplicated  in reference but edited in ProtoAML as per client request
                      </span>
                    </p>
                  </div>
                </span>
                <span v-if="
                  Object.values(getSingleUserKycDetails.kyc_applicants)
                    .length > 1
                ">
                  <a :style="'text-decoration: underline;'
                  + this.$getKycApplicantStatusColor(getSingleUserKycDetails.kyc_applicants[Object.values(getSingleUserKycDetails.kyc_applicants).length - 1].review_status,getSingleUserKycDetails.kyc_applicants[Object.values(getSingleUserKycDetails.kyc_applicants).length - 1].review_answer,getSingleUserKycDetails.kyc_applicants[Object.values(getSingleUserKycDetails.kyc_applicants).length - 1].review_reject_type)" href="#" class="mr-3" data-bs-toggle="modal"
                    data-bs-target="#ShowApplicants">
                    {{
                      getSingleUserKycDetails.kyc_applicants[
                        Object.values(getSingleUserKycDetails.kyc_applicants)
                          .length - 1
                      ].level_name /* +
                        " : " +
                        this.$getKycApplicantStatusName(getSingleUserKycDetails.kyc_applicants[Object.values(getSingleUserKycDetails.kyc_applicants).length - 1].review_status,getSingleUserKycDetails.kyc_applicants[Object.values(getSingleUserKycDetails.kyc_applicants).length - 1].review_answer,getSingleUserKycDetails.kyc_applicants[Object.values(getSingleUserKycDetails.kyc_applicants).length - 1].review_reject_type)
                      */
                      }}</a>
                  <div v-if="getSingleUserKycDetails.kyc_applicants[Object.values(getSingleUserKycDetails.kyc_applicants).length - 1].edited" class="toolpop">
                    <p
                      style="margin-bottom: 0px;"
                      @mouseover="defineCopy(getSingleUserKycDetails.kyc_applicants[Object.values(getSingleUserKycDetails.kyc_applicants).length - 1].id)"
                      @mouseout="backToOrigin(getSingleUserKycDetails.kyc_applicants[Object.values(getSingleUserKycDetails.kyc_applicants).length - 1].id)"
                    >
                      <span class="edited-tag" >
                        {{ getSingleUserKycDetails.kyc_applicants[Object.values(getSingleUserKycDetails.kyc_applicants).length - 1].edited ? 'E' : '' }}
                      </span>
                      <span class="toolpoptext" :id="'myToolpop_EditedApplicantUser_'+getSingleUserKycDetails.kyc_applicants[Object.values(getSingleUserKycDetails.kyc_applicants).length - 1].id">
                        this applicant is duplicated  in reference but edited in ProtoAML as per client request
                      </span>
                    </p>
                  </div>
                </span>
              </p>
              <p class="form-label" v-else>NOT APPLICABLE</p>
            </div>
          </div>
        </div>
        <div class="form-group" v-if="isAdmin">
          <div class="row row-sm text-start">
            <div class="col-md-3">
              <p class="form-label text-dark">Kyc Risk Score</p>
            </div>
            <div class="col-md-9 d-flex justify-content-start">
              <p class="form-label">
                {{
                  this.getSingleUserKycDetails.kyc_risk_score
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card col-12 col-md-6">
      <div class="card-body border-0" data-select2-id="12">
        <div class="form-group">
          <div class="row row-sm text-start">
            <div class="col-md-3">
              <p class="form-label text-dark">Tier Level</p>
            </div>
            <div class="col-md-9 d-flex justify-content-start">
              <p class="form-label" v-if="
                Object.values(getSingleUserKycDetails.kyc_applicants).length
              ">
                <span :style="
                this.$getKycApplicantStatusColor(getSingleUserKycDetails.kyc_applicants[Object.values(getSingleUserKycDetails.kyc_applicants).length - 1].review_status,getSingleUserKycDetails.kyc_applicants[Object.values(getSingleUserKycDetails.kyc_applicants).length - 1].review_answer,getSingleUserKycDetails.kyc_applicants[Object.values(getSingleUserKycDetails.kyc_applicants).length - 1].review_reject_type)
                ">
                  {{
                    getSingleUserKycDetails.kyc_applicants[
                      Object.values(getSingleUserKycDetails.kyc_applicants)
                        .length - 1
                    ].level_name /* +
                      " : " +
                      this.$getKycApplicantStatusName(getSingleUserKycDetails.kyc_applicants[Object.values(getSingleUserKycDetails.kyc_applicants).length - 1].review_status,getSingleUserKycDetails.kyc_applicants[Object.values(getSingleUserKycDetails.kyc_applicants).length - 1].review_answer,getSingleUserKycDetails.kyc_applicants[Object.values(getSingleUserKycDetails.kyc_applicants).length - 1].review_reject_type)
                  */ }}
                </span>
              </p>
              <p class="form-label" v-else>NOT APPLICABLE</p>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row row-sm text-start">
            <div class="col-md-3">
              <p class="form-label text-dark">Latest Update</p>
            </div>
            <div class="col-md-9 d-flex justify-content-start">
              <p class="form-label" v-if="
                Object.values(getSingleUserKycDetails.kyc_applicants).length
              ">
                {{
                  this.$dateTimeFormat(
                    getSingleUserKycDetails.kyc_applicants[
                      Object.values(getSingleUserKycDetails.kyc_applicants)
                        .length - 1
                    ].updated_at)
                }}
              </p>
              <p v-else class="form-label">NOT APPLICABLE</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <SpinnerLoader class="col-12 w-100 h-100" style="background: white" :start="!visible" />
</template>
<style scope>
.border-bottom-gray {
  border-bottom: 1px solid #ccc;
}
.edited-tag {
  background-color: yellow;
  padding: 2px;
  margin: 0px 4px;
  font-size: 12px;
  font-weight: bold;
  color: black;
}
.toolpop {
  position: relative;
  display: inline-block;
}

.toolpop .toolpoptext {
  visibility: hidden;
  width: 300px;
  white-space: normal;
  height: 50px;
  background-color: #555 !important;
  color: #fff !important;
  text-align: center !important;
  font-size: 14px;
  border-radius: 6px !important;
  padding: 5px !important;
  position: absolute !important;
  z-index: 1 !important;
  bottom: -80% !important;
  left: 120px !important;
  margin-left: -95px !important;
  opacity: 0;
  transition: opacity 0.3s;
}
.toolpop .toolpoptext::after {
  content: "";
  position: absolute;
  top: 43% !important;
  left: -5px !important;
  margin-left: -5px !important;
  border-width: 5px !important;
  border-style: solid !important;
  border-color: transparent #555 transparent transparent !important;
}
</style>
<script>
import SpinnerLoader from "@/components/SpinnerLoader.vue";
import ApplicantsUser from "@/components/UsersKyc/ApplicantsUser.vue";
export default {
  name: "UserKycInformationComponent",
  props: {
    userId: {
      type: String,
      require: true,
    },
    loading: Boolean,
  },
  components: {
    SpinnerLoader,
    ApplicantsUser,
  },
  data() {
    return {
      visible: false,
    };
  },
  mounted() {
    this.$store.dispatch("retrieveLevels");
    this.$store.dispatch("retrieveSingleUserKycDetails", {
      singleUserId: this.userId,
    });
  },
  computed: {
    getLevels() {
      return this.$store.getters.getLevels;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    getSingleUserKycDetails() {
      return this.$store.getters.getSingleUserKycDetails;
    },
  },
  watch: {
    getSingleUserKycDetails() {
      this.visible = true;
    },
    loading() {
      this.visible = false
      this.$store.dispatch("retrieveSingleUserKycDetails", {
        singleUserId: this.userId,
      });
    }
  },
  methods: {
    defineCopy(ind) {
      var toolpop = document.getElementById("myToolpop_EditedApplicantUser_"+ind);
      toolpop.style.visibility = "visible";
      toolpop.style.opacity = 1;
    },
    backToOrigin(ind) {
      var toolpop = document.getElementById("myToolpop_EditedApplicantUser_"+ind);
      toolpop.style.visibility = "hidden";
      toolpop.style.opacity = 0;
    },
  }
};
</script>
