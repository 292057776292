<template>
  <div
    class="modal fade"
    id="showClientModalKeys"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Client Api Keys</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="table-responsive export-table">
            <table
              id="datatable_client_keys"
              class="table table-bordered text-nowrap key-buttons border-bottom"
              style="text-align: center"
            >
              <thead>
                <tr class="text-center">
                  <th class="border-bottom-0">Token</th>
                  <th class="border-bottom-0">Created Date</th>
                </tr>
              </thead>
              <tbody v-if="loading">
                <tr class="old">
                  <td valign="top" colspan="2" class="dataTables_empty">
                    <SpinnerLoader :start="loading" />
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="Object.values(client.api_keys).length > 0">
                <tr
                  class="text-center"
                  v-for="keys in client.api_keys"
                  :key="keys.id"
                >
                  <td>
                    <input
                      :id="'myinput_' + keys.id"
                      type="text"
                      style="display: none"
                      readonly
                      :value="keys.api_key"
                    />
                    {{ keys.api_key.slice(0, 20) + "..." }}

                    <div class="toolpop">
                      <i
                        class="fas fa-copy"
                        @click="copy(keys.id)"
                        @mouseover="defineCopy(keys.id)"
                        @mouseout="backToOrigin(keys.id)"
                        ><span
                          class="toolpoptext"
                          :id="'myToolpop_' + keys.id"
                          >Copy to clipboard</span
                        ></i
                      >
                    </div>
                  </td>
                  <td>{{ keys.created_at.split(".")[0].replace("T", " ") }}</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr class="old">
                  <td valign="top" colspan="2" class="dataTables_empty">
                    No matching records found
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.dataTables_scrollBody {
  min-height: 130px;
}
</style>
<script>
import SpinnerLoader from "@/components/SpinnerLoader.vue";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "bootstrap";

// var $ = require("jquery");
export default {
  name: "DataTableClient",
  props: {
    client: { type: Object, required: true },
  },
  components: {
    SpinnerLoader,
  },
  data() {
    return {
      table: null,
      loading: true,
    };
  },
  watch: {
    client() {
    //   if (this.table == null)
    //     setTimeout(() => {
    //       this.table = $("#datatable_client_keys").DataTable({
    //         scrollX: true,
    //       });
    //     }, 100);
      this.loading = false;
    },
  },
  methods: {
    copy(ind) {
      var text = document.getElementById("myinput_" + ind).value;
      var listener = function (ev) {
        ev.clipboardData.setData("text/plain", text);
        ev.preventDefault();
      };
      document.addEventListener("copy", listener);
      document.execCommand("copy");
      document.removeEventListener("copy", listener);
      var toolpop = document.getElementById("myToolpop_" + ind);
      toolpop.innerHTML = "Copied: " + text.slice(0, 10);
      toolpop.style.visibility = "visible";
      toolpop.style.opacity = 1;
    },
    defineCopy(ind) {
      var toolpop = document.getElementById("myToolpop_" + ind);
      toolpop.innerHTML = "Copy to clipboard";
      toolpop.style.visibility = "visible";
      toolpop.style.opacity = 1;
    },
    backToOrigin(ind) {
      var toolpop = document.getElementById("myToolpop_" + ind);
      toolpop.style.visibility = "hidden";
      toolpop.style.opacity = 0;
    },
  },
};
</script>
