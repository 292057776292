<template>
  <div class="col-xl-8 col-lg-12 col-md-12 col-sm-12 px-0">
    <div class="card px-3 ps-4">
      <div class="card-header pb-0">
        <div class="d-flex justify-content-between">
          <h3 class="card-title mg-b-0">User Overview</h3>
        </div>
      </div>
      <div class="card-body px-0 pb-0" v-if="visible">
        <div class="row index1">
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xxl-3">
            <div class="row border-end border-top bd-xs-e-0 p-3">
              <div class="col-12 py-0">
                <div
                  class="row d-flex align-items-center justify-content-center"
                >
                  <div class="col-12">
                    <h6 class="mb-2 pb-3 tx-12">
                      <span
                        style="
                          position: relative;
                          top: -1px;
                          display: inline-block;
                          width: 6px;
                          height: 6px;
                          vertical-align: middle;
                          border-radius: 50%;
                          background-color: rgb(149, 32, 22);
                          margin-right: 5px;
                        "
                      ></span
                      >SEVERE RISK
                    </h6>
                    <div class="d-flex pb-3 justify-content-center">
                      <h2 class="tx-18 font-weight-semibold mb-0">
                        {{
                          getUserOverView.SEVERE.count > 0
                            ? parseFloat(
                                getUserOverView.SEVERE.percentage
                              ).toFixed(1) + "%"
                            : getUserOverView.SEVERE.percentage
                        }}
                      </h2>
                    </div>
                  </div>
                  <div class="col-12 pb-0 mt-0">
                    <router-link
                      :to="
                        (isAdmin ? '/admin' : '') + '/users?riskScore=SEVERE'
                      "
                      class="d-flex pb-2 justify-content-center badge bg-success-transparent text-success font-weight-semibold ms-auto rounded-pill lh-maincard"
                      style="font-size: 14px"
                      ><i class="fa fa-caret-up me-1"></i>View
                      {{ getUserOverView.SEVERE.count }} user</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xxl-3">
            <div class="row border-end border-top bd-xs-e-0 p-3">
              <div class="col-12 py-0">
                <div
                  class="row d-flex align-items-center justify-content-center"
                >
                  <div class="col-12">
                    <h6 class="mb-2 pb-3 tx-12">
                      <span
                        style="
                          position: relative;
                          top: -1px;
                          display: inline-block;
                          width: 6px;
                          height: 6px;
                          vertical-align: middle;
                          border-radius: 50%;
                          background-color: rgb(255, 77, 79);
                          margin-right: 5px;
                        "
                      ></span
                      >HIGH RISK
                    </h6>
                    <div class="d-flex pb-3 justify-content-center">
                      <h2 class="tx-18 font-weight-semibold mb-0">
                        {{
                          getUserOverView.HIGH.count > 0
                            ? parseFloat(
                                getUserOverView.HIGH.percentage
                              ).toFixed(1) + "%"
                            : getUserOverView.HIGH.percentage
                        }}
                      </h2>
                    </div>
                  </div>
                  <div class="col-12 pb-0 mt-0">
                    <router-link
                      :to="(isAdmin ? '/admin' : '') + '/users?riskScore=HIGH'"
                      class="d-flex pb-2 justify-content-center badge bg-success-transparent text-success font-weight-semibold ms-auto rounded-pill lh-maincard"
                      style="font-size: 14px"
                      ><i class="fa fa-caret-up me-1"></i>View
                      {{ getUserOverView.HIGH.count }} user</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xxl-3">
            <div class="row border-end border-top bd-xs-e-0 p-3">
              <div class="col-12 py-0">
                <div
                  class="row d-flex align-items-center justify-content-center"
                >
                  <div class="col-12">
                    <h6 class="mb-2 pb-3 tx-12">
                      <span
                        style="
                          position: relative;
                          top: -1px;
                          display: inline-block;
                          width: 6px;
                          height: 6px;
                          vertical-align: middle;
                          border-radius: 50%;
                          background-color: rgb(250, 173, 20);
                          margin-right: 5px;
                        "
                      ></span
                      >MEDIUM RISK
                    </h6>
                    <div class="d-flex pb-3 justify-content-center">
                      <h2 class="tx-18 font-weight-semibold mb-0">
                        {{
                          getUserOverView.MEDIUM.count > 0
                            ? parseFloat(
                                getUserOverView.MEDIUM.percentage
                              ).toFixed(1) + "%"
                            : getUserOverView.MEDIUM.percentage
                        }}
                      </h2>
                    </div>
                  </div>
                  <div class="col-12 pb-0 mt-0">
                    <router-link
                      :to="
                        (isAdmin ? '/admin' : '') + '/users?riskScore=MEDIUM'
                      "
                      class="d-flex pb-2 justify-content-center badge bg-success-transparent text-success font-weight-semibold ms-auto rounded-pill lh-maincard"
                      style="font-size: 14px"
                      ><i class="fa fa-caret-up me-1"></i>View
                      {{ getUserOverView.MEDIUM.count }} user</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xxl-3">
            <div class="row border-top bd-xs-e-0 p-3">
              <div class="col-12 py-0">
                <div
                  class="row d-flex align-items-center justify-content-center"
                >
                  <div class="col-12">
                    <h6 class="mb-2 pb-3 tx-12">
                      <span
                        style="
                          position: relative;
                          top: -1px;
                          display: inline-block;
                          width: 6px;
                          height: 6px;
                          vertical-align: middle;
                          border-radius: 50%;
                          background-color: rgb(82, 196, 26);
                          margin-right: 5px;
                        "
                      ></span
                      >LOW RISK
                    </h6>
                    <div class="d-flex pb-3 justify-content-center">
                      <h2 class="tx-18 font-weight-semibold mb-0">
                        {{
                          getUserOverView.LOW.count > 0
                            ? parseFloat(
                                getUserOverView.LOW.percentage
                              ).toFixed(1) + "%"
                            : getUserOverView.LOW.percentage
                        }}
                      </h2>
                    </div>
                  </div>
                  <div class="col-12 pb-0 mt-0">
                    <router-link
                      :to="(isAdmin ? '/admin' : '') + '/users?riskScore=LOW'"
                      class="d-flex pb-2 justify-content-center badge bg-success-transparent text-success font-weight-semibold ms-auto rounded-pill lh-maincard"
                      style="font-size: 14px"
                      ><i class="fa fa-caret-up me-1"></i>View
                      {{ getUserOverView.LOW.count }} users</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SpinnerLoader :start="!visible" />
    </div>
  </div>
</template>

<script>
import SpinnerLoader from "@/components/SpinnerLoader.vue";
export default {
  name: "UserOverview",
  components: {
    SpinnerLoader,
  },
  props: {
    clientId: Number,
  },
  data() {
    return {
      visible: true,
      filters: {
      }
    };
  },
  computed: {
    getUserOverView() {
      return this.$store.getters.getUserOverView;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
  watch: {
    clientId() {
      this.filters['client_id'] = this.clientId;
      this.$store.dispatch("retrieveUserOverView", {
        filters: this.filters,
      });
    },
    getUserOverView() {
      this.visible = true;
    },
  },
  mounted() {
    this.visible = false;
    this.filters['client_id'] = this.clientId;
    this.$store.dispatch("retrieveUserOverView", {
      filters: this.filters,
    });
  },
};
</script>
