<template>
  <div>
    <table
      id="datatable_addresses"
      class="table table-bordered text-nowrap key-buttons border-bottom"
    >
      <thead>
        <tr>
          <th class="border-bottom-0">Address</th>
          <th class="border-bottom-0">Update At</th>
          <th class="border-bottom-0">Reported At</th>
          <th class="border-bottom-0">Category</th>
          <th class="border-bottom-0">Name</th>
          <th class="border-bottom-0">Rating</th>
          <th class="border-botWtom-0">Asset</th>
        </tr>
      </thead>
      <tbody v-if="loading">
        <tr class="old">
          <td valign="top" colspan="7" class="dataTables_empty">
            <SpinnerLoader :start="loading" />
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="Object.values(getAddresses).length">
        <tr v-for="address in getAddresses" :key="address.id">
          <td>
            {{ address.address }}
          </td>
          <td>{{ address.updated_date }}</td>
          <td>{{ address.reported_date }}</td>
          <td>
            {{ address.cluster_decode ? address.cluster_decode.category : "—" }}
          </td>
          <td>
            {{ address.cluster_decode ? address.cluster_decode.name : "—" }}
          </td>
          <td>{{ address.rating }}</td>
          <td>{{ address.asset }}</td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr class="old">
          <td valign="top" colspan="7" class="dataTables_empty">
            No matching records found
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<style scope>
.link-hover:hover {
  text-decoration: underline;
}
</style>
<script>
import SpinnerLoader from "@/components/SpinnerLoader.vue";

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
var $ = require("jquery");

export default {
  name: "DataTableUser",
  props: {
    getAddresses: Object,
    visible: Boolean,
  },
  components: {
    SpinnerLoader,
  },
  data() {
    return {
      table: null,
      loading: true,
    };
  },
  watch: {
    getAddresses() {
      if (this.table == null && Object.values(this.getAddresses).length)
        setTimeout(() => {
          this.table = $("#datatable_addresses").DataTable({
            scrollX: true,
            searching: false,
            paging: false,
            info: false,
            dom: "Bfrltip",
            buttons: ["csv", "print", "copy"],
          });
        }, 100);
      else if (this.table != null && Object.values(this.getAddresses).length) {
        //this.table = null;
        $('#datatable_addresses').DataTable().clear().destroy();
        setTimeout(() => {
          this.table = $("#datatable_addresses").DataTable({
            scrollX: true,
            searching: false,
            paging: false,
            info: false,
            dom: "Bfrltip",
            buttons: ["csv", "print", "copy"],
          });
        }, 100);
      }
      this.loading = false;
    },
    visible() {
      this.loading = true;
    },
  },
};
</script>
