const mutations = {
  retrieveClients(state, clients) {
    state.clients = Object.values(clients);
  },
  retrieveClientLevels(state, clientLevels) {
    state.clientLevels = Object.values(clientLevels);
  },
  retrieveRoles(state, allRoles) {
    state.allRoles = Object.values(allRoles);
  },
  changeModule(state, module) {
    state.protoModule = module;
    if (state.type == "admins") state.adminDetailBack.protoModule = module;
    else state.clientDetailBack.protoModule = module;
  },
  changeStatusBothModule(state, status) {
    state.protoModuleDashboardBoth = status;
  },
  changeNavActive(state, nav) {
    state.navActive = nav;
  },
  retrieveToken(state, [token, type, roles, offM]) {
    state.token = token;
    state.type = type;
    state.roles = roles;
    state.offMenu = offM;
    if (type == "admins") {
      state.adminDetailBack.token = token;
      state.adminDetailBack.type = type;
      state.adminDetailBack.roles = roles;
      state.adminDetailBack.protoModule = state.protoModule;
    } else {
      state.clientDetailBack.token = token;
      state.clientDetailBack.type = type;
      state.clientDetailBack.roles = roles;
      state.clientDetailBack.protoModule = state.protoModule;
      state.clientDetailBack.offMenu = state.offMenu;
    }
  },
  destroyToken(state) {
    state.adminDetailBack.token = null;
    state.adminDetailBack.type = null;
    state.adminDetailBack.roles = [];
    state.adminDetailBack.protoModule = {};
    state.isClientImpresonate = false;
    state.clientDetailBack.token = null;
    state.clientDetailBack.type = null;
    state.clientDetailBack.roles = [];
    state.clientDetailBack.protoModule = {};
    state.clientDetailBack.offMenu = false;
    state.token = null;
    state.type = null;
    state.roles = [];
    state.profile = {};
    state.offMenu = false;
  },
  switchToClient(state, [token, type, roles]) {
    state.profile = {};
    state.token = token;
    state.type = type;
    state.roles = roles;
    state.isClientImpresonate = true;
    state.clientDetailBack.token = token;
    state.clientDetailBack.type = type;
    state.clientDetailBack.roles = roles;
  },
  backToAdmin(state) {
    state.profile = {};
    state.clientDetailBack.token = null;
    state.clientDetailBack.type = null;
    state.clientDetailBack.roles = [];
    state.clientDetailBack.protoModule = "";
    state.isClientImpresonate = false;
  },
  changeTokenToAdmin(state) {
    state.token = state.adminDetailBack.token;
    state.type = state.adminDetailBack.type;
    state.roles = state.adminDetailBack.roles;
    state.protoModule = state.adminDetailBack.protoModule;
  },
  changeTokenToClient(state) {
    state.token = state.clientDetailBack.token;
    state.type = state.clientDetailBack.type;
    state.roles = state.clientDetailBack.roles;
    state.protoModule = state.clientDetailBack.protoModule;
    state.offMenu = state.clientDetailBack.offMenu;
  },
  retrieveAlerts(state, [alerts, total]) {
    state.alerts = Object.values(alerts);
    state.totalAlerts = total;
  },
  retrieveUsers(state, [users, total]) {
    state.users = Object.values(users);
    state.totalUsers = total;
  },
  retrieveErrorLogs(state, [errors, total]) {
    state.errorLogs = Object.values(errors);
    state.totalErrorLogs = total;
  },
  retrieveUsersKyc(state, [usersKyc, totalKyc]) {
    state.usersKyc = Object.values(usersKyc);
    state.totalUsersKyc = totalKyc;
  },
  retrieveTransfers(state, [transfers, total]) {
    state.transfers = transfers;
    state.totalTransfers = total;
  },
  retrieveProfile(state, profile) {
    state.profile = profile;
  },
  retrieveSingleUser(state, singleUser) {
    state.singleUser = singleUser;
  },
  retrieveSingleUserAlerts(state, [singleUserAlerts, totalSingleUserAlerts]) {
    state.singleUserAlerts = singleUserAlerts;
    state.totalSingleUserAlerts = totalSingleUserAlerts;
  },
  retrieveSingleUserTransfers(
    state,
    [singleUserTransfers, totalSingleUserTransfers]
  ) {
    state.singleUserTransfers = singleUserTransfers;
    state.totalSingleUserTransfers = totalSingleUserTransfers;
  },
  retrieveSingleUserAddresses(
    state,
    [singleUserAddresses, totalSingleUserAddresses]
  ) {
    state.singleUserAddresses = singleUserAddresses;
    state.totalSingleUserAddresses = totalSingleUserAddresses;
  },
  retrieveSingleUserKycDetails(state, singleUserKycDetails) {
    state.singleUserKycDetails = singleUserKycDetails;
  },
  retrieveSingleErrorLog(state, singleErrorLog) {
    state.singleErrorLog = singleErrorLog;
  },
  retrieveApiKeys(state, keys) {
    state.apiKeys = keys;
  },
  retrieveWebHooks(state, hooks) {
    state.webHooks = hooks.webhooks;
    if (hooks.clients) {
      state.clientWebHooks = hooks.clients;
    }
  },
  search(
    state,
    [transfersFilter, clientUsersFilter, alertsFilter, addressesFilter]
  ) {
    if (transfersFilter) state.transfersFilter = transfersFilter;
    else state.transfersFilter = {};
    if (clientUsersFilter) state.clientUsersFilter = clientUsersFilter;
    else state.clientUsersFilter = {};
    if (alertsFilter) state.alertsFilter = alertsFilter;
    else state.alertsFilter = {};
    if (addressesFilter) state.addressesFilter = addressesFilter;
    else state.addressesFilter = {};
  },
  retrieveAlertsOverView(state, alertsOverview) {
    state.alertsOverview = alertsOverview;
  },
  retrieveUserOverView(state, userOverview) {
    state.userOverview = userOverview;
  },
  retrieveKycApplicantOverView(state, kycApplicantOverview) {
    state.kycApplicantOverview = kycApplicantOverview;
  },
  retrieveLevels(state, levels) {
    state.levels = levels;
  },
  retrieveLastestAlerts(state, latestAlerts) {
    state.latestAlerts = latestAlerts;
  },
  retrieveClientsOverview(state, clientsOverview) {
    state.clientsOverview = clientsOverview;
  },
  retrieveTransferVolumeperCategory(state, transferVolumePerCategory) {
    state.transferVolumePerCategory = transferVolumePerCategory;
  },
  retrieveSingleAlert(state, singleAlert) {
    state.singleAlert = singleAlert;
  },
  retrieveAllAdmin(state, admins) {
    state.admins = admins;
  },
  retrieveAllAudit(state, audits) {
    state.audits = audits;
  },
  retrieveAllSingleUserComments(state, singleUserComments) {
    state.singleUserComments = singleUserComments;
  },
  retrieveTransferSummary(state, newTransfer) {
    var index = state.transfers.findIndex(
      (transfer) => transfer.externalId == newTransfer.externalId
    );
    state.transfers.splice(index, 1, {
      timestamp: newTransfer.timestamp ? (newTransfer.timestamp.split("T")[0] + " " + newTransfer.timestamp.split("T")[1].split(".")[0]) : newTransfer.timestamp,
      amount: newTransfer.usdAmount,
      asset: newTransfer.asset,
      updatedAt: newTransfer.updatedAt,
      externalId: newTransfer.externalId,
      tx_hash: newTransfer.tx,
      output_address: newTransfer.outputAddress,
      index: newTransfer.idx,
      id: state.transfers[index].id,
      client_user_id: state.transfers[index].client_user_id,
      created_at: state.transfers[index].created_at,
      direction: state.transfers[index].direction,
      date: state.transfers[index].date,
      risky_amount: state.transfers[index].risky_amount,
      name: state.transfers[index].name,
      category: state.transfers[index].category,
      user_id: state.transfers[index].user_id,
      partner_uid: state.transfers[index].partner_uid,
    });
  },
  retrieveSingleUserTransferSummary(state, newTransfer) {
    var index = state.singleUserTransfers.findIndex(
      (transfer) => transfer.externalId == newTransfer.externalId
    );
    state.singleUserTransfers.splice(index, 1, {
      timestamp: newTransfer.timestamp ? (newTransfer.timestamp.split("T")[0] + " " + newTransfer.timestamp.split("T")[1].split(".")[0]) : newTransfer.timestamp,
      amount: newTransfer.usdAmount,
      asset: newTransfer.asset,
      updatedAt: newTransfer.updatedAt,
      externalId: newTransfer.externalId,
      tx_hash: newTransfer.tx,
      output_address: newTransfer.outputAddress,
      index: newTransfer.idx,
      id: state.singleUserTransfers[index].id,
      client_user_id: state.singleUserTransfers[index].client_user_id,
      created_at: state.singleUserTransfers[index].created_at,
      direction: state.singleUserTransfers[index].direction,
      date: state.singleUserTransfers[index].date,
      risky_amount: state.singleUserTransfers[index].risky_amount,
      name: state.singleUserTransfers[index].name,
      category: state.singleUserTransfers[index].category,
      user_id: state.singleUserTransfers[index].user_id,
      partner_uid: state.singleUserTransfers[index].partner_uid,
    });
  },
  turnSpinnerOn(state) {
    state.spinnerStatus = !state.spinnerStatus;
  },
  storeRouteName(state, routeName) {
    state.routeName = routeName;
  },
  changeSelectedClientFilter(state, selectedClient) {
    state.selectedClientFilter = selectedClient;
  },
};
export default mutations;
