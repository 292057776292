<template>
  <div class="sticky">
    <aside class="app-sidebar horizontal-main">
      <div class="main-sidebar-header active">
        <a class="header-logo active" href="index.html">
          <img
            src="@/assets/img/brand/logo.png"
            class="main-logo desktop-logo"
            alt="logo"
          />
          <img
            src="@/assets/img/brand/logo-white.png"
            class="main-logo desktop-dark"
            alt="logo"
          />
          <img
            src="@/assets/img/brand/favicon.png"
            class="main-logo mobile-logo"
            alt="logo"
          />
          <img
            src="@/assets/img/brand/favicon-white.png"
            class="main-logo mobile-dark"
            alt="logo"
          />
        </a>
      </div>
      <div class="main-sidemenu is-expanded container">
        <div class="row">
          <ul class="side-menu col-6 flex-wrap">
            <li
              :class="'slide ' + (getNavActive == 'home' ? 'activity-nav' : '')"
            >
              <router-link
                id="home-nav"
                class="side-menu__item"
                :to="{ name: isAdmin ? 'admin-dashboard' : 'dashboard' }"
              >
                <i class="fa-solid fa-chart-line"></i>
                <span class="side-menu__label">Dashboard</span>
              </router-link>
            </li>
            <li
              v-if="(isAdmin || accessKyt) && isKytModule"
              :class="
                'slide ' +
                (getNavActive == 'alerts' ||
                getNavActive == 'withdrawal-alerts' ||
                getNavActive == 'transfer-alerts'
                  ? 'activity-nav'
                  : '')
              "
            >
              <router-link
                id="alerts-nav"
                class="side-menu__item"
                data-bs-toggle="slide"
                :to="{ name: isAdmin ? 'admin-alerts' : 'alerts' }"
              >
                <i class="fa-solid fa-triangle-exclamation"></i>
                <span class="side-menu__label">Alerts</span>
              </router-link>
              <ul class="slide-menu">
                <li @click="changeColorAlert('withdrawal')">
                  <router-link
                    id="attempt-nav"
                    :class="
                      'slide-item ' +
                      (getNavActive == 'withdrawal-alerts'
                        ? 'activity-nav'
                        : '')
                    "
                    class="slide-item"
                    :to="
                      (isAdmin ? '/admin/alerts' : '/alerts') +
                      '?alertType=withdrawal-attempt'
                    "
                    >Withdrawal Attempt</router-link
                  >
                </li>
                <li @click="changeColorAlert('transfer')">
                  <router-link
                    id="transfer-nav"
                    :class="
                      'slide-item ' +
                      (getNavActive == 'transfer-alerts' ? 'activity-nav' : '')
                    "
                    :to="
                      (isAdmin ? '/admin/alerts' : '/alerts') +
                      '?alertType=transfer'
                    "
                    >Transfer</router-link
                  >
                </li>
              </ul>
            </li>
            <li
              v-if="(isAdmin || accessKyt) && isKytModule"
              :class="
                'slide ' + (getNavActive == 'users' ? 'activity-nav' : '')
              "
            >
              <router-link
                id="users-nav"
                class="side-menu__item"
                :to="{ name: isAdmin ? 'admin-users' : 'users' }"
                ><i class="fa-solid fa-users"></i>
                <span class="side-menu__label">Users</span></router-link
              >
            </li>
            <li
              v-if="(isAdmin || accessKyt) && isKytModule"
              :class="
                'slide ' + (getNavActive == 'transfers' ? 'activity-nav' : '')
              "
            >
              <router-link
                id="transfers-nav"
                class="side-menu__item"
                :to="{ name: isAdmin ? 'admin-transfers' : 'transfers' }"
              >
                <i class="fa-solid fa-arrow-right-arrow-left"></i>
                <span class="side-menu__label">Transfers</span></router-link
              >
            </li>
            <li
              v-if="(isAdmin || accessKyt) && isKytModule"
              @click="preClick()"
              :class="
                'slide ' + (getNavActive == 'pre-screen' ? 'activity-nav' : '')
              "
            >
              <a
                id="pre-screen-nav"
                class="side-menu__item"
                href="javascript:void(0)"
                @click="showModal;"
                data-bs-toggle="modal"
                data-bs-target="#walletModal"
              >
                <i class="fa-solid fa-money-bill-transfer"></i>
                <span class="side-menu__label"
                  >Withdrawal Pre-Screening</span
                ></a
              >
            </li>
            <li
              v-if="(isAdmin || accessKyc) && isKycModule"
              :class="'slide ' + (getNavActive == 'kyc' ? 'activity-nav' : '')"
            >
              <router-link
                id="kyc-nav"
                class="side-menu__item"
                :to="{ name: isAdmin ? 'admin-kyc' : 'kyc' }"
              >
                <i class="fa-solid fa-address-card"></i>
                <span class="side-menu__label">KYC</span></router-link
              >
            </li>
          </ul>
          <ul class="side-menu flex-wrap col-6 justify-content-end">
            <li
              v-if="isAdmin"
              :class="
                'slide ' + (getNavActive == 'clients' ? 'activity-nav' : '')
              "
            >
              <router-link
                id="clients-nav"
                class="side-menu__item"
                :to="{ name: 'admin-clients' }"
              >
                <i class="fa-solid fa-user-tie"></i>
                <span class="side-menu__label">Clients</span></router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </aside>
    <WalletPreScreening />
  </div>
</template>

<style scoped>
.activity-nav .side-menu__label {
  color: rgb(36, 175, 62) !important;
}

.activity-nav .fa-solid,
.activity-nav .fas {
  color: rgb(36, 175, 62) !important;
  fill: rgb(36, 175, 62) !important;
}

.side-menu .fa-solid,
.side-menu .fas {
  font-size: 16px;
  line-height: 0;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 2px;
  text-align: center;
  color: #7b8191;
  fill: #7b8191;
}

.side-menu__item:hover .fa-solid,
.side-menu__item:hover .fas {
  color: var(--primary-bg-color) !important;
  fill: var(--primary-bg-color) !important;
}

.activity-nav {
  color: rgb(36, 175, 62) !important;
}
.slide:hover .slide-menu {
  visibility: visible;
  display: block !important;
  height: auto !important;
  position: fixed !important;
}
</style>

<script>
import WalletPreScreening from "@/components/WalletPreScreening.vue";
export default {
  name: "NavbarSlider",
  components: {
    WalletPreScreening,
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    accessKyc() {
      return this.$store.getters.accessKyc;
    },
    accessKyt() {
      return this.$store.getters.accessKyt;
    },
    isKytModule() {
      return this.$store.getters.isKytModule;
    },
    isKycModule() {
      return this.$store.getters.isKycModule;
    },
    getNavActive() {
      return this.$store.getters.getNavActive;
    },
  },
  methods: {
    changeColorAlert(subitem_id) {
      this.$store.dispatch("changeNavActive", subitem_id + "-alerts");
    },
    preClick() {
      this.$store.dispatch("changeNavActive", "pre-screen");
    },
  },
};
</script>
