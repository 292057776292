<template>
  <div class="table-responsive export-table">
    <table
      id="datatable_web_hooks"
      class="table table-bordered text-nowrap key-buttons border-bottom"
      style="text-align: center"
    >
      <thead>
        <tr class="text-center">
          <th class="border-bottom-0">ID</th>
          <th class="border-bottom-0">WebHook Name</th>
          <th class="border-bottom-0">Link</th>
          <th v-if="isAdmin" class="border-bottom-0">User Name</th>
          <th class="border-bottom-0">Control</th>
        </tr>
      </thead>
      <tbody v-if="loading">
        <tr class="old">
          <td valign="top" :colspan="isAdmin?5:4" class="dataTables_empty">
            <SpinnerLoader :start="loading" />
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="Object.values(getWebHooks).length > 0">
        <tr class="text-center" v-for="webHook in getWebHooks" :key="webHook.id">
          <td>{{ webHook.id }}</td>
          <td>{{ webHook.webhook_name }}</td>
          <td>{{ webHook.link }}</td>
          <td  v-if="isAdmin">{{ webHook.user_name }}</td>
          <td>
            <a
              href="#"
              class="mr-3"
              data-bs-toggle="modal"
              data-bs-target="#editWebHookModal"
              @click="currentWebHook(webHook)"
              ><i class="fas fa-edit"></i
            ></a>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr class="old">
          <td valign="top" :colspan="isAdmin?5:4" class="dataTables_empty">
            No matching records found
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<style scoped>
.dataTables_scrollBody {
  min-height: 130px;
}
</style>
<script>
import SpinnerLoader from "@/components/SpinnerLoader.vue";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "bootstrap";

var $ = require("jquery");
export default {
  name: "DataTableWebHook",
  props: {
    refreshTableNow: Boolean,
    getWebHooks: Object,
  },
  components: {
    SpinnerLoader,
  },
  data() {
    return {
      table: null,
      loading: true,
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    }
  },
  watch: {
    getWebHooks() {
      if (this.table == null)
        setTimeout(() => {
          this.table = $("#datatable_web_hooks").DataTable({
            scrollX: true,
          });
        }, 200);
      else if (this.table != null) {
        //this.table = null;
        $('#datatable_web_hooks').DataTable().clear().destroy();
        setTimeout(() => {
          this.table = $("#datatable_web_hooks").DataTable({
            scrollX: true,
          });
        }, 100);
      }
      this.loading = false;
    },
    refreshTableNow() {
      this.loading = true;
    },
  },
  methods: {
    currentWebHook(webHook) {
      this.$emit("currentWebHook", webHook);
    },
  },
};
</script>
