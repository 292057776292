<template>
  <div class="col-xl-2 col-lg-12 col-md-12 col-xs-12">
    <div class="card">
      <div class="card-header pb-0">
        <div class="d-flex justify-content-between">
          <h3 class="card-title mg-b-0">Clients Overview</h3>
        </div>
      </div>
      <!-- card-header -->
      <div class="card-body" v-if="visible">
        <div class="browser-stats row">
          <div
            class="d-flex col-12 justify-content-center align-items-center item my-2"
          >
            <span
              class="me-4 mt-1 font-weight-semibold tx-16"
              style="font-size: 24px"
              >{{ this.getClientsOverview.kyc }}</span
            >
            <router-link
              class="text-success fs-13 my-auto"
              :to="'/admin/clients?type=Kyc'"
              style="font-size: 16px"
              >Kyc Clients
              <i class="fe fe-trending-up text-success me-2 ms-1 my-auto"></i
            ></router-link>
          </div>
          <div
            class="d-flex col-12 justify-content-center align-items-center item my-2"
          >
            <span
              class="me-4 mt-1 font-weight-semibold tx-16"
              style="font-size: 24px"
              >{{ this.getClientsOverview.kyt }}</span
            >
            <router-link
              class="text-success fs-13 my-auto"
              :to="'/admin/clients?type=Kyt'"
              style="font-size: 16px"
              >Kyt Clients
              <i class="fe fe-trending-up text-success me-2 ms-1 my-auto"></i
            ></router-link>
          </div>
        </div>
      </div>
      <SpinnerLoader :start="!visible" />
    </div>
  </div>
</template>

<script>
import SpinnerLoader from "@/components/SpinnerLoader.vue";
export default {
  name: "ClientsOverview",
  components: {
    SpinnerLoader,
  },
  data() {
    return {
      visible: true,
    };
  },
  computed: {
    getClientsOverview() {
      return this.$store.getters.getClientsOverview;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
  watch: {
    getClientsOverview() {
      this.visible = true;
    },
  },
  mounted() {
    this.visible = false;
    this.$store.dispatch("retrieveClientsOverview");
  },
};
</script>
