<template>
  <div>
    <!-- breadcrumb -->
    <div class="breadcrumb-header justify-content-between">
      <div class="py-2 d-flex align-items-center">
        <h2 class="main-content-label mb-1">Dashboard</h2>
      </div>
      <div
        v-if="isAdmin || (accessKyt && accessKyc)"
        class="justify-content-center mt-2 row"
      >
        <div class="form-check d-flex align-items-center">
          <input
            class="form-check-input"
            type="checkbox"
            id="flexCheckDefault"
            @click="changeStatusModule()"
            :checked="isBothModule"
          />
          <label class="form-check-label" for="flexCheckDefault">
            Show all widgets
          </label>
        </div>
      </div>
    </div>
    <!-- /breadcrumb -->
    <!-- row -->
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 row">
        <AlertsOverview
          v-if="isKytModule || isBothModule"
          :clientType="'clients'"
        />

        <UserOverview
          v-if="isKytModule || isBothModule"
          :clientType="'clients'"
        />
      </div>
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 row">
        <KycApplicantOverview
          v-if="isKycModule || isBothModule"
          :clientType="'clients'"
        />
      </div>
      <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 row">
        <LatestAlerts
          v-if="isKytModule || isBothModule"
          :clientType="'clients'"
        />

        <TransferVolumeperCategory
          v-if="isKytModule || isBothModule"
          :clientType="'clients'"
        />
      </div>
    </div>

    <!--ExposureGraph /-->

    <!--TopNamedCounterpartiesTable /-->
  </div>
</template>

<script>
// @ is an alias to /src
//import TopNamedCounterpartiesTable from "@/components/Home/TopNamedCounterpartiesTable.vue";
//import ExposureGraph from "@/components/Home/ExposureGraph.vue";
import AlertsOverview from "@/components/Home/AlertsOverview.vue";
import UserOverview from "@/components/Home/UserOverview.vue";
import KycApplicantOverview from "@/components/Home/KycApplicantOverview.vue";
import LatestAlerts from "@/components/Home/LatestAlerts.vue";
import TransferVolumeperCategory from "@/components/Home/TransferVolumeperCategory.vue";
export default {
  name: "DashboardView",
  components: {
    //TopNamedCounterpartiesTable,
    //ExposureGraph,
    AlertsOverview,
    UserOverview,
    LatestAlerts,
    TransferVolumeperCategory,
    KycApplicantOverview,
  },
  props: {
    loginSuccess: {
      type: Array,
    },
  },
  computed: {
    isBothModule() {
      return this.$store.getters.isBothModule;
    },
    isKycModule() {
      return this.$store.getters.isKycModule;
    },
    isKytModule() {
      return this.$store.getters.isKytModule;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    accessKyt() {
      return this.$store.getters.accessKyt;
    },
    accessKyc() {
      return this.$store.getters.accessKyc;
    },
  },
  watch: {
    isBothModule() {
      document.getElementById("flexCheckDefault").checked = this.isBothModule;
    },
  },
  mounted() {
    //document.getElementById("flexCheckDefault").checked = this.isBothModule;
    this.$store.dispatch("changeNavActive", "home");
    if (this.isBothModule) document.title = "Dashboard | ProtoAML";
    else if (this.isKytModule) document.title = "Dashboard | ProtoAnalysis";
    else if (this.isKycModule) document.title = "Dashboard | ProtoKYC";
    document.body.classList.remove("bg-primary");
    if (this.loginSuccess)
      if (this.$store.type != "")
        this.$notify({ type: "success", text: this.loginSuccess });
  },
  methods: {
    changeStatusModule() {
      if (document.getElementById("flexCheckDefault").checked)
        this.$store.dispatch("changeStatusBothModule", true);
      else this.$store.dispatch("changeStatusBothModule", false);
    },
  },
};
</script>
