<template>
  <div v-if="visible" class="panel panel-primary card mb-0 box-shadow col-6">
    <div class="scro">
      <div class="panel-body tabs-menu-body latest-tasks p-0 border-0">
        <form action="#" @submit.prevent="commitAudit">
          <div v-if="successMessage" class="success-message">
            {{ successMessage }}
          </div>
          <div v-if="serverError.length > 0" class="server-error">
            <ul style="list-style-type: none; margin: 0px; padding: 0px" v-for="(item, index) in serverError"
              :key="index">
              <li>{{ item }}</li>
            </ul>
          </div>
          <div class="form-inline justify-content-end">
            <div class="form-group col-10">
              <select class="form-control" id="exampleFormControlSelect1" style="width: 100%" v-model="userId">
                <option value="0">Unassigned</option>
                <option v-for="item in this.allAdmin" :key="item.id" :value="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="col-2 p-0">
              <p class="char-ad" id="charsAdminAudit">
                <svg viewBox="64 64 896 896" focusable="false" data-icon="user" width="1em" height="1em"
                  fill="currentColor" aria-hidden="true">
                  <path
                    d="M858.5 763.6a374 374 0 00-80.6-119.5 375.63 375.63 0 00-119.5-80.6c-.4-.2-.8-.3-1.2-.5C719.5 518 760 444.7 760 362c0-137-111-248-248-248S264 225 264 362c0 82.7 40.5 156 102.8 201.1-.4.2-.8.3-1.2.5-44.8 18.9-85 46-119.5 80.6a375.63 375.63 0 00-80.6 119.5A371.7 371.7 0 00136 901.8a8 8 0 008 8.2h60c4.4 0 7.9-3.5 8-7.8 2-77.2 33-149.5 87.8-204.3 56.7-56.7 132-87.9 212.2-87.9s155.5 31.2 212.2 87.9C779 752.7 810 825 812 902.2c.1 4.4 3.6 7.8 8 7.8h60a8 8 0 008-8.2c-1-47.8-10.9-94.3-29.5-138.2zM512 534c-45.9 0-89.1-17.9-121.6-50.4S340 407.9 340 362c0-45.9 17.9-89.1 50.4-121.6S466.1 190 512 190s89.1 17.9 121.6 50.4S684 316.1 684 362c0 45.9-17.9 89.1-50.4 121.6S557.9 534 512 534z">
                  </path>
                </svg>
              </p>
            </div>
          </div>
          <div class="form-group">
            <textarea v-model="comment" class="form-control" id="exampleFormControlTextarea1" rows="3"
              placeholder="Write your commit here" name="comment"></textarea>
          </div>
          <div class="form-inline justify-content-end">
            <div class="form-group mx-sm-3 mb-2">
              <select class="form-control" style="width: 150px" id="exampleFormControlSelect1" name="status"
                v-model="status">
                <option value="0">Unreviewed</option>
                <option value="1">In Review</option>
                <option value="2">Dismissed</option>
                <option value="3">Flagged</option>
                <option value="4">No Review</option>
              </select>
            </div>
            <button type="submit" id="btnAuditForm" class="btn btn-primary mb-2">
              Comment
            </button>
          </div>
        </form>
        <div style="max-width: 300px">
          <ul class="operation">
            <AuditListAlert v-for="item in getAllAudit" :key="item.id" :audit="item" />
          </ul>
        </div>
      </div>
    </div>
  </div>
  <SpinnerLoader :start="!visible" class="col-6" />
</template>
<style scope>
.scro {
  flex-grow: 1;
  padding: 10px;
  height: 150px;
  overflow: auto;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}

.operation {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  -webkit-font-feature-settings: "tnum", "tnum";
  font-feature-settings: "tnum", "tnum";
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: left;
}

.one-operation {
  position: relative;
  margin: 0;
  padding-bottom: 20px;
  font-size: 14px;
  list-style: none;
  box-sizing: border-box;
}

.icon-operation {
  position: absolute;
  top: 5.5px;
  left: 5px;
  width: auto;
  height: auto;
  margin-top: 0;
  padding: 3px 1px;
  line-height: 1;
  text-align: center;
  border: 0;
  border-radius: 0;
  transform: translate(-50%, -50%);
}

.tail {
  position: absolute;
  top: 18px;
  left: 4px;
  height: calc(100% - 25px);
  border-left: 2px solid #c7c7c7;
}

.one-operation:last-child .tail {
  display: none;
}

.content-operation {
  position: relative;
  top: -7.001px;
  margin: 0 0 0 26px;
  word-break: break-word;
}

.form-control {
  border-color: gray;
}

.char-ad {
  background: rgb(204, 204, 204);
  border-radius: 50px;
  width: 39px;
  height: 35px;
  display: flex;
  /* padding: 3px; */
  color: rgb(255, 255, 255);
  font-size: 19px;
  justify-content: center;
  align-items: center;
  margin-bottom: 13px;
  margin-top: 10px;
}
</style>
<script>
import SpinnerLoader from "@/components/SpinnerLoader.vue";
import AuditListAlert from "@/components/Alerts/AuditListAlert.vue";
var $ = require("jquery");
export default {
  name: "CommentSectionAlert",
  props: {
    assigneeId: {
      type: Number,
      required: true,
    },
    reviewStatus: {
      type: Number,
      required: true,
    },
    allAdmin: {
      type: Array,
      required: true,
    },
    alertId: {
      type: Number,
      required: true,
    },
  },
  components: {
    SpinnerLoader,
    AuditListAlert,
  },
  data() {
    return {
      orginalUserId: 0,
      userId: 0,
      comment: "",
      status: 0,
      successMessage: "",
      serverError: [],
      assignRequest: "Comment",
      reviewRequest: "Comment",
      commentRequest: "Comment",
      visible: false,
    };
  },
  computed: {
    getAllAudit() {
      return this.$store.getters.getAllAudit;
    },
  },
  mounted() {
    this.visible = false;
    this.$store
      .dispatch("retrieveAllAudit", {
        alertId: this.alertId,
      })
      .then();
    // setTimeout(() => {

    // }, 500);
    this.orginalUserId = this.assigneeId != null ? this.assigneeId : 0;
    this.userId = this.assigneeId != null ? this.assigneeId : 0;
    this.orginalStatus = this.reviewStatus != null ? this.reviewStatus : 0;
    this.status = this.reviewStatus != null ? this.reviewStatus : 0;
  },
  watch: {
    getAllAudit() {
      this.visible = true;
    },
    userId() {
      if (this.orginalStatus != this.status || this.userId != this.orginalUserId) {
        $("#btnAuditForm").html("Confirm");
        this.assignRequest = "Confirm";
      }
      else {
        this.assignRequest = "Comment";
        $("#btnAuditForm").html("Comment");
      }
      // if (this.userId > 0) {
      //   var nn = this.allAdmin.filter((admin) => admin.id == this.orginalUserId);
      //   console.log(nn);
      //   $("#charsAdminAudit").html(nn[0].name[0] + nn[0].name[1]);
      // } else
      //   $("#charsAdminAudit").html('<svg viewBox="64 64 896 896" focusable="false" data-icon="user" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M858.5 763.6a374 374 0 00-80.6-119.5 375.63 375.63 0 00-119.5-80.6c-.4-.2-.8-.3-1.2-.5C719.5 518 760 444.7 760 362c0-137-111-248-248-248S264 225 264 362c0 82.7 40.5 156 102.8 201.1-.4.2-.8.3-1.2.5-44.8 18.9-85 46-119.5 80.6a375.63 375.63 0 00-80.6 119.5A371.7 371.7 0 00136 901.8a8 8 0 008 8.2h60c4.4 0 7.9-3.5 8-7.8 2-77.2 33-149.5 87.8-204.3 56.7-56.7 132-87.9 212.2-87.9s155.5 31.2 212.2 87.9C779 752.7 810 825 812 902.2c.1 4.4 3.6 7.8 8 7.8h60a8 8 0 008-8.2c-1-47.8-10.9-94.3-29.5-138.2zM512 534c-45.9 0-89.1-17.9-121.6-50.4S340 407.9 340 362c0-45.9 17.9-89.1 50.4-121.6S466.1 190 512 190s89.1 17.9 121.6 50.4S684 316.1 684 362c0 45.9-17.9 89.1-50.4 121.6S557.9 534 512 534z"></path></svg>');
    },
    status() {
      if (this.orginalStatus != this.status || this.userId != this.orginalUserId) {
        $("#btnAuditForm").html("Confirm");
        this.reviewRequest = "Confirm";
      }
      else {
        this.reviewRequest = "Comment";
        $("#btnAuditForm").html("Comment");
      }
    },
    comment() {
      if (this.comment != '') {
        this.commentRequest = "Confirm";
      }
      else {
        this.commentRequest = "Comment";
      }
    }
  },
  methods: {
    commitAudit() {
      if (this.assignRequest == "Confirm" || this.reviewRequest == "Confirm" || this.commentRequest == "Confirm")
        $("#btnAuditForm").html('<div class="lds-ellipsis" v-if="loading"><div></div><div></div><div></div><div></div></div>');
      if (this.assignRequest == "Confirm") {
        this.$store
          .dispatch("sendAssignRequestAlert", {
            alertId: this.alertId,
            user_id: this.userId,
            comment: this.comment,
          })
          .then((response) => {
            this.orginalUserId = this.userId;
            if (this.reviewRequest != "Confirm" && this.commentRequest != "Confirm") {
              $("#btnAuditForm").html("Comment");
              this.assignRequest = "Comment";
              this.successMessage = response.message[0];
              setTimeout(() => {
                this.successMessage = "";
              }, 2000);
              this.$store.dispatch("retrieveAllAudit", {
                alertId: this.alertId,
              });
            }
          })
          .catch((error) => {
            if (this.reviewRequest != "Confirm" && this.commentRequest != "Confirm") {
              $("#btnAuditForm").html("Confirm");
            }
            this.serverError = error.response.data.message;
            setTimeout(() => {
              this.serverError = [];
            }, 2000);
          });
      }

      if (this.reviewRequest == "Confirm" || (this.assignRequest == "Confirm" && this.reviewRequest == "Comment" && this.commentRequest == "Confirm")) {
        this.$store
          .dispatch("sendReviewRequestAlert", {
            alertId: this.alertId,
            comment: this.comment,
            status: this.status,
          })
          .then((response) => {
            this.orginalStatus = this.status;
            this.successMessage = response.message[0];
            $("#btnAuditForm").html("Comment");
            this.assignRequest = "Comment";
            this.reviewRequest = "Comment";
            this.commentRequest = "Comment";
            this.comment = "";
            setTimeout(() => {
              this.successMessage = "";
            }, 2000);
            this.$store.dispatch("retrieveAllAudit", {
              alertId: this.alertId,
            });
          })
          .catch((error) => {
            $("#btnAuditForm").html("Confirm");
            this.serverError = error.response.data.message;
            setTimeout(() => {
              this.serverError = [];
            }, 2000);
          });
      }
      else if (this.assignRequest == "Comment" && this.reviewRequest == "Comment" && this.commentRequest == "Confirm") {
        this.$store
          .dispatch("sendReviewRequestAlert", {
            alertId: this.alertId,
            comment: this.comment,
            status: this.status,
          })
          .then((response) => {
            this.successMessage = response.message[0];
            $("#btnAuditForm").html("Comment");
            this.commentRequest = "Comment";
            this.comment = "";
            setTimeout(() => {
              this.successMessage = "";
            }, 2000);
            this.$store.dispatch("retrieveAllAudit", {
              alertId: this.alertId,
            });
          })
          .catch((error) => {
            $("#btnAuditForm").html("Comment");
            this.serverError = error.response.data.message;
            setTimeout(() => {
              this.serverError = [];
            }, 2000);
          });
      }


    },
  },
};
</script>
