<template>
  <div class="row row-sm text-start mt-5">
    <div class="col-lg-12">
      <div class="py-2 col-12 row">
        <div class="col-12 col-md-6 d-flex justify-content-start">
          <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <h2 class="main-content-label mb-1">KYC</h2>
            </div>
          </nav>
        </div>
        <!-- client filter -->
        <ClientFilterComponent @changeClientId="filterChanged" />
      </div>
      <div class="my-2 col-12 justify-content-center">
        <div class="row justify-content-end align-items-end filter-s px-0 mt-2">
          <button @click="exportAll" class="btn btn-primary" style="margin-right: 10px;">
            <div class="lds-ellipsis" v-if="export_loading">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <span v-if="!export_loading">Export All</span>
          </button>
          <!-- <FilterComponent
            v-if="sortByTypes.length > 0"
            :title="'Sort Type'"
            :arrayData="sortByTypes"
            :variableName="'sortType'"
            @changeFilter="filterChanged"
          /> -->
        </div>
      </div>
      <div class="card custom-card overflow-hidden">
        <div class="card-body">
          <div class="table-responsive export-table">
            <PaginationComponent
              :page="this.filters.page"
              :limit="this.filters.limit"
              :getTotalRows="this.getTotalUsersKyc"
              :nameComponent="this.nameComponent"
              @changePage="filterChanged"
            />
            <!-- pagination and total -->
            <!-- table -->
            <DataTableUser
              @generatedLink="generatedNewLink"
              @refresh="refreshTable"
              :getUsersKyc="this.getUsersKyc"
              :visible="visible"
            />

            <!-- pagination and total -->
            <PaginationComponent
              :page="this.filters.page"
              :limit="this.filters.limit"
              :getTotalRows="this.getTotalUsersKyc"
              :nameComponent="this.nameComponent"
              @changePage="filterChanged"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scope>
.assignment_icon {
  font-size: 30px !important;
  color: #cbcbcb !important;
}
.wrap_item_name {
  white-space: pre-wrap;
}
.select_whithout_arrow {
  background-image: none !important;
  font-size: 13px;
  font-weight: 900;
}
.select_whithout_arrow option {
  font-size: 13px;
}
.dropdown-scrollbar {
  height: 150px;
  overflow: scroll;
}
.btn-paging {
  background-color: #e1e1e1;
  border: 1px solid #aaa;
  font-weight: 600;
}

.btn-paging:hover {
  background-color: #fff;
}
.total_rows {
  font-size: 14px;
  color: #858f99;
}
.dt-button {
  background-color: #e5e5e5;
  border: 1px solid #d7d3d3;
  color: #7e7d7d;
  border-radius: 8px;
  font-weight: 100;
  font-size: 13px;
}
.dt-button:hover {
  background-color: #fff;
  color: #7e7d7d;
}
</style>

<script>
import ClientFilterComponent from "@/components/ClientFilterComponent.vue";
import DataTableUser from "@/components/UsersKyc/DataTableUser.vue";
import PaginationComponent from "@/components/PaginationComponent.vue";

export default {
  name: "AdminKycView",
  components: {
    ClientFilterComponent,
    DataTableUser,
    PaginationComponent,
  },
  data() {
    return {
      filters: {
        page: 1,
        limit: 50,
      },
      visible: false,
      nameComponent: "Users",
      export_loading: false,
    };
  },
  computed: {
    getUsersKyc() {
      return this.$store.getters.getUsersKyc;
    },
    getTotalUsersKyc() {
      return this.$store.getters.getTotalUsersKyc;
    },
    getSelectedClientFilter() {
      return this.$store.getters.getSelectedClientFilter;
    },
  },
  mounted() {
    this.$store.dispatch("changeNavActive", "kyc");
    document.title = "KYC  | ProtoKYC";
    this.$store.dispatch("changeModule", "KYC");
    this.filters['client_id'] = this.getSelectedClientFilter;
    this.$store.dispatch("retrieveUsersKyc", {
      filters: this.filters,
      param: this.$route.query,
    });
  },
  methods: {
    filterChanged(value) {
      if(value[0] != 'page') {
        this.filters['page'] = 1;  
      }
      this.visible = !this.visible;
      this.filters[value[0]] = value[1];
      this.$store.dispatch("retrieveUsersKyc", {
        filters: this.filters,
        param: this.$route.query,
      });
    },
    generatedNewLink(value) {
      this.visible = !this.visible;
      if (value == true)
        this.$store.dispatch("retrieveUsersKyc", {
          filters: this.filters,
          param: this.$route.query,
        });
    },
    refreshTable(value) {
      if (value)
        this.visible = !this.visible;
        this.$store.dispatch("retrieveUsersKyc", {
          filters: this.filters,
          param: this.$route.query,
        });
    },
    exportAll() {
        let exportFilters = Object.assign({}, this.filters)
        delete exportFilters['page']
        delete exportFilters['limit']
        console.log(exportFilters)
        this.export_loading = true
        this.$store.dispatch("exportAllUsersKyc", {
          filters: exportFilters,
          param: this.$route.query,
        })
          .then(() => {
            this.export_loading = false
            this.$notify({ type: "success", text: "Export Completed" });
          })
          .catch(() => {
            this.export_loading = false
            this.$notify({ type: "wronge", text: "Export Fail!!" });
          });
      }
  },
};
</script>
