<template>
  <div>
    <MainHeader />
    <div class="jumps-prevent" style="padding-top: 63px"></div>
    <NavbarSlider />
  </div>
</template>

<script>
import NavbarSlider from "@/components/Layout/Nav/NavbarSlider.vue";
import MainHeader from "@/components/Layout/MainHeader.vue";
export default {
  name: "UpHeader",
  components: {
    NavbarSlider,
    MainHeader,
  },
};
</script>
