<template>
  <div>
    <form action="#" @submit.prevent="addClientLevel">
      <div v-if="successMessage" class="success-message">
        {{ successMessage }}
      </div>
      <div v-if="serverError.length > 0" class="server-error">
        <ul
          style="list-style-type: none; margin: 0px; padding: 0px"
          v-for="(item, index) in serverError"
          :key="index"
        >
          <li>{{ item }}</li>
        </ul>
      </div>
      <div cass="mb-3 text-start">
        <p style="color: red; font-size: 12px">
          ** please make sure that the tier name is exactly same as the level
          name defined in Sumsub dashboard **
        </p>
        <label for="level_name" class="col-form-label">Name:</label>
        <div class="d-flex">
          <input
            type="text"
            style="margin-right: 10px"
            class="form-control"
            id="level_name"
            name="level_name"
            v-model="levelName"
            required
          />
          <button
            type="submit"
            :disabled="!buttonVisiable"
            class="btn btn-primary"
          >
            Add
          </button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
export default {
  name: "AddApplicantLevel",
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      levelName: "",
      successMessage: "",
      serverError: [],
      buttonVisiable: true,
    };
  },
  computed: {
    getProfile() {
      return this.$store.getters.getProfile;
    },
  },
  watch: {
    client() {
      this.levelName = this.client.prefix + "_";
    },
    getProfile() {
      this.levelName = this.getProfile.prefix + "_";
    },
  },
  methods: {
    addClientLevel() {
      this.successMessage = "";
      this.buttonVisiable = false;
      this.$store
        .dispatch("addKycLevelToClient", {
          clientId: this.client.id,
          name: this.levelName,
        })
        .then((response) => {
          this.successMessage = response.message[0];
          this.levelName = this.client.prefix + "_";
          this.$emit("refreshLevels", true);
          this.buttonVisiable = true;
        })
        .catch((error) => {
          if (Array.isArray(error.response.data.message))
            this.serverError = error.response.data.message;
          else if (error.response.data.message)
            this.serverError = [error.response.data.message];
          else if (typeof error.response.data.errors == "string")
            this.serverError = [error.response.data.errors];
          else this.serverError = Object.values(error.response.data.errors);
          setTimeout(() => {
            this.serverError = [];
          }, 2000);
          this.buttonVisiable = true;
        });
    },
  },
};
</script>
