<template>
  <div class="page-single">
    <div class="container">
      <div class="row">
        <div
          class="col-xl-5 col-lg-6 col-md-8 col-sm-8 col-xs-10 card-sigin-main mx-auto my-auto py-4 justify-content-center"
        >
          <div class="card-sigin">
            <!-- Demo content-->
            <div class="main-card-signin d-md-flex">
              <div class="w-100">
                <div class="d-flex mb-4 justify-content-center">
                  <a href="index.html"
                    ><img
                      src="../../assets/img/PROTOAML.png"
                      class="sign-favicon ht-40"
                      alt="logo"
                  /></a>
                </div>
                <div class="">
                  <div class="main-signup-header">
                    <h2>Bye Bye!</h2>
                    <h6 class="font-weight-semibold mb-4">
                      I hope you enjoy with our services!!
                    </h6>
                    <SpinnerLoader :start="true" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SpinnerLoader from "@/components/SpinnerLoader.vue";
export default {
  name: "LogoutView",
  components: {
    SpinnerLoader,
  },
  computed: {
    isAdmin() { 
      return this.$store.getters.isAdmin;
    }
  },
  created() {
    document.title = "ProtoAnalysis";
    if (this.isAdmin) {
      this.$store
        .dispatch("destroyToken")
        .then((response) => {
          this.$router.push({
            name: "admin",
            params: { logoutSuccess: response.message },
          });
        })
        .catch(() => {
          this.$router.push({
            name: "admin",
          });
        });
    } else {
      this.$store.dispatch("destroyToken").then((response) => {
        this.$router
          .push({
            name: "home",
            params: { logoutSuccess: response.message },
          })
          .catch(() => {
            this.$router.push({
              name: "home",
            });
          });
      });
    }
  },
};
</script>
