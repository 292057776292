<template>
    <div>
        
    </div>
</template>

<script>
var $ = require("jquery");
export default {
    name: "AdminSwitch",
    props: ['clientId'],
    mounted() {
        $('#global-loader').css('display','block');
        this.$store.dispatch('switchToClient', {
            clientId: this.clientId
        });
    },
}
</script>