<template>
  <div id="chart">
    <apexchart
      id="statistics2"
      type="area"
      height="500"
      :options="chartOptions"
      :series="series"
      ref="chart"
    ></apexchart>
  </div>
</template>
<script>
export default {
  name: "ApexChartTransfer",
  props: {
    transferVolumeperCategory: Array,
  },
  created() {
    //console.log(this.series[0].data);
  },
  data() {
    return {
      series: [
        {
          name: "series1",
          data: this.transferVolumeperCategory.map((a) => a.volume),
        },
      ],
      chartOptions: {
        chart: {
          height: 500,
          type: "area",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        colors: ["#27D04F"],
        xaxis: {
          type: "datetime",
          categories: this.transferVolumeperCategory.map((a) => a.dateRangeEnd),
          /*tooltip: {
            formatter: function (val, opts) {
              //console.log(opts.w.globals.lastXAxis.v);
              //console.log(val - 1);
              return opts.w.globals.lastXAxis.v[val - 1];
            },
          },*/
          tooltip: {
            enabled: false,
          },
          v: this.transferVolumeperCategory.map((a) => a.dateRange),
        },
        tooltip: {
          custom: function ({ dataPointIndex, w }) {
            return w.globals.lastXAxis.v[dataPointIndex];
          },
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return val.toFixed(1);
            },
          },
        },
      },
    };
  },
};
</script>
