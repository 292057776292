import { createStore } from "vuex";
import "./externalUrl";
import stateStore from "./state";
import gettersStore from "./getters";
import mutationsStore from "./mutations";
import actionsStore from "./actions";
import createPersistedState from 'vuex-persistedstate';
import Cookies from 'js-cookie';
import VueCryptojs from "crypto-js";

//const keys = ['type','token','roles','permissions'];
export default createStore({
  state: stateStore,
  plugins: [createPersistedState({
    paths: ['type','token','roles','protoModule','offMenu','adminDetailBack','clientDetailBack','isClientImpresonate'],
    storage: {
      getItem: (key) => {
        if(key == "vuex"){
          if(Cookies.get("_r3wKrLzz3") !== undefined)
            return (VueCryptojs.AES.decrypt(Cookies.get("_r3wKrLzz3"), '2puM!q59&2@PDpX7').toString(VueCryptojs.enc.Utf8))
          else
            return Cookies.get(key)
        }
        else
          return Cookies.get(key)
      },
      setItem: (key, value) => {
          if(key == "vuex"){
            Cookies.set("_r3wKrLzz3", VueCryptojs.AES.encrypt(value, '2puM!q59&2@PDpX7').toString(), { expires: 3, secure: true })
          }
          else
            Cookies.set(key, value, { expires: 3, secure: true })
      },
      removeItem: key => {
        if(key == "vuex")
          Cookies.remove("_r3wKrLzz3")
        else
          Cookies.remove(key)

      }
    }
  })],
  getters: gettersStore,
  mutations: mutationsStore,
  actions: actionsStore,
  modules: {},
});