<template>
  <div>
    <table
      id="datatable_alerts"
      class="table table-bordered text-nowrap key-buttons border-bottom"
      style="text-align: center"
    >
      <thead>
        <tr>
          <th class="border-bottom-0">Severity</th>
          <th class="border-bottom-0">User Id</th>
          <th class="border-bottom-0">Assignment</th>
          <th class="border-bottom-0">Category</th>
          <th class="border-bottom-0">Alert Type</th>
          <th class="border-bottom-0">Amount</th>
          <th class="border-bottom-0">Direction</th>
          <th class="border-bottom-0">Exposure</th>
          <th class="border-bottom-0">Name</th>
          <th class="border-bottom-0">State</th>
          <th class="border-bottom-0">Created At</th>
          <!--th class="border-bottom-0">Assignment</th-->
          <th class="border-bottom-0">Status</th>
        </tr>
      </thead>
      <tbody v-if="loading">
        <tr class="old">
          <td valign="top" colspan="12" class="dataTables_empty">
            <SpinnerLoader :start="loading" />
          </td>
        </tr>
      </tbody>
      <tbody
        v-else-if="Object.values(getAlerts).length"
        style="min-height: 200px"
      >
        <tr v-for="alert in getAlerts" :key="alert.id" style="cursor: pointer">
          <td @click="moveToSingle(alert.id)">
            <span :class="'severity_' + alert.severity.toLowerCase()">{{
              alert.severity
            }}</span>
          </td>
          <td>
            <router-link
              v-if="isAdmin"
              class="link-hover"
              :to="{
                name: 'admin-single-user',
                params: { userId: alert.user_id },
              }"
              >{{ alert.user_id }}</router-link
            >
            <router-link
              v-else
              class="link-hover"
              :to="{ name: 'single-user', params: { userId: alert.user_id } }"
              >{{ alert.partner_id }}</router-link
            >
          </td>
          <td @click="moveToSingle(alert.id)">
            <div v-if="alert.assignee == 'unassigned'" class="toolpop">
              <p
                class="char-ad"
                @mouseover="defineCopy(alert.id)"
                @mouseout="backToOrigin(alert.id)"
                id="charsAdmin"
              >
                <svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  data-icon="user"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M858.5 763.6a374 374 0 00-80.6-119.5 375.63 375.63 0 00-119.5-80.6c-.4-.2-.8-.3-1.2-.5C719.5 518 760 444.7 760 362c0-137-111-248-248-248S264 225 264 362c0 82.7 40.5 156 102.8 201.1-.4.2-.8.3-1.2.5-44.8 18.9-85 46-119.5 80.6a375.63 375.63 0 00-80.6 119.5A371.7 371.7 0 00136 901.8a8 8 0 008 8.2h60c4.4 0 7.9-3.5 8-7.8 2-77.2 33-149.5 87.8-204.3 56.7-56.7 132-87.9 212.2-87.9s155.5 31.2 212.2 87.9C779 752.7 810 825 812 902.2c.1 4.4 3.6 7.8 8 7.8h60a8 8 0 008-8.2c-1-47.8-10.9-94.3-29.5-138.2zM512 534c-45.9 0-89.1-17.9-121.6-50.4S340 407.9 340 362c0-45.9 17.9-89.1 50.4-121.6S466.1 190 512 190s89.1 17.9 121.6 50.4S684 316.1 684 362c0 45.9-17.9 89.1-50.4 121.6S557.9 534 512 534z"
                  ></path>
                </svg>
                <span class="toolpoptext" :id="'myToolpop_' + alert.id"
                  >Unassigned</span
                >
              </p>
            </div>

            <div v-else class="toolpop">
              <p
                class="char-ad"
                style="background-color: rgb(235, 47, 150)"
                @mouseover="defineCopy(alert.id)"
                @mouseout="backToOrigin(alert.id)"
                id="charsAdmin"
              >
                {{
                  /*alert.assignee.split(" ")[0][0] + alert.assignee.split(" ")[1][0]*/
                  alert.assignee[0]
                }}
                <span class="toolpoptext" :id="'myToolpop_' + alert.id">{{
                  alert.assignee
                }}</span>
              </p>
            </div>
          </td>
          <td @click="moveToSingle(alert.id)">{{ alert.category }}</td>
          <td @click="moveToSingle(alert.id)">{{ alert.alert_type }}</td>
          <td @click="moveToSingle(alert.id)">{{ alert.amount }}</td>
          <td @click="moveToSingle(alert.id)">{{ alert.direction }}</td>
          <td @click="moveToSingle(alert.id)">{{ alert.exposure }}</td>
          <td @click="moveToSingle(alert.id)" class="wrap_item_name">{{ alert.name }}</td>
          <td @click="moveToSingle(alert.id)">{{ alert.state }}</td>
          <td @click="moveToSingle(alert.id)">{{ alert.createdAt }}</td>
          <!--td class="assignment_icon">
                    <i class="fas fa-user-circle"></i>
                  </td-->
          <td>
            <div v-if="isAdmin" class="dropdown dropend">
              <button
                aria-expanded="false"
                aria-haspopup="true"
                class="btn ripple btn-info btn-approve"
                data-bs-toggle="dropdown"
                id="droprightMenuButton"
                type="button"
                v-if="alert.status == 1"
              >
                APPROVED<i class="fas fa-caret-right ms-1"></i>
              </button>
              <button
                aria-expanded="false"
                aria-haspopup="true"
                class="btn ripple btn-info btn-approve"
                data-bs-toggle="dropdown"
                id="droprightMenuButton"
                type="button"
                v-else-if="alert.status == 4"
                style="white-space: pre-wrap; padding: 0px"
              >
                AUTOMATICALLY APPROVED<i class="fas fa-caret-right ms-1"></i>
              </button>
              <button
                aria-expanded="false"
                aria-haspopup="true"
                class="btn ripple btn-info btn-on-hold"
                data-bs-toggle="dropdown"
                id="droprightMenuButton"
                type="button"
                v-else-if="alert.status == 0"
              >
                ON HOLD<i class="fas fa-caret-right ms-1"></i>
              </button>
              <button
                aria-expanded="false"
                aria-haspopup="true"
                class="btn ripple btn-info btn-on-hold"
                data-bs-toggle="dropdown"
                id="droprightMenuButton"
                type="button"
                v-else-if="alert.status == 2"
              >
                REJECTED<i class="fas fa-caret-right ms-1"></i>
              </button>
              <button
                aria-expanded="false"
                aria-haspopup="true"
                class="btn ripple btn-info btn-on-hold"
                data-bs-toggle="dropdown"
                id="droprightMenuButton"
                type="button"
                v-else-if="alert.status == 3"
              >
                FROZEN<i class="fas fa-caret-right ms-1"></i>
              </button>
              <div
                aria-labelledby="droprightMenuButton"
                class="dropdown-menu tx-13"
                style=""
              >
                <a
                  class="dropdown-item own-dropdown"
                  href="javascript:void(0);"
                  v-if="alert.alert_type == 'withdrawal attempt'"
                  @click="changeStatus(alert.id, 'approve')"
                  >Approve</a
                >
                <a
                  class="dropdown-item own-dropdown"
                  href="javascript:void(0);"
                  v-if="alert.alert_type == 'withdrawal attempt'"
                  @click="changeStatus(alert.id, 'reject')"
                  >Reject</a
                >
                <a
                  class="dropdown-item own-dropdown"
                  href="javascript:void(0);"
                  @click="changeStatus(alert.id, 'freeze')"
                  >Freeze</a
                >
              </div>
            </div>
            <div
              v-else
              :style="
                alert.status == 0
                  ? 'color: red;border: 2px solid;padding: 5px 20px;text-align: center;'
                  : alert.status == 1
                  ? 'color: rgb(34 175 66);border: 2px solid;padding: 5px 20px;text-align: center;'
                  : alert.status == 2
                  ? 'color: red;border: 2px solid;padding: 5px 20px;text-align: center;'
                  : alert.status == 3
                  ? 'color: red;border: 2px solid;padding: 5px 20px;text-align: center;'
                  : alert.status == 4
                  ? 'color: rgb(34 175 66);border: 2px solid;padding: 5px 20px;text-align: center;'
                  : ''
              "
            >
              {{
                alert.status == 0
                  ? "On Hold"
                  : alert.status == 1
                  ? "Approved"
                  : alert.status == 2
                  ? "Rejected"
                  : alert.status == 3
                  ? "Frozen"
                  : alert.status == 4
                  ? "Automatically Approved"
                  : ""
              }}
            </div>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr class="old">
          <td valign="top" colspan="12" class="dataTables_empty">
            No matching records found
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<style scope>
.wrap_item_name {
  overflow-wrap: break-word;
  inline-size: 100px;
}
.dropdown-menu {
  margin: unset !important;
}
.btn-approve,
.btn-on-hold {
  width: 125px !important;
}
.char-ad {
  background: rgb(204, 204, 204);
  border-radius: 50px;
  width: 39px;
  height: 35px;
  display: flex;
  /* padding: 3px; */
  color: rgb(255, 255, 255);
  font-size: 19px;
  justify-content: center;
  align-items: center;
  margin-bottom: 13px;
  margin-top: 10px;
}
.toolpop {
  position: relative;
  display: inline-block;
}

.toolpop .toolpoptext {
  visibility: hidden;
  width: 140px;
  background-color: #555 !important;
  color: #fff !important;
  text-align: center !important;
  font-size: 14px;
  border-radius: 6px !important;
  padding: 5px !important;
  position: absolute !important;
  z-index: 1 !important;
  bottom: 28% !important;
  left: 120px !important;
  margin-left: -75px !important;
  opacity: 0;
  transition: opacity 0.3s;
}
.card .dataTables_wrapper .dropdown-menu {
  border: 1px solid #c9c7c7;
}
.toolpop .toolpoptext::after {
  content: "";
  position: absolute;
  top: 35% !important;
  left: -5px !important;
  margin-left: -5px !important;
  border-width: 5px !important;
  border-style: solid !important;
  border-color: transparent #555 transparent transparent !important;
}
.pop {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}
</style>
<script>
import SpinnerLoader from "@/components/SpinnerLoader.vue";

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
var $ = require("jquery");

export default {
  name: "DataTableAlert",
  props: {
    getAlerts: Object,
    visible: Boolean,
  },
  components: {
    SpinnerLoader,
  },
  data() {
    return {
      table: null,
      loading: true,
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
  watch: {
    getAlerts() {
      if (this.table == null && Object.values(this.getAlerts).length)
        setTimeout(() => {
          this.table = $("#datatable_alerts").DataTable({
            scrollX: true,
            searching: false,
            paging: false,
            ordering: false,
            info: false,
            dom: "Bfrltip",
            buttons: ["csv", "print", "copy"],
          });
        }, 100);
      else if (this.table != null && Object.values(this.getAlerts).length) {
        //this.table = null;
        $('#datatable_alerts').DataTable().clear().destroy();
        setTimeout(() => {
          this.table = $("#datatable_alerts").DataTable({
            scrollX: true,
            searching: false,
            paging: false,
            ordering: false,
            info: false,
            dom: "Bfrltip",
            buttons: ["csv", "print", "copy"],
          });
        }, 100);
      }
      this.loading = false;
    },
    visible() {
      this.loading = true;
    },
  },
  methods: {
    changeStatus(id, value) {
      if (this.isAdmin) {
        this.loading = true;
        this.$store
          .dispatch("changeStatusAlerts", {
            id: id,
            new_status: value,
          })
          .then((response) => {
            
            this.$store
              this.$notify({ type: "success", text: response.message[0] });
              this.$emit("refresh", true);
          })
          .catch((error) => {
            console.log(error);
            this.$notify({
              type: "error",
              text:
                error.response.data.message == null
                  ? "Fail"
                  : error.response.data.message,
            });
          });
      }
    },
    moveToSingle(alert_id) {
      if (
        this.$route.name == "admin-single-user" ||
        this.$route.name == "single-user"
      ) {
        this.$emit("alertIdValue", alert_id);
      } else {
        if (this.isAdmin)
          this.$router.push({
            name: "admin-single-alert",
            params: { alertId: alert_id },
          });
        else
          this.$router.push({
            name: "single-alert",
            params: { alertId: alert_id },
          });
      }
    },
    defineCopy(ind) {
      var toolpop = document.getElementById("myToolpop_" + ind);
      toolpop.style.visibility = "visible";
      toolpop.style.opacity = 1;
    },
    backToOrigin(ind) {
      var toolpop = document.getElementById("myToolpop_" + ind);
      toolpop.style.visibility = "hidden";
      toolpop.style.opacity = 0;
    },
  },
};
</script>
