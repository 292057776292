<template>
  <div>
    <table
      id="datatable_users"
      class="table table-bordered text-nowrap key-buttons border-bottom"
      style="text-align: center"
    >
      <thead>
        <tr>
          <th class="border-bottom-0">User ID</th>
          <th class="border-bottom-0">Risk Score</th>
          <th class="border-bottom-0">Status</th>
          <th class="border-bottom-0">Last Activity</th>
          <th class="border-botWtom-0">Risky Volume</th>
          <th class="border-bottom-0">Transfer Volume</th>
        </tr>
      </thead>
      <tbody v-if="loading">
        <tr class="old">
          <td valign="top" colspan="6" class="dataTables_empty">
            <SpinnerLoader :start="loading" />
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="Object.values(getUsers).length">
        <tr v-for="user in getUsers" :key="user.id">
          <td>
            <router-link
              v-if="isAdmin"
              class="link-hover"
              :to="{
                name: 'admin-single-user',
                params: { userId: user.user_id },
              }"
              >{{ user.user_id }}</router-link
            >
            <router-link
              v-else
              class="link-hover"
              :to="{ name: 'single-user', params: { userId: user.user_id } }"
              >{{ user.partner_id }}</router-link
            >
          </td>
          <td>
            {{ user.risk_score }}
          </td>
          <td>{{ user.status }}</td>
          <td>{{ user.last_activity != null ? user.last_activity : user.last_activity}}</td>
          <td>{{ user.risky_volume ? user.risky_volume : '$0.00' }}</td>
          <td>{{ user.transfer_volume ? user.transfer_volume : '$0.00'}}</td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr class="old">
          <td valign="top" colspan="11" class="dataTables_empty">
            No matching records found
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<style scope>
.link-hover:hover {
  text-decoration: underline;
}
tbody {
  text-align: center;
}
.dataTables_scrollBody {
  min-height: 130px;
}
</style>
<script>
import SpinnerLoader from "@/components/SpinnerLoader.vue";

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
var $ = require("jquery");
import "bootstrap";
export default {
  name: "DataTableUser",
  props: {
    getUsers: Object,
    visible: Boolean,
  },
  components: {
    SpinnerLoader,
  },
  data() {
    return {
      table: null,
      loading: true,
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
  watch: {
    getUsers() {
      if (this.table == null && Object.values(this.getUsers).length)
        setTimeout(() => {
          this.table = $("#datatable_users").DataTable({
            scrollX: true,
            searching: false,
            paging: false,
            info: false,
            ordering: false,
            dom: "Bfrltip",
            buttons: ["csv", "print", "copy"],
          });
        }, 100);
      else if (this.table != null && Object.values(this.getUsers).length) {
        //this.table = null;
        $('#datatable_users').DataTable().clear().destroy();
        setTimeout(() => {
          this.table = $("#datatable_users").DataTable({
            scrollX: true,
            searching: false,
            paging: false,
            info: false,
            ordering: false,
            dom: "Bfrltip",
            buttons: ["csv", "print", "copy"],
          });
        }, 100);
      }
      this.loading = false;
    },
    visible() {
      this.loading = true;
    },
  },
};
</script>
