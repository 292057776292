import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueHead from "vue-head";
import PerfectScrollbar from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import Notifications from "@kyvg/vue3-notification";

import VueApexCharts from "vue3-apexcharts";

//import Select2 from "vue3-select2-component";

const app = createApp(App);
//app.component("Select2", Select2);
router.beforeEach((to, from, next) => {
  store.dispatch("storeRouteName", to.name);
  if (to.name == "kyc-services") {
    setTimeout(() => {
      next();
    }, 200);
  }
  if (to.matched.some((record) => record.meta.requiresAdmin)) {
    store.dispatch('changeTokenToAdmin');
    if (store.getters.loggedIn && store.getters.isAdmin) {
      if (Object.keys(store.getters.getProfile).length == 0)
        store.dispatch("retrieveProfile");

      next();
      return;
    }

    next({ name: "admin" });
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.loggedIn && to.name == "logout") {
      next();
      return;
    }
    store.dispatch('changeTokenToClient');
    if (store.getters.loggedIn) {
      if (Object.keys(store.getters.getProfile).length == 0)
        store.dispatch("retrieveProfile");
      if (to.matched.some((record) => record.meta.requiresAccessKyt)) {
        if (store.getters.accessKyt) {
          next();
          return;
        } else {
          next({ name: "dashboard" });
          return;
        }
      } else if (to.matched.some((record) => record.meta.requiresAccessKyc)) {
        if (store.getters.accessKyc) {
          next();
          return;
        } else {
          next({ name: "dashboard" });
          return;
        }
      } else {
        next();
        return;
      }
    }
    next({ name: "login" });
  } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
    if (!store.getters.loggedIn) {
      next();
    } else if (store.getters.isAdmin && store.getters.loggedIn) next({ name: "admin-dashboard" });
    else if(store.getters.loggedIn) next({ name: "dashboard" });
  } else {
    next();
  }
});


app.use(VueApexCharts).use(Notifications);
app.use(PerfectScrollbar);
app.use(store).use(router).mount("#app");
createApp(App).use(VueHead);
app.config.globalProperties.$dateTimeFormat = function(fullDate) {
  var date = new Date(new Date(fullDate).toISOString().replace('T', ' ')
  .replace('Z', '')); // M-D-YYYY
  var d = date.getDate();
  var m = date.getMonth() + 1;
  var y = date.getYear();
  var h = date.getHours();
  var mi = date.getMinutes();
  var ampm = h >= 12 ? 'PM' : 'AM';
  h = h % 12;
  h = h ? h : 12;
  mi = mi < 10 ? '0'+mi : mi;

  var dateString = m + '/' + d + '/' + y%100 + ' ' + h + ':' + mi + ' ' + ampm;
  return dateString
}
app.config.globalProperties.$getKycApplicantStatusName = function(reviewStatus, reviewAnswer, reviewRejectType) {
  if(reviewStatus == null || reviewStatus == "init" || reviewStatus == "")
    return "Documents requested"
  else if(reviewStatus == "pending")
    return "Pending"
  else if(reviewStatus == "onHold")
    return "Requires action"
  else if(reviewStatus == "prechecked")
    return "Prechecked"
  else if(reviewStatus == "queued")
    return "Processing"
  else if(reviewAnswer == "GREEN")
    return "Approved"
  else if(reviewRejectType == '"FINAL"')
    return "Rejected"
  else
    return "Resubmission requested"
}
app.config.globalProperties.$getKycApplicantStatusColor = function(reviewStatus, reviewAnswer, reviewRejectType) {
  if(reviewStatus == null || reviewStatus == "init" || reviewStatus == "")
    return 'color: #41495b99'
  else if(reviewStatus == "pending")
    return 'color: #eea70d'
  else if(reviewStatus == "onHold")
    return 'color: #9077d8'
  else if(reviewStatus == "prechecked")
    return 'color: #41495b99'
  else if(reviewStatus == "queued")
    return 'color: #41495b99'
  else if(reviewAnswer == "GREEN")
    return 'color: #19bc9b'
  else if(reviewRejectType == '"FINAL"')
    return 'color: #ef5656'
  else
    return 'color: #ef8b53'
}
