<template>
  <div class="col-12 col-md-12 d-flex justify-content-start mt-5">
    <h3 class="px-md-4">User Information</h3>
  </div>
  <div
    v-if="visible"
    class="col-12 col-md-12 d-flex justify-content-start border-bottom-gray"
  >
    <div class="card col-12 col-md-6">
      <div class="card-body border-0" data-select2-id="12">
        <div class="mb-4 main-content-label text-start">Account</div>
        <div class="form-group">
          <div class="row row-sm text-start">
            <div class="col-md-3">
              <p class="form-label text-dark">ID</p>
            </div>
            <div class="col-md-9 d-flex justify-content-start">
              <p class="form-label">
                {{
                  isAdmin
                    ? this.getSingleUser.uid
                    : this.getSingleUser.partner_id
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="form-group" v-if="this.getSingleUser.parentHasKyt">
          <div class="row row-sm text-start">
            <div class="col-md-3">
              <p class="form-label text-dark">Risk Score</p>
            </div>
            <div class="col-md-9 d-flex justify-content-start">
              <p class="form-label">
                {{ this.getSingleUser.risk_score }}
              </p>
            </div>
          </div>
        </div>
        <div class="form-group" v-if="this.getSingleUser.parentHasKyt">
          <div class="row row-sm text-start">
            <div class="col-md-3">
              <p class="form-label text-dark">Highest Severity Alert</p>
            </div>
            <div class="col-md-9 d-flex justify-content-start">
              <p class="form-label">—</p>
            </div>
          </div>
        </div>
        <div class="form-group" v-if="this.getSingleUser.parentHasKyt">
          <div class="row row-sm text-start">
            <div class="col-md-3">
              <p class="form-label text-dark">Status</p>
            </div>
            <div class="col-md-9 d-flex justify-content-start">
              <p class="form-label">
                {{ this.getSingleUser.status }}
              </p>
            </div>
          </div>
        </div>
        <div class="form-group" v-if="this.getSingleUser.parentHasKyt">
          <div class="row row-sm text-start">
            <div class="col-md-3">
              <p class="form-label text-dark">Last Risk Score Change</p>
            </div>
            <div class="col-md-9 d-flex justify-content-start">
              <p class="form-label">—</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card col-12 col-md-6" v-if="this.getSingleUser.parentHasKyt">
      <div class="card-body border-0" data-select2-id="12">
        <div class="mb-4 main-content-label text-start">Activity</div>
        <div class="form-group">
          <div class="row row-sm text-start">
            <div class="col-md-3">
              <p class="form-label text-dark">Transfers</p>
            </div>
            <div class="col-md-9 d-flex justify-content-start">
              <p class="form-label">{{ getTotalSingleUserTransfers }}</p>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row row-sm text-start">
            <div class="col-md-3">
              <p class="form-label text-dark">Transfer Volume</p>
            </div>
            <div class="col-md-9 d-flex justify-content-start">
              <p class="form-label">
                {{
                  this.getSingleUser.transfer_volume
                    ? this.getSingleUser.transfer_volume
                    : '$0.00'
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row row-sm text-start">
            <div class="col-md-3">
              <p class="form-label text-dark">Risky Transfer Volume</p>
            </div>
            <div class="col-md-9 d-flex justify-content-start">
              <p class="form-label">
                {{
                  this.getSingleUser.risky_volume
                    ? this.getSingleUser.risky_volume
                    : '$0.00'
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row row-sm text-start">
            <div class="col-md-3">
              <p class="form-label text-dark">Last Transfer</p>
            </div>
            <div class="col-md-9 d-flex justify-content-start">
              <p class="form-label">
                {{
                  this.getSingleUser.last_activity
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row row-sm text-start">
            <div class="col-md-3">
              <p class="form-label text-dark">Date of First Activity</p>
            </div>
            <div class="col-md-9 d-flex justify-content-start">
              <p class="form-label">—</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <SpinnerLoader
    class="col-12 w-100 h-100"
    style="background: white"
    :start="!visible"
  />
</template>
<style scope>
.border-bottom-gray {
  border-bottom: 1px solid #ccc;
}
</style>
<script>
import SpinnerLoader from "@/components/SpinnerLoader.vue";
export default {
  name: "UserInformationComponent",
  props: {
    loading: Boolean,
  },
  components: {
    SpinnerLoader,
  },
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    getTotalSingleUserTransfers() {
      return this.$store.getters.getTotalSingleUserTransfers;
    },
    getSingleUser() {
      return this.$store.getters.getSingleUser;
    },
  },
  watch: {
    getSingleUser() {
      this.visible = true;
    },
    loading() {
      this.visible = false
    }
  },
};
</script>
