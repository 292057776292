<template>
  <div class="page-single">
    <div class="container">
      <div class="row">
        <div
          class="col-xl-5 col-lg-6 col-md-8 col-sm-8 col-xs-10 card-sigin-main py-4 justify-content-center mx-auto"
        >
          <div class="card-sigin">
            <!-- Demo content-->
            <div class="main-card-signin d-md-flex justify-content-center">
              <div class="w-100">
                <div class="d-flex mb-3 justify-content-center">
                  <a href="index.html"
                    ><img
                      src="../../assets/img/PROTOAML.png"
                      class="sign-favicon ht-40"
                      alt="logo"
                  /></a>
                </div>
                <div class="mb-1">
                  <div class="main-signin-header">
                    <div class="">
                      <h2>Welcome back!</h2>
                      <h4 class="">Reset Your Password</h4>
                      <form action="#" @submit.prevent="reset">
                        <div v-if="serverError.length > 0" class="server-error">
                          <ul
                            style="
                              list-style-type: none;
                              margin: 0px;
                              padding: 0px;
                            "
                            v-for="(item, index) in serverError"
                            :key="index"
                          >
                            <li>{{ item }}</li>
                          </ul>
                        </div>
                        <div class="form-group">
                          <input
                            v-model="token_reset"
                            class="form-control"
                            type="hidden"
                            name="token_reset"
                            id="token_reset"
                            require
                          />
                        </div>
                        <div class="form-group">
                          <label style="text-align: left">Email</label>
                          <input
                            v-model="email"
                            class="form-control"
                            placeholder="Enter your email"
                            type="email"
                            name="email"
                            id="email"
                            require
                          />
                        </div>
                        <div class="form-group">
                          <label>Password</label>
                          <input
                            v-model="password"
                            class="form-control"
                            placeholder="Enter your password"
                            type="password"
                            name="password"
                            id="password"
                            require
                          />
                        </div>
                        <div class="form-group">
                          <label>Confirm Password</label>
                          <input
                            v-model="confirm_password"
                            class="form-control"
                            placeholder="Enter your confirm password"
                            type="password"
                            name="confirm_password"
                            id="confirm_password"
                            require
                          />
                        </div>
                        <button class="btn ripple btn-primary btn-block">
                          <div class="lds-ellipsis" v-if="loading">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                          <span v-if="!loading">Change Password</span>
                        </button>
                      </form>
                      <div
                        class="mt-2 d-flex mx-auto text-center justify-content-center"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.form-group label {
  display: block;
  text-align: start;
}
</style>
<script>
export default {
  name: "ResetPasswordView",
  data() {
    return {
      token_reset: "",
      email: "",
      password: "",
      confirm_password: "",
      serverError: [],
      loading: false,
    };
  },
  mounted() {
    this.email = this.$route.query.email;
    this.token_reset = this.$route.query.token;
    document.body.classList.add("bg-primary");
  },
  methods: {
    reset() {
      this.loading = true;
      this.$store
        .dispatch("resetPassword", {
          token_reset: this.token_reset,
          email: this.email,
          password: this.password,
          confirm_password: this.confirm_password,
        })
        .then((response) => {
          this.loading = false;
          this.$router.push({
            name: "home",
            params: { loginSuccess: response.message },
          });
        })
        .catch((error) => {
          this.loading = false;
          if (Array.isArray(error.response.data.message))
            this.serverError = error.response.data.message;
          else if (error.response.data.message)
            this.serverError = [error.response.data.message];
          else if (typeof error.response.data.errors == "string")
            this.serverError = [error.response.data.errors];
          else this.serverError = Object.values(error.response.data.errors);
          this.password = "";
        });
    },
  },
};
</script>
