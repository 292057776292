import DashboardView from "../views/DashboardView.vue";
import UsersView from "../views/UsersView.vue";
import AlertsView from "../views/AlertsView.vue";
import LoginView from "../views/Auth/LoginView.vue";
import TransfersView from "../views/TransfersView.vue";
import KycView from "../views/KycView.vue";
import KycService from "../views/KycService.vue";
import ProfileView from "../views/ProfileView.vue";
import ApiKeys from "../views/Settings/ApiKeys.vue";
import WebHooks from "../views/Settings/WebHooks.vue";
import SingleUserView from "../views/SingleUserView.vue";

const client = [
  {
    path: "/",
    name: "home",
    component: LoginView,
    props: true,
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: "/kyc-services",
    name: "kyc-services",
    component: KycService,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    props: true,
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: ProfileView,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: DashboardView,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/users",
    name: "users",
    component: UsersView,
    meta: { requiresAuth: true, requiresAccessKyt: true },
  },
  {
    path: "/users/:userId",
    name: "single-user",
    component: SingleUserView,
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: "/alerts",
    name: "alerts",
    component: AlertsView,
    meta: { requiresAuth: true, requiresAccessKyt: true },
    props: true,
  },
  {
    path: "/alerts/:alertId",
    name: "single-alert",
    component: AlertsView,
    meta: { requiresAuth: true, requiresAccessKyt: true },
    props: true,
  },
  {
    path: "/transfers",
    name: "transfers",
    component: TransfersView,
    meta: { requiresAuth: true, requiresAccessKyt: true },
  },
  {
    path: "/kyc",
    name: "kyc",
    component: KycView,
    meta: { requiresAuth: true, requiresAccessKyc: true },
  },
  {
    path: "/settings/api-keys",
    name: "api-keys",
    component: ApiKeys,
    meta: { requiresAuth: true },
  },
  {
    path: "/settings/web-hooks",
    name: "web-hooks",
    component: WebHooks,
    meta: { requiresAuth: true },
  },
];
export default client;
