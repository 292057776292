<template>
  <div class="page-single">
    <div class="container">
      <div class="row">
        <div
          class="col-xl-5 col-lg-6 col-md-8 col-sm-8 col-xs-10 card-sigin-main py-4 justify-content-center mx-auto"
        >
          <div class="card-sigin">
            <!-- Demo content-->
            <div class="main-card-signin d-md-flex justify-content-center">
              <div class="w-100">
                <div class="d-flex mb-3 justify-content-center">
                  <a href="index.html"
                    ><img
                      src="../../assets/img/PROTOAML.png"
                      class="sign-favicon ht-40"
                      alt="logo"
                  /></a>
                </div>
                <div class="mb-1">
                  <div class="main-signin-header">
                    <div class="">
                      <h2>Forgot Password!</h2>
                      <h4>Please Enter Your Email</h4>
                      <form action="#" @submit.prevent="forgot">
                        <div v-if="successMessage" class="success-message">
                          {{ successMessage }}
                        </div>
                        <div v-if="serverError.length > 0" class="server-error">
                          <ul
                            style="
                              list-style-type: none;
                              margin: 0px;
                              padding: 0px;
                            "
                            v-for="(item, index) in serverError"
                            :key="index"
                          >
                            <li>{{ item }}</li>
                          </ul>
                        </div>
                        <div class="form-group">
                          <label style="text-align: left">Email</label>
                          <input
                            :disabled="disabledInput"
                            v-model="email"
                            class="form-control"
                            placeholder="Enter your email"
                            type="email"
                            name="email"
                            id="email"
                            require
                          />
                        </div>
                        <button
                          :disabled="disabledButton"
                          class="btn btn-primary btn-block"
                        >
                          <div class="lds-ellipsis" v-if="loading">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                          <span v-if="!loading">Reset Password</span>
                        </button>
                      </form>
                    </div>
                    <div class="main-signup-footer mg-t-20 text-center">
                      <p>
                        Forget it,
                        <a href="javascript:void(0);"> Send me back</a> to the
                        sign in screen.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.form-group label {
  display: block;
  text-align: start;
}
</style>
<script>
export default {
  name: "ForgotPasswordView",
  data() {
    return {
      email: "",
      serverError: [],
      successMessage: "",
      disabledButton: false,
      disabledInput: false,
      loading: false,
    };
  },
  mounted() {
    document.body.classList.add("bg-primary");
  },
  methods: {
    forgot() {
      this.loading = true;
      this.disabledButton = true;
      this.disabledInput = true;
      this.serverErrorp = [];
      this.successMessage = "";
      this.$store
        .dispatch("sendEmail", {
          email: this.email,
        })
        .then((response) => {
          this.loading = false;
          this.successMessage = response.message[0];
        })
        .catch((error) => {
          this.disabledButton = false;
          this.disabledInput = false;
          this.loading = false;
          if (Array.isArray(error.response.data.message))
            this.serverError = error.response.data.message;
          else if (error.response.data.message)
            this.serverError = [error.response.data.message];
          else if (typeof error.response.data.errors == "string")
            this.serverError = [error.response.data.errors];
          else this.serverError = Object.values(error.response.data.errors);
        });
    },
  },
};
</script>
