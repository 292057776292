const getters = {
  loggedIn(state) {
    return state.token !== null && state.token !== "null";
  },
  isAdmin(state) {
    return state.type == "admins";
  },
  isKycModule(state) {
    return state.protoModule == "KYC";
  },
  isKytModule(state) {
    return state.protoModule == "KYT";
  },
  isBothModule(state) {
    return state.protoModuleDashboardBoth == true;
  },
  isOffMenu(state) {
    return state.offMenu;
  },
  accessKyc(state) {
    return state.type == "admins" || state.roles.includes("Kyc");
  },
  accessKyt(state) {
    return state.type == "admins" || state.roles.includes("Kyt");
  },
  getNavActive(state) {
    return state.navActive;
  },
  getClients(state) {
    return state.clients;
  },
  getClientLevels(state) {
    return state.clientLevels;
  },
  getRoles(state) {
    return state.allRoles;
  },
  getAlerts(state) {
    return state.alerts;
  },
  getTotalAlerts(state) {
    return state.totalAlerts;
  },
  getUsers(state) {
    return state.users;
  },
  getErrorLogs(state) {
    return state.errorLogs;
  },
  getUsersKyc(state) {
    return state.usersKyc;
  },
  getLevels(state) {
    return state.levels;
  },
  getTotalUsers(state) {
    return state.totalUsers;
  },
  getTotalErrorLogs(state) {
    return state.totalErrorLogs;
  },
  getTotalUsersKyc(state) {
    return state.totalUsersKyc;
  },
  getTransfers(state) {
    return state.transfers;
  },
  getTotalTransfers(state) {
    return state.totalTransfers;
  },
  getProfile(state) {
    return state.profile;
  },
  getSingleUser(state) {
    return state.singleUser;
  },
  getSingleUserKycDetails(state) {
    return state.singleUserKycDetails;
  },
  getSingleErrorLog(state) {
    return state.singleErrorLog;
  },
  getSingleUserAlerts(state) {
    return state.singleUserAlerts;
  },
  getSingleUserTransfers(state) {
    return state.singleUserTransfers;
  },
  getSingleUserAddresses(state) {
    return state.singleUserAddresses;
  },
  getTotalSingleUserAddresses(state) {
    return state.totalSingleUserAddresses;
  },
  getTotalSingleUserAlerts(state) {
    return state.totalSingleUserAlerts;
  },
  getTotalSingleUserTransfers(state) {
    return state.totalSingleUserTransfers;
  },
  getApiKeys(state) {
    return state.apiKeys;
  },
  getWebHooks(state) {
    return state.webHooks;
  },
  getClientsWebHooks(state) {
    return state.clientWebHooks;
  },
  getTransfersFilter(state) {
    return state.transfersFilter;
  },
  getAlertsFilter(state) {
    return state.alertsFilter;
  },
  getClientUsersFilter(state) {
    return state.clientUsersFilter;
  },
  getAddressesFilter(state) {
    return state.addressesFilter;
  },
  getAlertsOverview(state) {
    return state.alertsOverview;
  },
  getUserOverView(state) {
    return state.userOverview;
  },
  getKycApplicantOverView(state) {
    return state.kycApplicantOverview;
  },
  getLastestAlerts(state) {
    return state.latestAlerts;
  },
  getClientsOverview(state) {
    return state.clientsOverview;
  },
  getTransferVolumeperCategory(state) {
    return state.transferVolumePerCategory;
  },
  getSingleAlert(state) {
    return state.singleAlert;
  },
  getAllAdmin(state) {
    return state.admins;
  },
  getAllAudit(state) {
    return state.audits;
  },
  getAllComments(state) {
    return state.singleUserComments;
  },
  getSpinnerStatus(state) {
    return state.spinnerStatus;
  },
  getRouteName(state) {
    return state.routeName;
  },
  getSelectedClientFilter(state) {
    return state.selectedClientFilter;
  },
  isImpresonate(state) {
    return state.isClientImpresonate;
  },
};
export default getters;
