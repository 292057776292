<template>
    <div class="row row-sm text-start mt-5">
      <AddWebHook @refresh="refreshTable" />
      <EditWebHook :webHook="current_webHook" @refresh="refreshTable" />
      <div class="col-lg-12">
        <div class="py-2 col-12 row">
          <div class="col-12 col-md-6">
            <div class="py-2 d-flex align-items-center">
              <h2 class="main-content-label mb-1">Web Hooks</h2>
            </div>
          </div>
          <div
            class="col-12 col-md-6 d-flex justify-content-end align-items-center"
          >
            <button
              type="button"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#addWebHookModal"
            >
              Add WebHook
            </button>
          </div>
        </div>
        <div class="card custom-card overflow-hidden">
          <div class="card-body">
            <DataTableWebHook
              :getWebHooks="this.getWebHooks"
              :refreshTableNow="this.refreshTableVar"
              @currentWebHook="setCurrentWebHook"
            />
          </div>
        </div>
      </div>
    </div>
  </template>
  <style scope>
  .custom-switch-indicator {
    background: #ccc !important;
  }
  .custom-switch-input:checked ~ .custom-switch-indicator {
    background: var(--primary-bg-color) !important;
  }
  .access {
    width: 130px;
    margin: 5px;
    display: inline-block;
    padding: 6px 0px;
    background-color: rgb(255, 236, 224);
    color: rgb(194, 78, 0);
  }
  </style>
  <script>
  import DataTableWebHook from "@/components/WebHooks/DataTableWebHook.vue";
  import AddWebHook from "@/components/WebHooks/AddWebHook.vue";
  import EditWebHook from "@/components/WebHooks/EditWebHook.vue";
  export default {
    name: "WebHooks",
    components: {
      DataTableWebHook,
      AddWebHook,
      EditWebHook,
    },
    data() {
      return {
        current_webHook: {},
        refreshTableVar: false,
      };
    },
    created() {
      this.$store.dispatch("changeNavActive", "web-hooks");
      document.title = "ProtoAnalysis";
      this.$store.dispatch("retrieveWebHooks");
    },
    computed: {
      getWebHooks() {
        return this.$store.getters.getWebHooks;
      },
    },
    methods: {
      refreshTable() {
        this.refreshTableVar = !this.refreshTableVar;
        this.$store.dispatch("retrieveWebHooks");
      },
      setCurrentWebHook(value) {
        this.current_webHook = value;
      },
      changeActive(id) {
        this.$store
          .dispatch("changeActive", {
            id: id,
          })
          .then((response) => {
            this.$notify({ type: "success", text: response.message[0] });
          })
          .catch((error) => {
            var serverError =
              error.response.data.message == null
                ? [error.response.data.errors]
                : [error.response.data.message];
            this.$notify({ type: "wronge", text: serverError });
          });
      },
    },
  };
  </script>
  