<template>
  <div class="row row-sm text-start">
    <div class="col-lg-12">
      <div class="py-2 col-12 row">
        <div class="col-12 justify-content-center mt-2">
          <ol class="breadcrumb">
            <li class="breadcrumb-item tx-15">
              <router-link
                class=""
                :to="{ name: (isAdmin ? 'admin-' : '') + 'error-logs' }"
                >Error logs</router-link
              >
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              <router-link
                class=""
                :to="{
                  name: (isAdmin ? 'admin-' : '') + 'single-error-log',
                  params: { errorId: this.errorId },
                }"
                >{{ errorId }}</router-link
              >
            </li>
          </ol>
        </div>
        <div class="col-12 col-md-6 d-flex justify-content-start">
          <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
          </nav>
        </div>
        <ErrorLogInformationComponent />
      </div>
    </div>
  </div>
</template>
<style scope>
.breadcrumb-item::before {
  color: #000 !important;
}
</style>
<script>
import ErrorLogInformationComponent from "@/components/ErrorLogs/ErrorLogInformationComponent.vue";
export default {
  props: ["errorId"],
  name: "AdminSingleErrorLog",
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
  components: {
    ErrorLogInformationComponent,
  },
  mounted() {
    document.title = "ProtoAML";
    this.$store.dispatch("retrieveSingleErrorLog", {
      singleErrorId: this.errorId,
    });
  },
};
</script>
