<template>
  <div class="col-12 col-md-6 d-flex justify-content-end align-items-center">
    <div>
      <select
        v-model="clientId"
        class="SlectBox form-select"
        style="width: 125px"
      >
        <option value="">All Clients</option>
        <option
          v-for="client in getClients"
          :key="client.id"
          :value="client.id"
        >
          {{ client.name }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClientFilterComponent",
  data() {
    return {
      clientId: "",
    };
  },
  mounted() {
    this.$store.dispatch("retrieveClients");
    this.clientId = this.getSelectedClientFilter;
  },
  computed: {
    getSelectedClientFilter() {
      return this.$store.getters.getSelectedClientFilter;
    },
    getClients() {
      return this.$store.getters.getClients;
    },
  },
  watch: {
    clientId() {
      if (this.clientId != this.getSelectedClientFilter) {
        this.$store.dispatch("changeSelectedClientFilter", this.clientId);
        this.$emit("changeClientId", ['client_id',this.clientId]);
      }
    },
  },
};
</script>
