<template>
  <div class="col-12 row">
    <div class="col-12 col-md-6">
      <div class="pt-2 text-start total_rows">
        <p>{{ getTotalRows }} {{ nameComponent }}</p>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="pt-2 text-end">
        <a
          class="btn btn-paging"
          :class="page <= 1 ? 'disabled' : ''"
          @click="changePage(this.page - 1)"
        >
          <i class="fas fa-angle-left"></i> Previous</a
        >
        <a class="btn btn-paging mx-2 disabled">{{ this.page }}</a>
        <a
          class="btn btn-paging"
          :class="page >= Math.ceil(getTotalRows / limit) ? 'disabled' : ''"
          @click="changePage(this.page + 1)"
          >Next <i class="fas fa-angle-right"></i
        ></a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PaginationComponent",
  props: {
    page: Number,
    limit: Number,
    getTotalRows: Number,
    nameComponent: String,
  },
  methods: {
    changePage(newPage) {
      this.$emit("changePage", ['page',newPage]);
    },
  },
};
</script>
