<template>
  <div
    class="main-header-center ms-4 d-sm-none d-md-none d-lg-block form-group"
  >
    <input
      id="searchInput"
      class="form-control"
      autocomplete="off"
      placeholder="Search by Tx Hash or Output Address or User ID"
      type="search"
      v-model="textSearch"
      @focus="handleFocus()"
      @keyup="handleKeyUp()"
      @blur="handleBlur()"
    />
    <div
      class="ant-select overflow-select"
      id="searchItems"
      style="display: none"
    >
      <div
        v-if="
          Object.values(getClientUsersFilter).length == 0 &&
          Object.values(getTransfersFilter).length == 0 &&
          Object.values(getAlertsFilter).length == 0 &&
          Object.values(getAddressesFilter).length == 0
        "
      >
        <div class="ant-select-item-group mb-2">Users</div>
        <div class="mx-3">
          <div class="ant-select-item-group mb-2">No Results Found</div>
        </div>
      </div>
      <div v-if="Object.values(getClientUsersFilter).length > 0">
        <div class="ant-select-item-group mb-2">Users</div>

        <div
          v-for="clientUser in getClientUsersFilter"
          :key="clientUser.id"
          class="mx-3"
        >
          <router-link
            v-if="isAdmin"
            :to="{
              name: 'admin-single-user',
              params: { userId: clientUser.user_id },
            }"
            ><span
              class="ant-badge-status-dot"
              style="background: rgb(82, 196, 26)"
            ></span>
            <span class="ant-badge-status-text">{{
              clientUser.user_id
            }}</span></router-link
          >
          <router-link
            v-else
            class="link-hover"
            :to="{
              name: 'single-user',
              params: { userId: clientUser.user_id },
            }"
            ><span
              class="ant-badge-status-dot"
              style="background: rgb(82, 196, 26)"
            ></span>
            <span class="ant-badge-status-text">{{
              clientUser.user_id
            }}</span></router-link
          >
        </div>
      </div>
      <div v-if="Object.values(getTransfersFilter).length > 0">
        <div class="ant-select-item-group mb-2">Transfers</div>
        <div class="mx-3">
          <a
            :href="
              (isAdmin ? '/admin' : '') +
              '/transfers?' +
              getTransfersFilter.filter
            "
          >
            <span class="ant-badge-status-text"
              >{{ getTransfersFilter.count }} transfers found with matching tx
              hash</span
            >
          </a>
        </div>
      </div>
      <div v-if="Object.values(getAlertsFilter).length > 0">
        <div class="ant-select-item-group mb-2">Alerts</div>
        <div class="mx-3">
          <a
            :href="
              (isAdmin ? '/admin' : '') + '/alerts?' + getAlertsFilter.filter
            "
          >
            <span class="ant-badge-status-text"
              >{{ getAlertsFilter.count }} alerts found with matching ID</span
            >
          </a>
        </div>
      </div>

      <div v-if="Object.values(getAddressesFilter).length > 0">
        <div class="ant-select-item-group mb-2">Output Address</div>
        <div class="mx-3">
          <a
            :href="
              (isAdmin ? '/admin' : '') +
              '/transfers?' +
              getAddressesFilter.filter
            "
          >
            <span class="ant-badge-status-text"
              >{{ getAddressesFilter.count }} transfers found with output
              address</span
            >
          </a>
        </div>
      </div>
    </div>

    <button class="btn" @click="handleSearchButton()">
      <i class="fas fa-search"></i>
    </button>
  </div>
</template>
<style>
.ant-select {
  position: absolute;
  right: 0;
  left: 0;
  padding: 10px 10px 10px 10px;
  background: #e8e8e8;
  text-align: left;
}
.ant-select-item-group {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  cursor: default;
}
.ant-badge-status-dot {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 6px;
  height: 6px;
  vertical-align: middle;
  border-radius: 50%;
}
.ant-badge-status-text {
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.overflow-select {
  max-height: 150px;
  overflow-y: auto;
}
</style>
<script>
import "jquery/dist/jquery.min.js";
var $ = require("jquery");

export default {
  name: "SearchComponent",
  data() {
    return {
      textSearch: "",
      timer: 0,
      type: "",
    };
  },
  computed: {
    getTransfersFilter() {
      return this.$store.getters.getTransfersFilter;
    },
    getClientUsersFilter() {
      return this.$store.getters.getClientUsersFilter;
    },
    getAlertsFilter() {
      return this.$store.getters.getAlertsFilter;
    },
    getAddressesFilter() {
      return this.$store.getters.getAddressesFilter;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
  watch: {
    textSearch() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        var filters = {
          queryString : this.textSearch
        };
        this.$store.dispatch("search", {
          type: this.isAdmin ? "admins" : "clients",
          filters: filters,
        });
      }, 500);
    },
  },
  methods: {
    handleFocus() {
      if (this.textSearch) $("#searchItems").show("slow");
    },
    handleKeyUp() {
      if (this.textSearch) $("#searchItems").show("slow");
      else $("#searchItems").hide("slow");
    },
    handleBlur() {
      //if (!$("#searchInput").val()) {
      $("#searchItems").hide("slow");
      // }
    },
    handleSearchButton() {
      $("#searchInput").focus();
    },
  },
};
</script>
