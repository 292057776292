<template>
  <div
    class="modal fade bd-example-modal-md"
    id="ShowApplicants"
    tabindex="-1"
    aria-labelledby="mySmallModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Applicants</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <ul v-for="item in this.applicantsArray" :key="item.id">
            <li
              :style="this.$getKycApplicantStatusColor(item.review_status,item.review_answer,item.review_reject_type)
              "
            >
              {{
                item.level_name +
                " : " +
                this.$getKycApplicantStatusName(item.review_status,item.review_answer,item.review_reject_type)
              }}
              <a
                v-if="this.isAdmin"
                :href="
                  'https://cockpit.sumsub.com/checkus?_gl=1*131v61x*_ga*MTAzMDY5MDE1MC4xNjUyMDk3NDE4*_ga_ZF910PGWRL*MTY1MzM5MjgxOS4xOS4wLjE2NTMzOTI4MjAuNTk.#/applicant/' +
                  item.partner_applicant_id +
                  '/basicInfo?clientId=protocol33.io_38560'
                "
                target="_blank"
              >
                <i class="fas fa-external-link-alt"></i>
              </a>
              <div v-if="item.edited" class="toolpop">
                  <p
                    style="margin-bottom: 0px;"
                    @mouseover="defineCopy(item.id)"
                    @mouseout="backToOrigin(item.id)"
                  >
                    <span class="edited-tag" >
                      {{ item.edited ? 'E' : '' }}
                    </span>
                    <span class="toolpoptext" :id="'myToolpop_EditedApplicantModal_'+item.id">
                      this applicant is duplicated  in reference but edited in ProtoAML as per client request
                    </span>
                  </p>
                </div>
              <table class="table" v-if="item.review_answer == 'RED' && item.review_status == 'completed'">
                <tbody>
                  <tr>
                    <th style="font-weight: bold" scope="row">comment</th>
                    <td>{{ item.review_client_comment ? item.review_client_comment  : '—' }}</td>
                  </tr>
                  <tr>
                    <th style="font-weight: bold" scope="row">
                      possible reason for rejection
                    </th>
                    <td>
                      {{
                        item.reject_labels ? item.reject_labels.slice(
                          1,
                          item.reject_labels.length - 1
                        ) : '—'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th style="font-weight: bold" scope="row">reject type</th>
                    <td>{{ item.review_reject_type ? item.review_reject_type : '—' }}</td>
                  </tr>
                </tbody>
              </table>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
table tbody tr th,
td {
  border: red 1px solid;
}
.edited-tag {
  background-color: yellow;
  padding: 2px;
  margin: 0px 4px;
  font-size: 12px;
  font-weight: bold;
  color: black;
}
.toolpop {
  position: relative;
  display: inline-block;
}

.toolpop .toolpoptext {
  visibility: hidden;
  width: 300px;
  white-space: normal;
  height: 50px;
  background-color: #555 !important;
  color: #fff !important;
  text-align: center !important;
  font-size: 14px;
  border-radius: 6px !important;
  padding: 5px !important;
  position: absolute !important;
  z-index: 1 !important;
  bottom: -80% !important;
  left: 120px !important;
  margin-left: -95px !important;
  opacity: 0;
  transition: opacity 0.3s;
}
.toolpop .toolpoptext::after {
  content: "";
  position: absolute;
  top: 43% !important;
  left: -5px !important;
  margin-left: -5px !important;
  border-width: 5px !important;
  border-style: solid !important;
  border-color: transparent #555 transparent transparent !important;
}
</style>
<script>
export default {
  name: "ApplicantsUser",
  props: {
    applicants: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      applicantsArray: [],
    };
  },
  watch: {
    applicants() {
      this.applicantsArray = this.applicants;
    },
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
  methods: {
    defineCopy(ind) {
      var toolpop = document.getElementById("myToolpop_EditedApplicantModal_"+ind);
      toolpop.style.visibility = "visible";
      toolpop.style.opacity = 1;
    },
    backToOrigin(ind) {
      var toolpop = document.getElementById("myToolpop_EditedApplicantModal_"+ind);
      toolpop.style.visibility = "hidden";
      toolpop.style.opacity = 0;
    },
  }
};
</script>
