<template>
  <div>
    <ApplicantsUser :applicants="current_applicants" />
    <LinkModal :link="current_link" />
    <table id="datatable_userskyc" class="table table-bordered text-nowrap key-buttons border-bottom"
      style="text-align: center">
      <thead>
        <tr>
          <th class="border-bottom-0" v-if="isAdmin">User Full Name</th>
          <th class="border-bottom-0">Proto User ID</th>
          <th class="border-bottom-0" v-if="isAdmin">Sumsub User ID</th>
          <th class="border-bottom-0">Kyc Status</th>
          <th class="border-bottom-0">Tier Level</th>
          <th class="border-bottom-0" v-if="this.isAdmin">Kyc Risk Score</th>
          <th class="border-bottom-0">Latest Update</th>
          <!--
          <th class="border-bottom-0">Create Link</th>
-->
        </tr>
      </thead>
      <tbody v-if="loading">
        <tr class="old">
          <td valign="top" colspan="6" class="dataTables_empty">
            <SpinnerLoader :start="loading" />
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="Object.values(getUsersKyc).length">
        <tr v-for="user in getUsersKyc" :key="user.id">
          <td v-if="isAdmin">
            <span v-if="Object.values(user.kyc_applicants).length"
              :style="this.$getKycApplicantStatusColor(user.kyc_applicants[Object.values(user.kyc_applicants).length - 1].review_status, user.kyc_applicants[Object.values(user.kyc_applicants).length - 1].review_answer, user.kyc_applicants[Object.values(user.kyc_applicants).length - 1].review_reject_type)">
              {{ user.kyc_applicants[Object.values(user.kyc_applicants).length - 1].firstName ?
                (user.kyc_applicants[Object.values(user.kyc_applicants).length - 1].firstName + ' ' +
                  user.kyc_applicants[Object.values(user.kyc_applicants).length - 1].lastName) : "—" }}
            </span>
            <span v-else>NOT APPLICABLE</span>
          </td>
          <!-- User ID -->
          <td>
            <router-link v-if="isAdmin" class="link-hover" :to="{
              name: 'admin-single-user',
              params: { userId: user.user_id },
            }">{{ user.user_id }}</router-link>
            <router-link v-else class="link-hover" :to="{ name: 'single-user', params: { userId: user.user_id } }">{{
              user.partner_id
            }}</router-link>

          </td>
          <td v-if="isAdmin">
            <span v-if="Object.values(user.kyc_applicants).length"
              :style="this.$getKycApplicantStatusColor(user.kyc_applicants[Object.values(user.kyc_applicants).length - 1].review_status, user.kyc_applicants[Object.values(user.kyc_applicants).length - 1].review_answer, user.kyc_applicants[Object.values(user.kyc_applicants).length - 1].review_reject_type)">
              {{ user.kyc_applicants[Object.values(user.kyc_applicants).length - 1].client_user_name }}
            </span>
            <span v-else>NOT APPLICABLE</span>
          </td>
          <!-- Kyc Status -->
          <td v-if="Object.values(user.kyc_applicants).length">
            <span v-if="Object.values(user.kyc_applicants).length == 1"
              :style="this.$getKycApplicantStatusColor(user.kyc_applicants[0].review_status, user.kyc_applicants[0].review_answer, user.kyc_applicants[0].review_reject_type)">
              {{
                this.$getKycApplicantStatusName(user.kyc_applicants[0].review_status, user.kyc_applicants[0].review_answer, user.kyc_applicants[0].review_reject_type)
              }}
              <a href="#" class="mr-3" style="color: red; margin-left: 2px; margin-right: 2px"
                v-if="user.kyc_applicants[0].review_answer == 'RED' && user.kyc_applicants[0].review_status == 'completed'"
                data-bs-toggle="modal" @click="currentClient(user.kyc_applicants)" data-bs-target="#ShowApplicants">
                <i class="fa fa-info-circle" style="font-size: 14px"></i>
              </a>
              <a v-if="this.isAdmin" :href="'https://cockpit.sumsub.com/checkus#/applicant/' +
                user.kyc_applicants[0].partner_applicant_id +
                '/basicInfo?clientId=protocol33.io_38560'
                " target="_blank">
                <i class="fas fa-external-link-alt"></i>
              </a>
              <div v-if="user.kyc_applicants[0].edited" class="toolpop">
                <p style="margin-bottom: 0px;" @mouseover="defineCopy(user.kyc_applicants[0].id)"
                  @mouseout="backToOrigin(user.kyc_applicants[0].id)">
                  <span class="edited-tag">
                    {{ user.kyc_applicants[0].edited ? 'E' : '' }}
                  </span>
                  <span class="toolpoptext" :id="'myToolpop_EditedApplicant_' + user.kyc_applicants[0].id">
                    this applicant is duplicated in reference but edited in ProtoAML as per client request
                  </span>
                </p>
              </div>
            </span>
            <span v-if="Object.values(user.kyc_applicants).length > 1">
              <a :style="'text-decoration: underline;'
                + this.$getKycApplicantStatusColor(user.kyc_applicants[Object.values(user.kyc_applicants).length - 1].review_status, user.kyc_applicants[Object.values(user.kyc_applicants).length - 1].review_answer, user.kyc_applicants[Object.values(user.kyc_applicants).length - 1].review_reject_type)"
                href="#" class="mr-3" data-bs-toggle="modal" @click="currentClient(user.kyc_applicants)"
                data-bs-target="#ShowApplicants">
                {{
                  this.$getKycApplicantStatusName(user.kyc_applicants[Object.values(user.kyc_applicants).length -
                    1].review_status, user.kyc_applicants[Object.values(user.kyc_applicants).length -
                      1].review_answer, user.kyc_applicants[Object.values(user.kyc_applicants).length - 1].review_reject_type)
                }}</a>
              <div v-if="user.kyc_applicants[Object.values(user.kyc_applicants).length - 1].edited" class="toolpop">
                <p style="margin-bottom: 0px;"
                  @mouseover="defineCopy(user.kyc_applicants[Object.values(user.kyc_applicants).length - 1].id)"
                  @mouseout="backToOrigin(user.kyc_applicants[Object.values(user.kyc_applicants).length - 1].id)">
                  <span class="edited-tag">
                    {{ user.kyc_applicants[Object.values(user.kyc_applicants).length - 1].edited ? 'E' : '' }}
                  </span>
                  <span class="toolpoptext"
                    :id="'myToolpop_EditedApplicant_' + user.kyc_applicants[Object.values(user.kyc_applicants).length - 1].id">
                    this applicant is duplicated in reference but edited in ProtoAML as per client request
                  </span>
                </p>
              </div>
            </span>
          </td>
          <td v-else>NOT APPLICABLE</td>
          <!-- Tier Level -->
          <td v-if="Object.values(user.kyc_applicants).length">
            <span
              :style="this.$getKycApplicantStatusColor(user.kyc_applicants[Object.values(user.kyc_applicants).length - 1].review_status, user.kyc_applicants[Object.values(user.kyc_applicants).length - 1].review_answer, user.kyc_applicants[Object.values(user.kyc_applicants).length - 1].review_reject_type)">
              {{
                user.kyc_applicants[
                  Object.values(user.kyc_applicants).length - 1
                ].level_name
              }}
            </span>
          </td>
          <td v-else>NOT APPLICABLE</td>
          <!-- Kyc Risk Score -->
          <td v-if="this.isAdmin">
            <div v-if="isAdmin" class="dropdown dropend">
              <button aria-expanded="false" aria-haspopup="true" class="btn ripple btn-info btn-high"
                data-bs-toggle="dropdown" id="droprightMenuButton" type="button" v-if="user.kyc_risk_score == 'HIGH'">
                HIGH<i class="fas fa-caret-right ms-1"></i>
              </button>
              <button aria-expanded="false" aria-haspopup="true" class="btn ripple btn-info btn-medium"
                data-bs-toggle="dropdown" id="droprightMenuButton" type="button"
                v-else-if="user.kyc_risk_score == 'MEDIUM'">
                MEDIUM<i class="fas fa-caret-right ms-1"></i>
              </button>
              <button aria-expanded="false" aria-haspopup="true" class="btn ripple btn-info btn-low"
                data-bs-toggle="dropdown" id="droprightMenuButton" type="button" v-else-if="user.kyc_risk_score == 'LOW'">
                LOW<i class="fas fa-caret-right ms-1"></i>
              </button>
              <button aria-expanded="false" aria-haspopup="true" class="btn ripple btn-info btn-no-risk"
                data-bs-toggle="dropdown" id="droprightMenuButton" type="button" v-else>
                No Risk<i class="fas fa-caret-right ms-1"></i>
              </button>
              <div aria-labelledby="droprightMenuButton" class="dropdown-menu tx-13" style="">
                <a class="dropdown-item own-dropdown" href="javascript:void(0);"
                  @click="changeRiskScore(user.id, 'HIGH')">HIGH</a>
                <a class="dropdown-item own-dropdown" href="javascript:void(0);"
                  @click="changeRiskScore(user.id, 'MEDIUM')">MEDIUM</a>
                <a class="dropdown-item own-dropdown" href="javascript:void(0);"
                  @click="changeRiskScore(user.id, 'LOW')">LOW</a>
              </div>
            </div>
          </td>
          <!-- Latest Update -->
          <td v-if="Object.values(user.kyc_applicants).length">
            {{
              this.$dateTimeFormat(user.kyc_applicants[
                Object.values(user.kyc_applicants).length - 1
              ].updated_at)
            }}
          </td>
          <td v-else>NOT APPLICABLE</td>
          <!-- Create Link -->
          <!--
          <td>
            <div
              v-if="
                getLevels &&
                Object.values(getLevels).filter(
                  (el) => el.user_id == user.user_id_rel
                ).length >
                  user.kyc_applicants.filter(
                    (e) =>
                      e.review_answer == 'GREEN' ||
                      (e.review_reject_type != null &&
                        e.review_reject_type.includes('FINAL'))
                  ).length
              "
              class="dropdown dropend"
            >
              <button
                aria-expanded="false"
                aria-haspopup="true"
                class="btn ripple btn-info btn-approve"
                data-bs-toggle="dropdown"
                :disabled="eventGenerate"
                :id="'droprightMenuButton_' + user.id"
                type="button"
              >
                <span>Create<i class="fas fa-caret-right ms-1"></i></span>
              </button>
              <div
                aria-labelledby="droprightMenuButton"
                class="dropdown-menu tx-13"
              >
                <a
                  v-for="level in Object.values(getLevels).filter(
                    (el) =>
                      el.user_id == user.user_id_rel &&
                      user.kyc_applicants
                        .filter(
                          (e) =>
                            e.review_answer == 'GREEN' ||
                            (e.review_reject_type != null &&
                              e.review_reject_type.includes('FINAL'))
                        )
                        .some((k) => k.level_name === el.name) == false
                  )"
                  :key="level.id"
                  class="dropdown-item own-dropdown"
                  href="javascript:void(0)"
                  @click="generateLink(user.id, level.name)"
                  >{{ level.name }}</a
                >
              </div>
              <button
                type="button"
                id="showLinkMod"
                data-bs-toggle="modal"
                data-bs-target="#ShowLink"
                style="visibility: hidden"
              ></button>
            </div>
          </td>
-->
        </tr>
      </tbody>
      <tbody v-else>
        <tr class="old">
          <td valign="top" colspan="11" class="dataTables_empty">
            No matching records found
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<style scope>
.link-hover:hover {
  text-decoration: underline;
}

.dataTables_scrollBody {
  min-height: 130px;
}

tbody {
  text-align: center;
}

.btn-info.disabled,
.btn-info:disabled {
  background: #569618;
  border-color: #569618;
  opacity: 1;
}

.dropdown-menu .own-dropdown {
  border-bottom: #cccccb 1px solid;
  color: #8b8b8b !important;
}

.dropdown-menu {
  margin: unset !important;
}

.btn-high,
.btn-medium,
.btn-low,
.btn-no-risk {
  width: 125px !important;
}

.btn-no-risk {
  color: #929292;
  background: transparent;
  border-color: #929292;
  display: inline-block;
}

.btn-high {
  color: tomato;
  background: transparent;
  border-color: tomato;
  display: inline-block;
}

.btn-medium {
  color: orange;
  background: transparent;
  border-color: orange;
  display: inline-block;
}

.btn-low {
  color: #569618;
  background: transparent;
  border-color: #569618;
  display: inline-block;
  width: 105px;
}

.btn-no-risk:hover,
.btn-no-risk:active,
.btn-no-risk:focus {
  color: gray !important;
  background: #d8d8d8 !important;
  border-color: gray !important;
  box-shadow: 0 0 0 0.2rem white !important;
}

.btn-low:hover,
.btn-low:active,
.btn-low:focus {
  color: #569618 !important;
  background: #c0ff84 !important;
  border-color: #569618 !important;
  box-shadow: 0 0 0 0.2rem white !important;
}

.btn-medium:hover,
.btn-medium:active,
.btn-medium:focus {
  color: orange !important;
  background: #ffebc6 !important;
  border-color: orange !important;
  box-shadow: 0 0 0 0.2rem white !important;
}

.btn-high:hover,
.btn-high:active,
.btn-high:focus {
  color: tomato !important;
  background: #ffd1c8 !important;
  border-color: tomato !important;
  box-shadow: 0 0 0 0.2rem white !important;
}

.edited-tag {
  background-color: yellow;
  padding: 2px;
  margin: 0px 4px;
  font-size: 12px;
  font-weight: bold;
  color: black;
}

.toolpop {
  position: relative;
  display: inline-block;
}

.toolpop .toolpoptext {
  visibility: hidden;
  width: 300px;
  white-space: normal;
  height: 50px;
  background-color: #555 !important;
  color: #fff !important;
  text-align: center !important;
  font-size: 14px;
  border-radius: 6px !important;
  padding: 5px !important;
  position: absolute !important;
  z-index: 1 !important;
  bottom: -80% !important;
  left: 120px !important;
  margin-left: -95px !important;
  opacity: 0;
  transition: opacity 0.3s;
}

.toolpop .toolpoptext::after {
  content: "";
  position: absolute;
  top: 43% !important;
  left: -5px !important;
  margin-left: -5px !important;
  border-width: 5px !important;
  border-style: solid !important;
  border-color: transparent #555 transparent transparent !important;
}
</style>
<script>
import SpinnerLoader from "@/components/SpinnerLoader.vue";
import ApplicantsUser from "@/components/UsersKyc/ApplicantsUser.vue";
import LinkModal from "@/components/UsersKyc/LinkModal.vue";

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
var $ = require("jquery");
import "bootstrap";
export default {
  name: "DataTableUser",
  props: {
    getUsersKyc: Object,
    visible: Boolean,
    client_id: Number,
  },
  components: {
    SpinnerLoader,
    ApplicantsUser,
    LinkModal,
  },
  data() {
    return {
      table: null,
      loading: true,
      loading_button: false,
      current_applicants: [],
      current_link: "",
      eventGenerate: false,
      user_id_button: "",
    };
  },
  mounted() {
    this.$store.dispatch("retrieveLevels");
  },
  computed: {
    getLevels() {
      return this.$store.getters.getLevels;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    accessKyt() {
      return this.$store.getters.accessKyt;
    },
  },
  watch: {
    getUsersKyc() {
      if (this.table == null) {
        setTimeout(() => {
          this.table = $("#datatable_userskyc").DataTable({
            scrollX: true,
            searching: false,
            paging: false,
            ordering: false,
            info: false,
            dom: "Bfrltip",
            buttons: ["csv", "print", "copy"],
          });
        }, 100);
      }
      else if (this.table != null) {
        //this.table = null;
        $('#datatable_userskyc').DataTable().clear().destroy();
        setTimeout(() => {
          this.table = $("#datatable_userskyc").DataTable({
            scrollX: true,
            searching: false,
            paging: false,
            ordering: false,
            info: false,
            dom: "Bfrltip",
            buttons: ["csv", "print", "copy"],
          });
        }, 100);
      }
      this.loading = false;
    },
    visible() {
      this.loading = true;
    },
    current_link() {
      document.getElementById(
        "droprightMenuButton_" + this.user_id_button
      ).innerHTML =
        "<span>Create<i class='fas fa-caret-right ms-1'></i></span>";
      this.eventGenerate = false;
      if (this.current_link.indexOf("http") != -1)
        this.$emit("generatedLink", true);
    },
  },
  methods: {
    currentClient(appliacnts) {
      this.current_applicants = appliacnts;
    },
    generateLink(user_Id, level_Name) {
      this.user_id_button = user_Id;
      document.getElementById(
        "droprightMenuButton_" + this.user_id_button
      ).innerHTML =
        "<div class='lds-ellipsis'><div></div><div></div><div></div><div></div></div>";
      this.eventGenerate = true;
      this.current_link = "";
      this.$store
        .dispatch("generateLinkKyc", {
          userId: user_Id,
          levelName: level_Name,
        })
        .then((response) => {
          var link = response.body.kyc_link;
          var current_url = window.location.href;
          var ind = current_url.indexOf("/", 8);
          var dom = current_url.slice(0, ind);
          this.current_link = link.replace("baseUrl", dom);
          $("#showLinkMod").click();
        })
        .catch((error) => {
          this.current_link = error.response.data.message[0];
          $("#showLinkMod").click();
        });
    },
    changeRiskScore(id, value) {
      if (this.isAdmin) {
        this.loading = true;
        this.$store
          .dispatch("changeKycRiskUser", {
            id: id,
            new_kyc_risk: value,
          })
          .then((response) => {
            this.$notify({ type: "success", text: response.data.message[0] });
            this.$emit("refresh", true);
          })
          .catch((error) => {
            this.$notify({
              type: "error",
              text:
                error.response.data.message == null
                  ? "Fail"
                  : error.response.data.message[0],
            });
          });
      }
    },
    defineCopy(ind) {
      var toolpop = document.getElementById("myToolpop_EditedApplicant_" + ind);
      toolpop.style.visibility = "visible";
      toolpop.style.opacity = 1;
    },
    backToOrigin(ind) {
      var toolpop = document.getElementById("myToolpop_EditedApplicant_" + ind);
      toolpop.style.visibility = "hidden";
      toolpop.style.opacity = 0;
    },
  },
};
</script>
