<template>
  <!-- breadcrumb -->
  <div class="breadcrumb-header justify-content-between">
    <div class="left-content">
      <h2 class="main-content-label mb-1">PROFILE</h2>
    </div>
  </div>
  <!-- /breadcrumb -->

  <div class="row">
    <div class="col-lg-12 col-md-12">
      <div class="card custom-card">
        <div class="card-body d-md-flex">
          <div class="pos-relative">
            <!--
            <span class="profile-image"
              ><img class="br-5" alt="" src="../assets/img/faces/profile.jpg"
            /></span>
            -->
            <p v-if="getProfile.name" class="square-img">
              {{ getProfile.name.substring(0, 2) }}
            </p>
          </div>

          <div class="prof-details">
            <h4 class="font-weight-semibold ms-md-4 ms-0 mb-1 pb-0">
              {{ getProfile.name }}
            </h4>

            <p class="text-muted ms-md-4 ms-0 mb-2">
              <span><i class="fa fa-envelope me-2"></i></span
              ><span class="font-weight-semibold me-2">Email:</span
              ><span>{{ getProfile.email }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Row -->
  <div class="row row-sm">
    <div class="col-lg-12 col-md-12">
      <div class="custom-card main-content-body-profile">
        <div class="tab-content">
          <div class="main-content-body tab-pane active" id="settings">
            <div class="card">
              <div class="card-body border-0" data-select2-id="12">
                <div class="mb-4 main-content-label text-start">Account</div>
                <div class="form-group">
                  <div class="row row-sm text-start px-md-5">
                    <div class="col-md-3">
                      <p class="form-label">Name</p>
                    </div>
                    <div class="col-md-9 d-flex justify-content-start">
                      <p class="form-label">{{ getProfile.name }}</p>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row row-sm text-start px-md-5">
                    <div class="col-md-3">
                      <p class="form-label">Email</p>
                    </div>
                    <div class="col-md-9 d-flex justify-content-start">
                      <p class="form-label">{{ getProfile.email }}</p>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row row-sm text-start px-md-5">
                    <div class="col-md-3">
                      <p class="form-label">Prefix</p>
                    </div>
                    <div class="col-md-9 d-flex justify-content-start">
                      <p class="form-label">
                        {{ getProfile.prefix ? getProfile.prefix : "—" }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row row-sm text-start px-md-5">
                    <div class="col-md-3">
                      <p class="form-label">Withdraw Alert Threshold (USD)</p>
                    </div>
                    <div class="col-md-9 d-flex justify-content-start">
                      <p class="form-label">
                        {{
                          getProfile.withdraw_alert_threshold
                            ? getProfile.withdraw_alert_threshold
                            : "—"
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row row-sm text-start px-md-5">
                    <div class="col-md-3">
                      <p class="form-label">Deposit Alert Threshold (USD)</p>
                    </div>
                    <div class="col-md-9 d-flex justify-content-start">
                      <p class="form-label">
                        {{
                          getProfile.deposit_alert_threshold
                            ? getProfile.deposit_alert_threshold
                            : "—"
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row row-sm text-start px-md-5">
                    <div class="col-md-3">
                      <p class="form-label">Alert Types</p>
                    </div>
                    <div class="col-md-3 row d-flex justify-content-start">
                      <div
                        v-for="(x, index) in getProfile.alert_types"
                        :key="index"
                        class="mx-2 px-0"
                      >
                        <p :class="'severity ' + 'severity_' + x.toLowerCase()">
                          {{ x }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-body border-0" data-select2-id="12">
                <form
                  class="form-horizontal"
                  data-select2-id="11"
                  action="#"
                  @submit.prevent="updatePassword"
                >
                  <div v-if="successMessage" class="success-message col-6">
                    {{ successMessage }}
                  </div>
                  <div v-if="serverError.length > 0" class="server-error col-6">
                    <ul
                      style="list-style-type: none; margin: 0px; padding: 0px"
                      v-for="(item, index) in serverError"
                      :key="index"
                    >
                      <li>{{ item }}</li>
                    </ul>
                  </div>
                  <div class="mb-4 main-content-label text-start">
                    Change Password
                  </div>
                  <div class="form-group">
                    <div class="row row-sm text-start px-md-5">
                      <div class="col-md-3">
                        <label class="form-label">Current Password </label>
                      </div>
                      <div class="col-md-3 d-flex align-items-center">
                        <input
                          id="pass1"
                          type="password"
                          class="form-control"
                          style="margin-right: 6px"
                          v-model="current_password"
                          name="current_password"
                          placeholder="Enter Current Password"
                          required
                        />
                        <i
                          @click="showPass(1)"
                          class="fa fa-eye eye-pos eye1"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row row-sm text-start px-md-5">
                      <div class="col-md-3">
                        <label class="form-label">New Password </label>
                      </div>
                      <div class="col-md-3 d-flex align-items-center">
                        <input
                          id="pass2"
                          v-model="new_password"
                          type="password"
                          name="new_password"
                          style="margin-right: 6px"
                          class="form-control"
                          placeholder="Enter New Password"
                          required
                        />
                        <i
                          @click="showPass(2)"
                          class="fa fa-eye eye-pos eye2"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row row-sm text-start px-md-5">
                      <div class="col-md-3">
                        <label class="form-label">Confirm Password </label>
                      </div>
                      <div class="col-md-3 d-flex align-items-center">
                        <input
                          id="pass3"
                          v-model="new_password_confirmation"
                          name="new_password_confirmation"
                          type="password"
                          style="margin-right: 6px"
                          class="form-control"
                          placeholder="Enter Confirm Password"
                          required
                        />
                        <i
                          @click="showPass(3)"
                          class="fa fa-eye eye-pos eye3"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row row-sm">
                      <div class="col-md-6 text-end px-5">
                        <button class="btn btn-primary" type="submit">
                          <div
                            class="lds-ellipsis"
                            style="display: flex"
                            v-if="loading"
                          >
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                          <span v-if="!loading">Change Password</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- row closed -->
</template>
<style scoped>
.square-img {
  background: #ccc;
  padding: 20px 36px;
  color: #fff;
  font-size: 60px;
}
.severity {
  width: 100px;
  text-align: center;
  padding: 8px 15px;
}
.severity_sever {
  background-color: rgb(255, 240, 240);
  color: rgb(162, 9, 7);
}
.severity_high {
  background-color: rgb(255, 236, 224);
  color: rgb(194, 78, 0);
}
.severity_medium {
  background-color: rgb(255, 249, 219);
  color: rgb(153, 107, 15);
}
.severity_low {
  background-color: rgb(220 227 203);
  color: rgb(156 159 12);
}
.severity_other {
  background-color: rgb(199 205 184);
  color: rgb(108 110 6);
}
.eye-pos {
  position: absolute;
  right: 30px;
}
</style>
<script>
var $ = require("jquery");
export default {
  name: "ProfileView",
  data() {
    return {
      current_password: "",
      new_password: "",
      new_password_confirmation: "",
      serverError: [],
      successMessage: "",
      loading: false,
    };
  },
  created() {
    this.$store.dispatch("changeNavActive", "profile");
    document.title = "ProtoAnalysis";
    this.$store.dispatch("retrieveProfile");
  },
  computed: {
    getProfile() {
      return this.$store.getters.getProfile;
    },
  },

  methods: {
    showPass(num) {
      var el = document.getElementById("pass" + num);
      el.type = el.type == "text" ? "password" : "text";
      $('.eye'+num).css('text-decoration',el.type == "text" ? 'line-through':'unset');
    },
    updatePassword() {
      this.loading = true;
      this.$store
        .dispatch("updatePassword", {
          current_password: this.current_password,
          new_password: this.new_password,
          new_password_confirmation: this.new_password_confirmation,
        })
        .then((response) => {
          this.loading = false;
          this.successMessage = response.message[0];
          this.current_password = "";
          this.new_password = "";
          this.new_password_confirmation = "";
        })
        .catch((error) => {
          this.loading = false;
          if (Array.isArray(error.response.data.message))
            this.serverError = error.response.data.message;
          else if (error.response.data.message)
            this.serverError = [error.response.data.message];
          else if (typeof error.response.data.errors == "string")
            this.serverError = [error.response.data.errors];
          else this.serverError = Object.values(error.response.data.errors);
          this.current_password = "";
          this.new_password = "";
          this.new_password_confirmation = "";
        });
    },
  },
};
</script>
