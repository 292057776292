<template>

  <div class="row row-sm text-start">
    <div :class="isAdmin ? 'col-lg-9' : 'col-lg-12'">
      <div class="py-2 col-12 row">
        <div class="col-12 justify-content-center mt-2">
          <ol class="breadcrumb">
            <li class="breadcrumb-item tx-15">
              <router-link v-if="isAdmin" class="" :to="{ name: 'admin-users' }"
                >Users</router-link
              >
              <router-link v-else class="" :to="{ name: 'users' }"
                >Users</router-link
              >
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              <router-link
                v-if="isAdmin"
                class=""
                :to="{ name: 'admin-single-user' }"
                >{{ userId }}</router-link
              >
              <router-link v-else class="" :to="{ name: 'single-user' }">{{
                isAdmin ? this.getSingleUser.uid : this.getSingleUser.partner_id
              }}</router-link>
            </li>
          </ol>
        </div>
        <div class="col-12 col-md-6 d-flex justify-content-start">
          <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <h2 class="main-content-label mb-1">
                {{
                  isAdmin
                    ? this.getSingleUser.uid
                    : this.getSingleUser.partner_id
                }}
              </h2>
            </div>
          </nav>
        </div>
        <UserInformationComponent v-if="accessKyt" :loading="this.loading"/>
        <UserKycInformationComponent v-if="accessKyc && this.getSingleUser.parentHasKyc" :userId="this.userId"  :loading="this.loading"/>
        <AlertsComponent
          v-if="accessKyt && this.getSingleUser.parentHasKyt"
          :userId="this.userId" :loading="this.loading"
          @parentAlertId="getAlertIdParent"
        />
        <TransfersComponent v-if="accessKyt && this.getSingleUser.parentHasKyt" :userId="this.userId"  :loading="this.loading"/>
        <AddressesComponent v-if="accessKyt && this.getSingleUser.parentHasKyt" :userId="this.userId"  :loading="this.loading"/>
      </div>
    </div>
    <div v-if="isAdmin" class="col-lg-3">
      <div class="row m-0" style="width: 100%; height: 100%">
        <CommentSectionSingleUser />
      </div>
    </div>
  </div>
  <SideBarRightAlert
    v-if="this.alertId != 0"
    :alertId="parseInt(this.alertId)"
    @alert-id-to-zero="changeAlertIdToZero"
  />
</template>
<style scope>
.breadcrumb-item::before {
  color: #000 !important;
}
</style>
<script>
import SideBarRightAlert from "@/components/Alerts/SideBarRightAlert.vue";
import CommentSectionSingleUser from "@/components/SingleUser/CommentSectionSingleUser.vue";
import UserInformationComponent from "@/components/SingleUser/UserInformationComponent.vue";
import UserKycInformationComponent from "@/components/SingleUser/UserKycInformationComponent.vue";
import AlertsComponent from "@/components/SingleUser/AlertsComponent.vue";
import TransfersComponent from "@/components/SingleUser/TransfersComponent.vue";
import AddressesComponent from "@/components/SingleUser/AddressesComponent.vue";
var $ = require("jquery");

export default {
  props: ["userId"],
  name: "SingleUserView",
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    accessKyt() {
      return this.$store.getters.accessKyt;
    },
    accessKyc() {
      return this.$store.getters.accessKyc;
    },
    getSingleUser() {
      return this.$store.getters.getSingleUser;
    },
  },
  data() {
    return {
      alertId: 0,
      loading: false,
    };
  },
  components: {
    SideBarRightAlert,
    UserInformationComponent,
    UserKycInformationComponent,
    AlertsComponent,
    TransfersComponent,
    AddressesComponent,
    CommentSectionSingleUser,
  },
  mounted() {
    document.title = "ProtoAnalysis";
    if (this.isAdmin) {
      $("#main-re").removeClass("container");
      $("#main-re").css("padding-left", "20px");
    }
    this.$store.dispatch("retrieveSingleUser", {
      singleUserId: this.userId,
    });
    this.$watch(
      () => this.$route.params,
      () => {
        setTimeout(() => {
          this.loading = !this.loading 
          this.$store.dispatch("retrieveSingleUser", {
            singleUserId: this.userId,
          });
        }, 100);
      }
    );
  },
  unmounted() {
    if (this.isAdmin) {
      $("#main-re").addClass("container");
      $("#main-re").css("padding-left", "0px");
    }
  },
  methods: {
    getAlertIdParent(value) {
      this.alertId = value;
    },
    changeAlertIdToZero(value) {
      this.alertId = value;
    },
  },
};
</script>
