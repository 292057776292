<template>
  <div class="col-xl-4 col-lg-12 col-md-12 col-xs-12">
    <div class="card">
      <div class="card-header pb-0">
        <div class="d-flex justify-content-between">
          <h3 class="card-title mg-b-0">Latest Alerts</h3>
        </div>
      </div>
      <div class="card-body px-0 pb-0">
        <div class="table-responsive">
          <table class="table mg-b-0 text-md-nowrap">
            <thead>
              <tr>
                <th>Category</th>
                <th>Amount</th>
                <th>Created</th>
              </tr>
            </thead>
            <tbody
              v-if="
                getLastestAlerts && Object.values(getLastestAlerts).length > 0
              "
            >
              <tr
                v-for="item in this.getLastestAlerts"
                :key="item.id"
                @click="goToSingle(item.id)"
                style="cursor: pointer"
              >
                <th scope="row">{{ item.category }}</th>
                <td>{{ item.alertAmount }}</td>
                <td>{{ item.created_at }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <SpinnerLoader :start="!visible" />
    </div>
  </div>
</template>
<script>
import SpinnerLoader from "@/components/SpinnerLoader.vue";
export default {
  name: "LatestAlerts",
  components: {
    SpinnerLoader,
  },
  props: {
    clientId: Number,
  },
  data() {
    return {
      visible: true,
      filters: {
      }
    };
  },
  computed: {
    getLastestAlerts() {
      return this.$store.getters.getLastestAlerts;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
  watch: {
    clientId() {
      this.filters['client_id'] = this.clientId;
      this.$store.dispatch("retrieveLastestAlerts", {
        filters: this.filters,
      });
    },
    getLastestAlerts() {
      this.visible = true;
    },
  },
  mounted() {
    this.visible = false;
    this.filters['client_id'] = this.clientId;
    this.$store.dispatch("retrieveLastestAlerts", {
      filters: this.filters,
    });
  },
  methods: {
    goToSingle(alert_id) {
      if (this.isAdmin)
        this.$router.push({
          name: "admin-single-alert",
          params: { alertId: alert_id },
        });
      else
        this.$router.push({
          name: "single-alert",
          params: { alertId: alert_id },
        });
    },
  },
};
</script>
