import LogoutView from "../views/Auth/LogoutView.vue";
import ResetPasswordView from "../views/Auth/ResetPasswordView.vue";
import ForgotPasswordView from "../views/Auth/ForgotPasswordView.vue";
import NotFound from "../views/Auth/NotFound.vue";
const common = [
  {
    path: "/logout",
    name: "logout",
    component: LogoutView,
    meta: { requiresAuth: true },
  },
  {
    path: "/reset/password",
    name: "reset-password",
    component: ResetPasswordView,
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: "/forgot/password",
    name: "forgot-password",
    component: ForgotPasswordView,
    meta: {
      requiresVisitor: true,
    },
  },
  { path: "/:pathMatch(.*)*", name: "not-found", component: NotFound },
];
export default common;
