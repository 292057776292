<template>
  <div class="col-12 col-md-12 d-flex justify-content-start mt-5">
    <h3 class="px-md-4">Error Log Information</h3>
  </div>
  <div
    v-if="visible"
    class="col-12 col-md-12 d-flex justify-content-start border-bottom-gray"
  >
    <div class="card col-12 col-md-6">
      <div class="card-body border-0" data-select2-id="12">
        <div class="form-group">
          <div class="row row-sm text-start">
            <div class="col-md-3">
              <p class="form-label text-dark">ID</p>
            </div>
            <div class="col-md-9 d-flex justify-content-start">
              <p class="form-label">
                {{ this.getSingleErrorLog.id }}
              </p>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row row-sm text-start">
            <div class="col-md-3">
              <p class="form-label text-dark">Message</p>
            </div>
            <div class="col-md-9 d-flex justify-content-start">
              <p class="form-label">
                {{ this.getSingleErrorLog.message }}
              </p>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row row-sm text-start">
            <div class="col-md-3">
              <p class="form-label text-dark">Class</p>
            </div>
            <div class="col-md-9 d-flex justify-content-start">
              <p class="form-label" style="width: 100%;">{{ this.getSingleErrorLog.class }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card col-12 col-md-6">
      <div class="card-body border-0" data-select2-id="12">
        <div class="form-group">
          <div class="row row-sm text-start">
            <div class="col-md-3">
              <p class="form-label text-dark">Function</p>
            </div>
            <div class="col-md-9 d-flex justify-content-start">
              <p class="form-label">
                {{ this.getSingleErrorLog.function }}
              </p>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row row-sm text-start">
            <div class="col-md-3">
              <p class="form-label text-dark">User ID</p>
            </div>
            <div class="col-md-9 d-flex justify-content-start">
              <p class="form-label">{{ this.getSingleErrorLog.user_id }}</p>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row row-sm text-start">
            <div class="col-md-3">
              <p class="form-label text-dark">User IP</p>
            </div>
            <div class="col-md-9 d-flex justify-content-start">
              <p class="form-label">{{ this.getSingleErrorLog.user_ip }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <SpinnerLoader
    class="col-12 w-100 h-100"
    style="background: white"
    :start="!visible"
  />
</template>
<style scope>
.border-bottom-gray {
  border-bottom: 1px solid #ccc;
}
</style>
<script>
import SpinnerLoader from "@/components/SpinnerLoader.vue";
export default {
  name: "ErrorLogInformationComponent",
  components: {
    SpinnerLoader,
  },
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    getSingleErrorLog() {
      return this.$store.getters.getSingleErrorLog;
    },
  },
  watch: {
    getSingleErrorLog() {
      this.visible = true;
    },
  },
};
</script>
