import { createRouter, createWebHistory } from "vue-router";
import adminRoute from "./admin";
import clientRoute from "./client";
import commonRoute from "./common";

const routes = [].concat(adminRoute, clientRoute, commonRoute);

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
