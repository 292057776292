<template>
  <div class="main-header p-0 hor-header sticky nav nav-item">
    <div class="main-container container">
      <div class="main-header-left">
        <div class="">
          <router-link
            :to="{ name: this.isAdmin ? 'admin-dashboard' : 'dashboard' }"
            class="header-logo"
          >
            <img
              v-if="
                ((this.$route.name == 'admin-dashboard' ||
                  this.$route.name == 'dashboard') &&
                  isBothModule) ||
                this.$route.name == 'admin-clients'
              "
              src="@/assets/img/PROTOAML.svg"
              class="mobile-logo logo-1"
              alt="logo"
              style="width: 250px"
            />
            <img
              v-else-if="isKycModule"
              src="@/assets/img/PROTOKYC.svg"
              class="mobile-logo logo-1"
              alt="logo"
              style="width: 250px"
            />
            <img
              v-else
              src="@/assets/img/PROTOANALYSIS.svg"
              class="mobile-logo logo-1"
              alt="logo"
              style="width: 250px"
            />
          </router-link>
        </div>
        <SearchComponent v-if="accessKyt || isAdmin" />
        <SwitchNav v-if="(accessKyt && accessKyc) || isAdmin" />
      </div>
      <div class="main-header-right">
        <button
          class="navbar-toggler navresponsive-toggler d-md-none ms-auto"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent-4"
          aria-controls="navbarSupportedContent-4"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon fe fe-more-vertical"></span>
        </button>
        <div
          class="mb-0 navbar navbar-expand-lg navbar-nav-right responsive-navbar navbar-dark p-0"
        >
          <div class="collapse navbar-collapse" id="navbarSupportedContent-4">
            <ul class="nav nav-item header-icons navbar-nav-right ms-auto">
              <li
                class="dropdown main-profile-menu nav nav-item nav-link ps-lg-2"
              >
                <a
                  class="new nav-link profile-user d-flex"
                  href="javascript:void(0)"
                  data-bs-toggle="dropdown"
                  @click="showProfileMenu"
                >
                  <p
                    v-if="getProfile.name"
                    style="
                      background: rgb(204, 204, 204);
                      /* padding: 16px; */
                      font-size: 18px;
                      border-radius: 50px;
                      width: 100px;
                      height: 33px;
                      color: #fff;
                    "
                  >
                    {{ getProfile.name.substring(0, 2) }}
                  </p></a
                >
                <div class="dropdown-menu">
                  <div class="menu-header-content p-3 border-bottom">
                    <div class="d-flex wd-100p">
                      <div class="main-img-user">
                        <p
                          v-if="getProfile.name"
                          style="
                            background: rgb(204, 204, 204);
                            border-radius: 50%;
                            padding: 7px;
                            color: rgb(255, 255, 255);
                          "
                        >
                          {{ getProfile.name.substring(0, 2) }}
                        </p>
                      </div>
                      <div class="ms-3 my-auto">
                        <h6 class="tx-15 font-weight-semibold mb-0">
                          {{ getProfile.name }}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <a v-if="!isOffMenu || isOffMenu == 'false'"
                    class="dropdown-item"
                    target="_blank"
                    href="https://dash.protocol33.io/v1/api-documentation/#introduction"
                    ><a
                      target="_blank"
                      href="https://dash.protocol33.io/v1/api-documentation/#introduction"
                      ><i class="far fa-arrow-alt-circle-left"></i> API
                      Documentation
                    </a></a
                  >
                  <a v-if="!isOffMenu || isOffMenu == 'false'" class="dropdown-item" href="javascript:void(0)"
                    ><router-link
                      :to="{ name: (isAdmin ? 'admin-' : '') + 'profile' }"
                      :class="getNavActive == 'profile' ? 'activity-nav' : ''"
                      ><i class="far fa-arrow-alt-circle-left"></i> Profile
                    </router-link></a
                  >
                  <a v-if="!isOffMenu || isOffMenu == 'false'" class="dropdown-item" href="javascript:void(0)"
                    ><router-link
                      :to="{
                        name: (isAdmin ? 'admin-' : '') + 'api-keys',
                      }"
                      :class="getNavActive == 'api-keys' ? 'activity-nav' : ''"
                      ><i class="far fa-arrow-alt-circle-left"></i> API Keys
                    </router-link></a
                  >
                  <a v-if="!isOffMenu || isOffMenu == 'false'" class="dropdown-item" href="javascript:void(0)"
                    ><router-link
                      :to="{
                        name: (isAdmin ? 'admin-' : '') + 'web-hooks',
                      }"
                      :class="getNavActive == 'web-hooks' ? 'activity-nav' : ''"
                      ><i class="far fa-arrow-alt-circle-left"></i> Web Hooks
                    </router-link></a
                  >
                  <a
                    v-if="isAdmin"
                    class="dropdown-item"
                    href="javascript:void(0)"
                    ><router-link
                      :to="{ name: 'admin-error-logs' }"
                      :class="
                        getNavActive == 'error-logs' ? 'activity-nav' : ''
                      "
                      ><i class="far fa-arrow-alt-circle-left"></i> Error
                      Logs</router-link
                    ></a
                  >
                  <a
                    v-if="isImpresonate && !isAdmin"
                    @click="backToAdmin"
                    class="dropdown-item"
                    href="javascript:void(0)"
                    ><i class="far fa-arrow-alt-circle-left"></i> Back To
                    Admin</a
                  >
                  <a v-else class="dropdown-item" href="javascript:void(0)"
                    ><router-link :to="{ name: 'logout' }"
                      ><i class="far fa-arrow-alt-circle-left"></i> Sign
                      Out</router-link
                    ></a
                  >
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.activity-nav {
  color: rgb(36, 175, 62) !important;
}
a.dropdown-item a{
  color: #7a839b;
} 
</style>
<script>
var $ = require("jquery");
import SearchComponent from "@/components/SearchComponent";
import SwitchNav from "@/components/Layout/SwitchNav";
export default {
  name: "MainHeader",
  components: { SearchComponent, SwitchNav },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    getProfile() {
      return this.$store.getters.getProfile;
    },
    isKycModule() {
      return this.$store.getters.isKycModule;
    },
    accessKyt() {
      return this.$store.getters.accessKyt;
    },
    accessKyc() {
      return this.$store.getters.accessKyc;
    },
    isBothModule() {
      return this.$store.getters.isBothModule;
    },
    getNavActive() {
      return this.$store.getters.getNavActive;
    },
    isImpresonate() {
      return this.$store.getters.isImpresonate;
    },
    isOffMenu() {
      return this.$store.getters.isOffMenu;
    },
  },
  methods: {
    backToAdmin() {
      $("#global-loader").css("display", "block");
      this.$store.dispatch("backToAdmin",{ backBy: "close-button" });
    },
  },
};
</script>
