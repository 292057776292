<template>
  <div class="row row-sm text-start mt-5">
    <div class="col-lg-12">
      <div class="py-2 col-12 row">
        <div class="col-12 col-md-6">
          <div class="py-2 d-flex align-items-center">
            <h2 class="main-content-label mb-1">Transfers</h2>
          </div>
        </div>
        <!-- client filter -->
        <ClientFilterComponent @changeClientId="filterChanged" />
      </div>
      <div class="my-2 col-12 justify-content-center">
        <div class="row justify-content-between align-items-end filter-s px-0 mt-2">
          <div class="row justify-content-start align-items-end">
            <SortByComponent :arrayData="sortByList" :sortBySelected="'timestamp'" @changeSortBy="SortChanged" />
          </div>
          <button @click="exportAll" class="btn btn-primary" style="margin-right: 10px;">
          <div class="lds-ellipsis" v-if="export_loading">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <span v-if="!export_loading">Export All</span>
        </button>
          <!-- <FilterComponent
            v-if="sortByTypes.length > 0"
            :title="'Sort Type'"
            :arrayData="sortByTypes"
            :variableName="'sortType'"
            @changeFilter="filterChanged"
          /> -->
        </div>
      </div>
      <div class="card custom-card overflow-hidden">
        <div class="card-body">
          <div class="table-responsive export-table">
            <PaginationComponent
              :page="this.filters.page"
              :limit="this.filters.limit"
              :getTotalRows="this.getTotalTransfers"
              :nameComponent="this.nameComponent"
              @changePage="filterChanged"
            />
            <!-- pagination and total -->
            <!-- table -->
            <DataTableTransfer
              :getTransfers="this.getTransfers"
              :visible="visible"
            />

            <!-- pagination and total -->
            <PaginationComponent
              :page="this.filters.page"
              :limit="this.filters.limit"
              :getTotalRows="this.getTotalTransfers"
              :nameComponent="this.nameComponent"
              @changePage="filterChanged"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scope>
.text-bold {
  font-weight: bold;
  flex: 0 0 8rem;
}
.cut-text {
  overflow-x: auto;
}
.cursor-pointer {
  cursor: pointer;
}
.toggle_icon {
  width: 100%;
}
.toggle_icon li {
  flex: 0 0 3rem !important;
  padding: 0px !important;
}
</style>
<script>
import ClientFilterComponent from "@/components/ClientFilterComponent.vue";
import DataTableTransfer from "@/components/Transfers/DataTableTransfer.vue";
import PaginationComponent from "@/components/PaginationComponent.vue";
import SortByComponent from "@/components/SortByComponent.vue";
// @ is an alias to /src
export default {
  name: "TransfersView",
  components: {
    ClientFilterComponent,
    DataTableTransfer,
    PaginationComponent,
    SortByComponent,
  },
  data() {
    return {
      filters: {
        page: 1,
        limit: 50,
        sortBy: "timestamp",
        sortType: "desc",
      },
      visible: false,
      nameComponent: "Transfers",
      export_loading: false,
      sortByList: [
        {
          id: 'timestamp',
          name: 'Date'
        },
        {
          id: 'direction',
          name: 'Direction'
        },
        {
          id: 'amount',
          name: 'Amount'
        }
      ],
      sortByTypes: [
        {
          id: 'asc',
          name: 'Ascending'
        },
        {
          id: 'desc',
          name: 'Descending'
        },
      ],
    };
  },
  computed: {
    getTransfers() {
      return this.$store.getters.getTransfers;
    },
    getTotalTransfers() {
      return this.$store.getters.getTotalTransfers;
    },
    getSelectedClientFilter() {
      return this.$store.getters.getSelectedClientFilter;
    },
  },
  mounted() {
    this.$store.dispatch("changeModule", "KYT");
    this.$store.dispatch("changeNavActive", "transfers");
    document.title = "Transfers | ProtoAnalysis";
    this.filters['client_id'] = this.getSelectedClientFilter;
    this.$store.dispatch("retrieveTransfers", {
      filters: this.filters,
      param: this.$route.query,
    });
  },
  methods: {
    filterChanged(value) {
      if(value[0] != 'page') {
        this.filters['page'] = 1;  
      }
      this.visible = !this.visible;
      this.filters[value[0]] = value[1];
      this.$store.dispatch("retrieveTransfers", {
        filters: this.filters,
        param: this.$route.query,
      });
    },
    SortChanged(value) {
      this.visible = !this.visible;
      this.filters['sortBy'] = value.sortBy;
      this.filters['sortType'] = value.sortType;
      this.$store.dispatch("retrieveTransfers", {
        filters: this.filters,
        param: this.$route.query,
      });
    },
    exportAll() {
      let exportFilters = Object.assign({}, this.filters)
      delete exportFilters['page']
      delete exportFilters['limit']
      this.export_loading = true
      this.$store.dispatch("exportAllTransfers", {
        filters: exportFilters,
        param: this.$route.query,
      })
        .then(() => {
          this.export_loading = false
          this.$notify({ type: "success", text: "Export Completed" });
        })
        .catch(() => {
          this.export_loading = false
          this.$notify({ type: "wronge", text: "Export Fail!!" });
        });
    }
  },
};
</script>
