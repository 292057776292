<template>
  <div
    class="modal fade"
    id="walletModal"
    tabindex="-1"
    aria-labelledby="walletModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" style="max-width: 50%">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="walletModalLabel">
            Register Withdrawal Pre-Screening
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form action="#" @submit.prevent="registerPreScreening">
            <div v-if="successMessage" class="success-message">
              <a
                href="javascript:void(0)"
                @click="initSuccessValues"
                class="cancel-table"
                >X</a
              >
              <table
                class="table table-bordered text-nowrap key-buttons border-bottom"
              >
                <tr>
                  <td>Assest</td>
                  <td>{{ successMessage.asset }}</td>
                </tr>
                <tr>
                  <td>Address</td>
                  <td>{{ successMessage.address }}</td>
                </tr>
                <tr v-if="!successMessage.cluster">
                  <td>Cluster</td>
                  <td>Null</td>
                </tr>
                <tr v-if="successMessage.cluster">
                  <td>Cluster Name</td>
                  <td>
                    {{ successMessage.cluster.name }}
                  </td>
                </tr>
                <tr v-if="successMessage.cluster">
                  <td>Cluster Category</td>
                  <td>
                    {{ successMessage.cluster.category }}
                  </td>
                </tr>
                <tr>
                  <td style="font-weight: bold">Rating</td>
                  <td style="font-weight: bold">
                    {{ successMessage.rating }}
                  </td>
                </tr>
                <tr v-if="!successMessage.customAddress">
                  <td>Custom Address</td>
                  <td>Null</td>
                </tr>
                <tr v-if="successMessage.customAddress">
                  <td>Custom Address Name</td>
                  <td>
                    {{ successMessage.customAddress.addressName }}
                  </td>
                </tr>
                <tr v-if="successMessage.customAddress">
                  <td>Custom Address Description</td>
                  <td>
                    {{ successMessage.customAddress.description }}
                  </td>
                </tr>
                <tr v-if="successMessage.customAddress">
                  <td>Custom Address Category</td>
                  <td>
                    {{ successMessage.customAddress.categoryName }}
                  </td>
                </tr>
                <tr v-if="!successMessage.chainalysisIdentification">
                  <td>CI Identity</td>
                  <td>Null</td>
                </tr>
                <tr v-if="successMessage.chainalysisIdentification">
                  <td>CI Identity Name</td>
                  <td>
                    {{ successMessage.chainalysisIdentification.addressName }}
                  </td>
                </tr>
                <tr v-if="successMessage.chainalysisIdentification">
                  <td>CI Identity Description</td>
                  <td>
                    {{ successMessage.chainalysisIdentification.description }}
                  </td>
                </tr>
                <tr v-if="successMessage.chainalysisIdentification">
                  <td>CI Identity Category</td>
                  <td>
                    {{ successMessage.chainalysisIdentification.categoryName }}
                  </td>
                </tr>
              </table>
            </div>
            <div v-if="serverError" class="server-error">
              <ul style="list-style-type: none; margin: 0px; padding: 0px">
                <li>{{ serverError }}</li>
              </ul>
            </div>
            <div class="mb-3 text-start">
              <label for="user_id" class="col-form-label">User ID:</label>
              <input
                type="text"
                class="form-control"
                id="user_id"
                name="user_id"
                placeholder=" Enter User"
                v-model="user_id"
                required
              />
            </div>
            <div class="mb-3 text-start">
              <label for="asset" class="col-form-label">Asset:</label>
              <input
                type="text"
                class="form-control"
                id="asset"
                name="asset"
                placeholder="Enter Asset"
                v-model="asset"
                required
              />
            </div>
            <div class="mb-3 text-start">
              <label for="address" class="col-form-label">Address:</label>
              <input
                type="text"
                class="form-control"
                id="address"
                name="address"
                placeholder=" Enter Address"
                v-model="address"
                required
              />
            </div>

            <div class="modal-footer">
              <button
                id="closeWalletModal"
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                :disabled="!buttonVisiable"
                class="btn btn-primary"
              >
                register
              </button>
              <div></div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.table-bordered tr {
  border: 1px solid #000 !important;
}

.cancel-table {
  position: absolute;
  right: 10px;
  top: 10px;
  color: white !important;
  font-size: 14px;
  background: black;
  padding-top: 0px;
  border-radius: 50px;
  padding-bottom: 0px;
  padding-right: 6px;
  padding-left: 6px;
}
.success-message {
  background-color: #dff0d8;
  color: #3c763d;
  margin-bottom: 12px;
  font-size: 16px;
  padding: 6px 7px;
  border-radius: 4px;
}
.table {
  margin-bottom: 0rem;
}
</style>
<script>
import "bootstrap";
//var $ = require("jquery");
export default {
  name: "WalletPreScreening",
  props: {
    toggale: String,
  },
  data() {
    return {
      user_id: "",
      asset: "",
      address: "",
      successMessage: "",
      serverError: "",
      buttonVisiable: true,
    };
  },
  methods: {
    registerPreScreening() {
      this.buttonVisiable = false;
      this.$store
        .dispatch("registerPreScreening", {
          userId: this.user_id,
          asset: this.asset,
          address: this.address,
        })
        .then((response) => {
          this.successMessage = response[0];
          this.user_id = "";
          this.asset = "";
          this.address = "";
          this.buttonVisiable = true;
          //$("#closeWalletModal").click();
        })
        .catch((error) => {
          this.serverError = error.response.data.message;
          setTimeout(() => {
            this.serverError = null;
          }, 2000);
          this.buttonVisiable = true;
        });
    },
    initSuccessValues() {
      this.successMessage = "";
    },
  },
};
</script>
