<template>
  <notifications position="bottom right" />
  <!-- Loader -->
  <div id="global-loader">
    <div class="d-flex justify-content-center align-items-center w-100 h-100">
      <img
        src="./assets/img/preloader.gif"
        class=""
        style="margin: 0 auto"
        alt="Loader"
      />
    </div>
  </div>
  <!-- /Loader -->
  <AuthMaster v-if="authPage" />
  <KycService v-if="kycServicePage" />
  <PageMaster v-if="masterPage" />

  <!-- Back-to-top -->
  <a href="#top" id="back-to-top"><i class="las la-arrow-up"></i></a>
</template>

<style lang="scss">
body {
  margin: 0px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.server-error {
  margin-bottom: 12px;
  font-size: 16px;
  padding: 10px 16px;
  color: #a94442;
  background: #f3dede;
  border-radius: 4px;
}

.success-message {
  background-color: #dff0d8;
  color: #3c763d;
  margin-bottom: 12px;
  font-size: 16px;
  padding: 10px 16px;
  border-radius: 4px;
}

.form-error {
  font-size: 16px;
  color: #a94442;
}

.input-error {
  border: 1px solid red;
}

.lds-ellipsis {
  position: relative;
  width: 70px;
  display: inline-flex;
  height: 16px;
  justify-content: center;
  align-items: center;
}
.lds-ellipsis div {
  position: absolute;
  //top: 15px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #000;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
.dataTables_scrollBody {
  min-height: 300px !important;
}
.filter-s {
    border: 1px solid #d5d5d5;
    background-color: transparent;
    padding: 11px 15px;
    border-radius: 7px;
}
.toolpop .toolpoptext {
  font-family: Avenir,Helvetica,Arial,sans-serif;
  font-size: 11px;
}
</style>
<script>
import KycService from "./views/KycService.vue";
import PageMaster from "./views/PageMaster.vue";
import AuthMaster from "./views/Auth/AuthMaster.vue";
export default {
  name: "App",
  components: {
    PageMaster,
    AuthMaster,
    KycService,
  },
  data() {
    return {
      authPage: false,
      kycServicePage: false,
      masterPage: false,
    };
  },
  computed: {
    getRouteName() {
      return this.$store.getters.getRouteName;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    isImpresonate() {
      return this.$store.getters.isImpresonate;
    },
  },
  watch: {
    getRouteName() {
      if (
        this.getRouteName == "home" ||
        this.getRouteName == "login" ||
        this.getRouteName == "admin-login" ||
        this.getRouteName == "reset-password" ||
        this.getRouteName == "forgot-password" ||
        this.getRouteName == "logout" ||
        this.getRouteName == "not-found" ||
        this.getRouteName == "admin"
      ) {
        this.authPage = true;
        this.kycServicePage = false;
        this.masterPage = false;
      } else if (this.getRouteName == "kyc-services") {
        this.authPage = false;
        this.kycServicePage = true;
        this.masterPage = false;
      } else {
        this.authPage = false;
        this.kycServicePage = false;
        this.masterPage = true;
      }
      document.getElementById("global-loader").style.display = "none";
    },
  },
};
</script>
