<template>
  <div class="row row-sm text-start mt-5">
    <div class="col-lg-12">
      <div class="py-2 col-12 row">
        <div class="col-12 col-md-6 d-flex justify-content-start">
          <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <h2 class="main-content-label mb-1">Alerts</h2>
              <ul class="navbar-nav mr-auto">
                <li id="all" class="nav-item active">
                  <a class="nav-link" href="javascript:void(0)" @click="filterChanged(['all', true])">All</a>
                </li>
                <li id="Assigned" class="nav-item">
                  <a class="nav-link" @click="filterChanged(['assignee_to', this.getProfile.email])"
                    href="javascript:void(0)">Assigned to me</a>
                </li>
                <li id="unreviewed" class="nav-item">
                  <a class="nav-link" @click="filterChanged(['review_status', 'unreviewed'])"
                    href="javascript:void(0)">Unreviewed</a>
                </li>
                <li id="in_review" class="nav-item">
                  <a class="nav-link" @click="filterChanged(['review_status', 'in_review'])" href="javascript:void(0)">In
                    Review</a>
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false" @mouseover="showMenu()">
                    ···
                  </a>
                  <div id="menu-filter" class="dropdown-menu" aria-labelledby="navbarDropdown" @mouseleave="showMenu()">
                <li id="flagged">
                  <a class="dropdown-item" @click="filterChanged(['review_status', 'flagged'])"
                    href="javascript:void(0)">Flagged</a>
                </li>
                <li id="dismissed">
                  <a class="dropdown-item" @click="filterChanged(['review_status', 'dismissed'])"
                    href="javascript:void(0)">Dismissed</a>
                </li>
                <li id="no_review">
                  <a class="dropdown-item" @click="filterChanged(['review_status', 'no_review'])"
                    href="javascript:void(0)">No Review</a>
                </li>
            </div>
            </li>
            </ul>
        </div>
        </nav>
      </div>
      <ClientFilterComponent @changeClientId="filterChanged" />
    </div>
    <div class="my-2 col-12 justify-content-center">
      <div class="row justify-content-between align-items-end filter-s px-0 mt-2">
        <div class="row justify-content-start align-items-end">
          <SortByComponent :arrayData="sortByList" :sortBySelected="'severity'" @changeSortBy="SortChanged" />
        </div>
        <button @click="exportAll" class="btn btn-primary" style="margin-right: 10px;">
          <div class="lds-ellipsis" v-if="export_loading">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <span v-if="!export_loading">Export All</span>
        </button>
        <!-- <FilterComponent
            v-if="sortByTypes.length > 0"
            :title="'Sort Type'"
            :arrayData="sortByTypes"
            :variableName="'sortType'"
            @changeFilter="filterChanged"
          /> -->
      </div>
    </div>
    <div class="card custom-card overflow-hidden">
      <div class="card-body">
        <div class="table-responsive export-table">
          <PaginationComponent :page="this.filters.page" :limit="this.filters.limit" :getTotalRows="this.getTotalAlerts"
            :nameComponent="this.nameComponent" @changePage="filterChanged" />
          <DataTableAlert @refresh="refreshTable" :getAlerts="this.getAlerts" :visible="visible" />

          <PaginationComponent :page="this.filters.page" :limit="this.filters.limit" :getTotalRows="this.getTotalAlerts"
            :nameComponent="this.nameComponent" @changePage="filterChanged" />
        </div>
      </div>
    </div>
  </div>
  </div>
  <SideBarRightAlert v-if="this.alertId" :alertId="this.alertId" />
</template>
<!--div
      class="modal-overlay"
      style="
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: RGBA(0, 0, 0, 0.6);
      "
    ></div-->
<script>
import SideBarRightAlert from "@/components/Alerts/SideBarRightAlert.vue";
import ClientFilterComponent from "@/components/ClientFilterComponent.vue";
import DataTableAlert from "@/components/Alerts/DataTableAlert.vue";
import PaginationComponent from "@/components/PaginationComponent.vue";
import SortByComponent from "@/components/SortByComponent.vue";

var $ = require("jquery");
export default {
  name: "AdminAlertsView",
  props: ["alertId"],
  components: {
    SideBarRightAlert,
    ClientFilterComponent,
    DataTableAlert,
    PaginationComponent,
    SortByComponent,
  },
  data() {
    return {
      filters: {
        page: 1,
        limit: 50,
        sortBy: "severity",
        sortType: "desc",
      },
      visible: false,
      nameComponent: "Alerts",
      renderComponent: true,
      export_loading: false,
      sortByList: [
        {
          id: 'severity',
          name: 'Severity'
        },
        {
          id: 'created_at',
          name: 'Created At'
        },
        {
          id: 'alertable_type',
          name: 'Alert Type'
        },
        {
          id: 'amount',
          name: 'Amount'
        }
      ],
      sortByTypes: [
        {
          id: 'asc',
          name: 'Ascending'
        },
        {
          id: 'desc',
          name: 'Descending'
        },
      ],
    };
  },
  computed: {
    getAlerts() {
      return this.$store.getters.getAlerts;
    },
    getTotalAlerts() {
      return this.$store.getters.getTotalAlerts;
    },
    getProfile() {
      return this.$store.getters.getProfile;
    },
    getSelectedClientFilter() {
      return this.$store.getters.getSelectedClientFilter;
    },
  },
  mounted() {
    this.$store.dispatch("changeModule", "KYT");
    this.$store.dispatch("changeNavActive", "alerts");
    document.title = "Alerts | ProtoAnalysis";
    this.filters['client_id'] = this.getSelectedClientFilter;
    this.$store.dispatch("retrieveAlerts", {
      filters: this.filters,
      param: this.$route.query,
    });
    $('button.more-details-link').on("click", function (e) {
      e.preventDefault();
      var url = "google.com"
      window.open(url, '_blank')
    });
    this.$watch(
      () => this.$route.params,
      () => {
        this.visible = !this.visible;
        this.$store.dispatch("retrieveAlerts", {
          filters: this.filters,
          param: this.$route.query,
        });
      }
    );
  },
  methods: {
    filterChanged(value) {
      this.visible = !this.visible;
      this.filters[value[0]] = value[1];
      if (value[0] != 'page') {
        this.filters['page'] = 1;
      }
      if (value[0] == 'all') {
        $("li.nav-item.active").removeClass("active");
        $("li#all").addClass("active");
        delete this.filters['all'];
        delete this.filters['review_status'];
        delete this.filters['assignee_to'];
      }
      else if (value[0] == 'review_status') {
        $("li.nav-item.active").removeClass("active");
        $("li#" + value[1]).addClass("active");
        delete this.filters['assignee_to'];
      }
      else if (value[0] == 'assignee_to') {
        $("li.nav-item.active").removeClass("active");
        $("li#Assigned").addClass("active");
        delete this.filters['review_status'];
      }
      this.$store.dispatch("retrieveAlerts", {
        filters: this.filters,
        param: this.$route.query,
      });
    },
    SortChanged(value) {
      this.visible = !this.visible;
      this.filters['sortBy'] = value.sortBy;
      this.filters['sortType'] = value.sortType;
      this.$store.dispatch("retrieveAlerts", {
        filters: this.filters,
        param: this.$route.query,
      });
    },
    refreshTable(value) {
      if(value) {
        this.visible = !this.visible;
        this.$store.dispatch("retrieveAlerts", {
          filters: this.filters,
          param: this.$route.query,
        });
      }
    },
    showMenu() {
      var x = document.getElementById("menu-filter");
      if (window.getComputedStyle(x).display === "none")
        document.getElementById("menu-filter").style.display = "block";
      else document.getElementById("menu-filter").style.display = "none";
    },
    exportAll() {
      let exportFilters = Object.assign({}, this.filters)
      delete exportFilters['page']
      delete exportFilters['limit']
      this.export_loading = true
      this.$store.dispatch("exportAllAlerts", {
        filters: exportFilters,
        param: this.$route.query,
      })
        .then(() => {
          this.export_loading = false
          this.$notify({ type: "success", text: "Export Completed" });
        })
        .catch(() => {
          this.export_loading = false
          this.$notify({ type: "wronge", text: "Export Fail!!" });
        });
    }
  },
};
</script>

<style scope>
.btn-info {
  font-size: 12px;
}

.btn-approve {
  color: #569618;
  background: transparent;
  border-color: #569618;
  display: inline-block;
  width: 105px;
}

.btn-approve:hover,
.btn-approve:active,
.btn-approve:focus {
  color: #569618 !important;
  background: #c0ff84 !important;
  border-color: #569618 !important;
  box-shadow: 0 0 0 0.2rem white !important;
}

.btn-on-hold:hover,
.btn-on-hold:active,
.btn-on-hold:focus {
  color: orangered !important;
  background: transparent !important;
  border-color: orangered !important;
  box-shadow: 0 0 0 0.2rem white !important;
}

.btn-on-hold {
  color: orangered;
  background: transparent;
  border-color: orangered;
  display: inline-block;
  width: 105px;
}

.dropdown-menu .own-dropdown {
  border-bottom: #cccccb 1px solid;
}

.dropdown-menu .own-dropdown:first-child {
  color: #569618;
}

.dropdown-menu .own-dropdown:nth-child(2) {
  color: orangered;
}

.dropdown-menu .own-dropdown:nth-child(3) {
  color: deepskyblue;
}

.assignment_icon {
  font-size: 30px !important;
  color: #cbcbcb !important;
}

.wrap_item_name {
  white-space: pre-wrap;
}

.select_whithout_arrow {
  background-image: none !important;
  font-size: 13px;
  font-weight: 900;
}

.select_whithout_arrow option {
  font-size: 13px;
}

.severity_severe {
  width: 80px;
  display: inline-block;
  padding: 8px 15px;
  background-color: rgb(255, 240, 240);
  color: rgb(162, 9, 7);
}

.severity_high {
  width: 80px;
  display: inline-block;
  padding: 8px 15px;
  background-color: rgb(255, 236, 224);
  color: rgb(194, 78, 0);
}

.severity_medium {
  width: 80px;
  display: inline-block;
  padding: 8px 15px;
  background-color: rgb(255, 249, 219);
  color: rgb(153, 107, 15);
}

.severity_low {
  width: 80px;
  display: inline-block;
  padding: 8px 15px;
  background-color: rgb(220 227 203);
  color: rgb(156 159 12);
}

.severity_other {
  width: 80px;
  display: inline-block;
  padding: 8px 15px;
  background-color: rgb(199 205 184);
  color: rgb(108 110 6);
}

.dropdown-scrollbar {
  height: 150px;
  overflow: scroll;
}

.btn-paging {
  background-color: #e1e1e1;
  border: 1px solid #aaa;
  font-weight: 600;
}

.btn-paging:hover {
  background-color: #fff;
}

.total_rows {
  font-size: 14px;
  color: #858f99;
}

.dt-button {
  background-color: #e5e5e5;
  border: 1px solid #d7d3d3;
  color: #7e7d7d;
  border-radius: 8px;
  font-weight: 100;
  font-size: 13px;
}

.dt-button:hover {
  background-color: #fff;
  color: #7e7d7d;
}
</style>
