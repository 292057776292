<template>
  <div class="main-sidemenu is-expanded">
    <ul class="side-menu flex-wrap">
      <li class="slide">
        <a
          style="padding-right: 0px !important"
          v-if="isAdmin || accessKyt"
          href="javascript:void(0)"
          @click="changeMod('KYT')"
          class="side-menu__item"
        >
          <span
            class="side-menu__label"
            :style="
              'border-radius: 10px 0 0 10px;' +
              (!isKytModule ? 'background: white;' : 'color: white !important;')
            "
            >Proto Analysis</span
          >
        </a>
      </li>
      <li class="slide">
        <a
          style="padding-left: 0px !important"
          v-if="isAdmin || accessKyc"
          href="javascript:void(0)"
          @click="changeMod('KYC')"
          class="side-menu__item"
        >
          <span
            class="side-menu__label"
            :style="
              'border-radius: 0 10px 10px 0;' +
              (!isKycModule ? 'background: white;' : 'color: white !important;')
            "
            >Proto KYC</span
          >
        </a>
      </li>
    </ul>
  </div>
</template>
<style scoped>
.side-menu__label {
  display: inline-block;
  font-weight: 400;
  color: #4a4a69;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.5rem 0.78rem;
  font-size: 0.875rem;
  line-height: 0.5;
  border: 1px #24af3e solid !important;
  padding: 10px !important;
  font-size: 14px !important;
  color: black !important;
  background: #24af3e;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.side-menu__label:hover {
  color: white !important;
  background: #24af3e !important;
}
</style>
<script>
export default {
  name: "SwitchNav",
  computed: {
    isKycModule() {
      return this.$store.getters.isKycModule;
    },
    isKytModule() {
      return this.$store.getters.isKytModule;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    accessKyt() {
      return this.$store.getters.accessKyt;
    },
    accessKyc() {
      return this.$store.getters.accessKyc;
    },
  },
  methods: {
    changeMod(mod) {
      this.$store.dispatch("changeNavActive", "home");
      this.$store.dispatch("changeModule", mod);
      this.$store.dispatch("changeStatusBothModule", false);
      if (this.isAdmin)
        this.$router.push({
          name: "admin-dashboard",
        });
      else
        this.$router.push({
          name: "dashboard",
        });
    },
  },
};
</script>
