<template>
  <div
    class="modal fade bd-example-modal-lg"
    id="ShowLink"
    tabindex="-1"
    aria-labelledby="myLargeModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Generation Link</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div v-if="generateLink.slice(0, 4) == 'http'" class="modal-body">
          <input
            id="myinput_"
            type="text"
            readonly
            :value="this.generateLink"
          />
          <button class="toolpop" id="but" type="button">
            <i
              class="fas fa-copy"
              style="font-size: 14px"
              @click="copy()"
              @mouseover="defineCopy()"
              @mouseout="backToOrigin()"
              ><span class="toolpoptext" id="myToolpop_"
                >Copy to clipboard</span
              ></i
            >
          </button>
        </div>
        <div v-else class="modal-body d-flex justify-content-center">
          <p class="server-error">{{ this.generateLink }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
input[type="text"] {
  width: 100%;
  height: 35px;
  padding-right: 50px;
  padding-left: 10px;
}

#but {
  margin-left: -50px;
  height: 35px;
  width: 50px;
  color: black;
  border: 1px solid black;
  -webkit-appearance: none;
}
.server-error {
  font-size: 20px;
  padding: 10px 40px;
}
</style>
<script>
export default {
  name: "LinkModal",
  props: {
    link: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      generateLink: "",
    };
  },
  watch: {
    link() {
      this.generateLink = this.link;
    },
  },
  methods: {
    copy() {
      var text = document.getElementById("myinput_").value;
      var listener = function (ev) {
        ev.clipboardData.setData("text/plain", text);
        ev.preventDefault();
      };
      document.addEventListener("copy", listener);
      document.execCommand("copy");
      document.removeEventListener("copy", listener);
      var toolpop = document.getElementById("myToolpop_");
      toolpop.innerHTML = "Copied: " + text;
      toolpop.style.visibility = "visible";
      toolpop.style.opacity = 1;
    },
    defineCopy() {
      var toolpop = document.getElementById("myToolpop_");
      toolpop.innerHTML = "Copy to clipboard";
      toolpop.style.visibility = "visible";
      toolpop.style.opacity = 1;
    },
    backToOrigin() {
      var toolpop = document.getElementById("myToolpop_");
      toolpop.style.visibility = "hidden";
      toolpop.style.opacity = 0;
    },
  },
};
</script>
