<template>
    <div style="margin-right: 10px">
      <label for="">Sort By</label>
      <select v-model="selectedValue" class="SlectBox form-select">
        <option v-for="(item, index) in arrayData" :key="item.id" :value="item.id" :selected="index == 0">
          {{ item.name }}
        </option>
      </select>
    </div>
    <div style="margin-right: 10px" class="row">
        <label class="rdiobox" :for="'asc'+uniqId"><input :id="'asc'+uniqId" value="asc" v-model="choosedValue" type="radio"> <span>Ascending</span></label>
        <label class="rdiobox" :for="'desc'+uniqId"><input :id="'desc'+uniqId" value="desc" v-model="choosedValue" type="radio"> <span>Descending</span></label>
    </div>

  </template>
  
  <script>
  export default {
    name: "SortByComponent",
    emits: ['changeSortBy'],
    props: {
      arrayData: Object,
      sortBySelected: String,
      uniqId: {
        type: String,
        default: ""
      }
    },
    data() {
      return {
        selectedValue: this.sortBySelected,
        choosedValue: 'desc',
      };
    },
    watch: {
      sortBySelected() {
        //this.selectedValue = this.sortBySelected
      },
      selectedValue() {
        this.$emit("changeSortBy", {
            sortBy: this.selectedValue,
            sortType: this.choosedValue
        });
      },
      choosedValue() {
        this.$emit("changeSortBy", {
            sortBy: this.selectedValue,
            sortType: this.choosedValue
        });
      },
    },
  };
  </script>
  