<template>
  <div>
    <table
      id="datatable_error_log"
      class="table table-bordered text-nowrap key-buttons border-bottom"
      style="text-align: center"
    >
      <thead>
        <tr>
          <th class="border-bottom-0">Error ID</th>
          <th class="border-bottom-0">Message</th>
          <th class="border-bottom-0">Class</th>
          <th class="border-bottom-0">Function</th>
          <th class="border-bottom-0">User IP</th>
        </tr>
      </thead>
      <tbody v-if="loading">
        <tr class="old">
          <td valign="top" colspan="6" class="dataTables_empty">
            <SpinnerLoader :start="loading" />
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="Object.values(getErrorLogs).length">
        <tr
          v-for="error in getErrorLogs"
          :key="error.id"
          @click="moveToSingle(error.id)"
          style="cursor: pointer"
        >
          <td>
            {{ error.id }}
          </td>
          <td style="white-space: pre-wrap">{{ error.message }}</td>
          <td style="white-space: pre-wrap;width: 1000px;max-width: 1000px">{{ error.class }}</td>
          <td>{{ error.function }}</td>
          <td>{{ error.user_ip }}</td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr class="old">
          <td valign="top" colspan="6" class="dataTables_empty">
            No matching records found
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<style scope>
.link-hover:hover {
  text-decoration: underline;
}
tbody {
  text-align: center;
}
.dataTables_scrollBody {
  min-height: 130px;
}
</style>
<script>
import SpinnerLoader from "@/components/SpinnerLoader.vue";

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
var $ = require("jquery");
import "bootstrap";
export default {
  name: "DataTableErrorLogs",
  props: {
    getErrorLogs: Object,
    visible: Boolean,
  },
  components: {
    SpinnerLoader,
  },
  data() {
    return {
      table: null,
      loading: true,
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
  watch: {
    getErrorLogs() {
      if (this.table == null && Object.values(this.getErrorLogs).length)
        setTimeout(() => {
          this.table = $("#datatable_error_log").DataTable({
            scrollX: true,
            searching: false,
            paging: false,
            info: false,
            order: [],
            dom: "Bfrltip",
            buttons: ["csv", "print", "copy"],
          });
        }, 100);
      else if (this.table != null && Object.values(this.getErrorLogs).length) {
        //this.table = null;
        $('#datatable_error_log').DataTable().clear().destroy();
        setTimeout(() => {
          this.table = $("#datatable_error_log").DataTable({
            scrollX: true,
            searching: false,
            paging: false,
            info: false,
            order: [],
            dom: "Bfrltip",
            buttons: ["csv", "print", "copy"],
          });
        }, 100);
      }
      this.loading = false;
    },
    visible() {
      this.loading = true;
    },
  },
  methods: {
    moveToSingle(error_id) {
      this.$router.push({
        name: "admin-single-error-log",
        params: { errorId: error_id },
      });
    },
  },
};
</script>
