<template>
  <div class="col-sm-12 col-lg-12 col-xl-8">
    <div class="card custom-card overflow-hidden">
      <div class="card-header border-bottom-0 d-flex">
        <h3 class="card-title mb-2">Transfer Volume per Category</h3>
        <div class="card-options ms-auto">
          <div class="btn-group p-0">
            <button
              @click="changeDirection('both')"
              class="btn btn-outline-light btn-sm"
              type="button"
            >
              Both
            </button>
            <button
              @click="changeDirection('received')"
              class="btn btn-light btn-sm"
              type="button"
            >
              Received
            </button>
            <button
              @click="changeDirection('sent')"
              class="btn btn-outline-light btn-sm"
              type="button"
            >
              Sent
            </button>
          </div>
        </div>
      </div>
      <div class="card-body py-5">
        <ApexChartTransfer
          :transferVolumeperCategory="this.getTransferVolumeperCategory"
          v-if="visible"
        />
        <SpinnerLoader :start="!visible" />
      </div>
    </div>
  </div>
</template>

<script>
import SpinnerLoader from "@/components/SpinnerLoader.vue";
import ApexChartTransfer from "@/components/Home/ApexChartTransfer.vue";
export default {
  name: "TransferVolumeperCategory",
  components: {
    ApexChartTransfer,
    SpinnerLoader,
  },
  props: {
    clientId: Number,
  },
  data() {
    return {
      visible: true,
      filters: {
      }
    };
  },
  computed: {
    getTransferVolumeperCategory() {
      return this.$store.getters.getTransferVolumeperCategory;
    },
  },
  watch: {
    clientId() {
      this.visible = false;
      this.filters['client_id'] = this.clientId;
      this.$store
        .dispatch("retrieveTransferVolumeperCategory", {
          filters: this.filters,
        });
    },
    getTransferVolumeperCategory() {
      this.visible = true;
    },
  },
  mounted() {
    this.visible = false;
    this.filters['client_id'] = this.clientId;
    this.$store.dispatch("retrieveTransferVolumeperCategory", {
        filters: this.filters,
      });
  },
  methods: {
    changeDirection(value) {
      this.visible = false;
      this.filters['client_id'] = this.clientId;
      this.filters['direction'] = value;
      this.$store
        .dispatch("retrieveTransferVolumeperCategory", {
          filters: this.filters,
        });
    },
  },
};
</script>
