<template>
  <div class="page" style="justify-content: start">
    <UpHeader />
    <div class="main-content horizontal-content">
      <div class="main-container container" id="main-re">
        <router-view />
      </div>
    </div>

    <DownFooter />
  </div>
</template>

<script>
import DownFooter from "@/components/Layout/DownFooter.vue";
import UpHeader from "@/components/Layout/UpHeader.vue";
export default {
  name: "PageMaster",
  components: {
    DownFooter,
    UpHeader,
  },
};
</script>
