<template>
  <div class="row row-sm text-start mt-5">
    <div class="col-lg-12">
      <div class="py-2 col-12 row">
        <div class="col-12 col-md-6 d-flex justify-content-start">
          <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <h2 class="main-content-label mb-1">Users</h2>
              <ul class="navbar-nav mr-auto">
                <li id="all" class="nav-item active">
                  <a class="nav-link" href="#" @click="filterChanged(['all',true])">All</a>
                </li>
                <li id="SEVERE" class="nav-item">
                  <a class="nav-link" @click="filterChanged(['riskScore','SEVERE'])" href="#"
                    >Severe</a
                  >
                </li>
                <li id="HIGH" class="nav-item">
                  <a class="nav-link" @click="filterChanged(['riskScore','HIGH'])" href="#"
                    >High</a
                  >
                </li>
                <li id="MEDIUM" class="nav-item">
                  <a class="nav-link" @click="filterChanged(['riskScore','MEDIUM'])" href="#"
                    >Medium</a
                  >
                </li>
                <li id="LOW" class="nav-item">
                  <a class="nav-link" @click="filterChanged(['riskScore','LOW'])" href="#"
                    >Low</a
                  >
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
      <div class="my-2 col-12 justify-content-center">
        <div class="row justify-content-start align-items-end filter-s px-0 mt-2">
          <SortByComponent :arrayData="sortByList" :sortBySelected="'last_activity'" @changeSortBy="SortChanged" />
          <!-- <FilterComponent
            v-if="sortByTypes.length > 0"
            :title="'Sort Type'"
            :arrayData="sortByTypes"
            :variableName="'sortType'"
            @changeFilter="filterChanged"
          /> -->
        </div>
      </div>
      <div class="card custom-card overflow-hidden">
        <div class="card-body">
          <div class="table-responsive export-table">
            <PaginationComponent
              :page="this.filters.page"
              :limit="this.filters.limit"
              :getTotalRows="this.getTotalUsers"
              :nameComponent="this.nameComponent"
              @changePage="filterChanged"
            />
            <!-- pagination and total -->
            <!-- table -->
            <DataTableUser :getUsers="this.getUsers" :visible="visible" />

            <!-- pagination and total -->
            <PaginationComponent
              :page="this.filters.page"
              :limit="this.filters.limit"
              :getTotalRows="this.getTotalUsers"
              :nameComponent="this.nameComponent"
              @changePage="filterChanged"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scope>
.assignment_icon {
  font-size: 30px !important;
  color: #cbcbcb !important;
}
.wrap_item_name {
  white-space: pre-wrap;
}
.select_whithout_arrow {
  background-image: none !important;
  font-size: 13px;
  font-weight: 900;
}
.select_whithout_arrow option {
  font-size: 13px;
}
.dropdown-scrollbar {
  height: 150px;
  overflow: scroll;
}
.btn-paging {
  background-color: #e1e1e1;
  border: 1px solid #aaa;
  font-weight: 600;
}

.btn-paging:hover {
  background-color: #fff;
}
.total_rows {
  font-size: 14px;
  color: #858f99;
}
.dt-button {
  background-color: #e5e5e5;
  border: 1px solid #d7d3d3;
  color: #7e7d7d;
  border-radius: 8px;
  font-weight: 100;
  font-size: 13px;
}
.dt-button:hover {
  background-color: #fff;
  color: #7e7d7d;
}
</style>

<script>
import DataTableUser from "@/components/Users/DataTableUser.vue";
import PaginationComponent from "@/components/PaginationComponent.vue";
import SortByComponent from "@/components/SortByComponent.vue";
var $ = require("jquery");
export default {
  name: "UsersView",
  components: {
    DataTableUser,
    PaginationComponent,
    SortByComponent,
  },
  data() {
    return {
      filters: {
        page: 1,
        limit: 50,
        sortBy: "last_activity",
        sortType: "desc",
      },
      visible: false,
      nameComponent: "Users",
      sortByList: [
        {
          id: 'last_activity',
          name: 'Last Activity'
        },
        {
          id: 'risky_volume',
          name: 'Risky Volume'
        },
        {
          id: 'transfer_volume',
          name: 'Transfer Volume'
        }
      ],
      sortByTypes: [
        {
          id: 'asc',
          name: 'Ascending'
        },
        {
          id: 'desc',
          name: 'Descending'
        },
      ],
    };
  },
  computed: {
    getUsers() {
      return this.$store.getters.getUsers;
    },
    getTotalUsers() {
      return this.$store.getters.getTotalUsers;
    },
  },
  mounted() {
    this.$store.dispatch("changeModule", "KYT");
    this.$store.dispatch("changeNavActive", "users");
    if (this.$route.query["riskScore"]) {
      $("li.nav-item.active").removeClass("active");
      $("li#" + this.$route.query["riskScore"]).addClass("active");
      this.filters['riskScore'] = this.$route.query["riskScore"];
      this.$router.replace('/users');
    }
    document.title = "Users  | ProtoAnalysis";
    this.$store.dispatch("retrieveUsers", {
      filters: this.filters,
      param: this.$route.query,
    });
  },
  methods: {
    filterChanged(value) {
      this.visible = !this.visible;
      this.filters[value[0]] = value[1];
      if(value[0] != 'page') {
        this.filters['page'] = 1;  
      }
      if(value[0] == 'all') {
        $("li.nav-item.active").removeClass("active");
        $("li#all").addClass("active");
        delete this.filters['all'];
        delete this.filters['riskScore'];
      }
      else if(value[0] == 'riskScore') {
        $("li.nav-item.active").removeClass("active");
        $("li#" + value[1]).addClass("active");
      }
      this.$store.dispatch("retrieveUsers", {
        filters: this.filters,
        param: this.$route.query,
      });
    },
    SortChanged(value) {
      this.visible = !this.visible;
      this.filters['sortBy'] = value.sortBy;
      this.filters['sortType'] = value.sortType;
      this.$store.dispatch("retrieveUsers", {
        filters: this.filters,
        param: this.$route.query,
      });
    },
  },
};
</script>
