import AdminDashboardView from "../views/Admin/AdminDashboardView.vue";
import AdminUsersView from "../views/Admin/AdminUsersView.vue";
import AdminAlertsView from "../views/Admin/AdminAlertsView.vue";
import AdminTransfersView from "../views/Admin/AdminTransfersView.vue";
import AdminErrorLogs from "../views/Admin/AdminErrorLogs.vue";
import AdminSingleErrorLog from "../views/Admin/AdminSingleErrorLog.vue";
import AdminLoginView from "../views/Auth/AdminLoginView.vue";
import UserManagement from "../views/Admin/UserManagement.vue";
import ProfileView from "../views/ProfileView.vue";
import ApiKeys from "../views/Settings/ApiKeys.vue";
import WebHooks from "../views/Settings/WebHooks.vue";
import SingleUserView from "../views/SingleUserView.vue";
import AdminKycView from "../views/Admin/AdminKycView.vue";
import AdminSwitch from "../views/Admin/AdminSwitch.vue";

const admin = [
  {
    path: "/admin",
    name: "admin",
    component: AdminLoginView,
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: "/admin/login",
    name: "admin-login",
    component: AdminLoginView,
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: "/admin/clients",
    name: "admin-clients",
    component: UserManagement,
    meta: { requiresAdmin: true },
  },
  {
    path: "/admin/profile",
    name: "admin-profile",
    component: ProfileView,
    meta: { requiresAdmin: true },
  },
  {
    path: "/admin/dashboard",
    name: "admin-dashboard",
    component: AdminDashboardView,
    props: true,
    meta: { requiresAdmin: true },
  },
  {
    path: "/admin/users",
    name: "admin-users",
    component: AdminUsersView,
    meta: { requiresAdmin: true },
  },
  {
    path: "/admin/users/:userId",
    name: "admin-single-user",
    component: SingleUserView,
    meta: { requiresAdmin: true },
    props: true,
  },
  {
    path: "/admin/alerts",
    name: "admin-alerts",
    component: AdminAlertsView,
    meta: { requiresAdmin: true },
  },
  {
    path: "/admin/alerts/:alertId",
    name: "admin-single-alert",
    component: AdminAlertsView,
    meta: { requiresAdmin: true },
    props: true,
  },
  {
    path: "/admin/transfers",
    name: "admin-transfers",
    component: AdminTransfersView,
    meta: { requiresAdmin: true },
  },
  {
    path: "/admin/kyc",
    name: "admin-kyc",
    component: AdminKycView,
    meta: { requiresAdmin: true },
  },
  {
    path: "/admin/settings/api-keys",
    name: "admin-api-keys",
    component: ApiKeys,
    meta: { requiresAdmin: true },
    props: true,
  },
  {
    path: "/admin/settings/web-hooks",
    name: "admin-web-hooks",
    component: WebHooks,
    meta: { requiresAdmin: true },
  },
  {
    path: "/admin/error-logs",
    name: "admin-error-logs",
    component: AdminErrorLogs,
    meta: { requiresAdmin: true },
  },
  {
    path: "/admin/error-logs/:errorId",
    name: "admin-single-error-log",
    component: AdminSingleErrorLog,
    meta: { requiresAdmin: true },
    props: true,
  },
  {
    path: "/admin/switch/:clientId",
    name: "admin-switch",
    component: AdminSwitch,
    meta: { requiresAdmin: true },
    props: true,
  },
];
export default admin;
