<template>
  <div class="page" style="justify-content: start">
    <div class="main-header p-0 hor-header sticky nav nav-item">
      <div class="main-container container">
        <div class="main-header-left">
          <div class="">
            <a
              :href="this.isAdmin ? '/admin/dashboard' : '/dashboard'"
              class="header-logo"
            >
              <img
                src="@/assets/img/PROTOKYC.svg"
                class="mobile-logo logo-1"
                alt="logo"
                style="width: 250px"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="jumps-prevent" style="padding-top: 63px"></div>
    <div class="main-content horizontal-content">
      <div class="main-container container" id="main-re">
        <div
          v-if="successMessage"
          class="success-message"
          style="margin: auto; width: max-content"
        >
          {{ successMessage }}
        </div>
        <div
          v-if="errorMessage[0] != ''"
          class="server-error"
          style="margin: auto; width: max-content"
        >
          <ul
            style="list-style-type: none; margin: 0px; padding: 0px"
            v-for="(item, index) in errorMessage"
            :key="index"
          >
            <li
              v-if="item != ''"
              :style="index == 1 ? 'font-weight: bold;' : ''"
            >
              {{ item }}
            </li>
          </ul>
        </div>
        <div v-if="iframe" id="sumsub-websdk-container"></div>
      </div>
    </div>
    <DownFooter />
  </div>
</template>
<script>
import DownFooter from "@/components/Layout/DownFooter.vue";
import snsWebSdk from "@sumsub/websdk";
export default {
  name: "KycService",
  data() {
    return {
      iframe: true,
      errorMessage: ["", "", ""],
      successMessage: "",
    };
  },
  components: {
    DownFooter,
  },
  created() {
    setTimeout(() => {
      this.launchWebSdk(this.$route.query.reference);
      // this.$store
      //   .dispatch("getKycServiceStatus", {
      //     refer: this.$route.query.reference,
      //   })
      //   .then((response) => {
      //     console.log(response);
      //     if (response.data.body.review_answer == "GREEN") {
      // this.successMessage =
      //   "your KYC check has been approved, please check the application for more details";
      //     } else if (response.data.body.review_answer == "RED") {
      //       if (response.data.body.review_reject_type == '"RETRY"') {
      // this.successMessage = "";
      // this.errorMessage[0] =
      //   "we are sorry, your KYC check has been Rejected, some information is missed";
      // this.errorMessage[1] =
      //   "please check the application to retry the KYC process";
      //         //this.launchWebSdk(this.$route.query.reference);
      //       } else {
      // this.successMessage = "";
      // this.errorMessage[0] =
      //   "we are sorry, your KYC check has been Rejected, Please check the Application for more details";
      // this.errorMessage[1] = "";
      //       }
      //     } else {
      //       this.launchWebSdk(this.$route.query.reference);
      //     }
      //   })
      //   .catch((error) => {
      //     this.launchWebSdk(this.$route.query.reference);
      //     console.log(error);
      //   });
    }, 200);
  },
  methods: {
    launchWebSdk(accessToken, applicantEmail, applicantPhone) {
      let snsWebSdkInstance = snsWebSdk
        .init(accessToken, () => this.getNewAccessToken())
        .withConf({
          lang: "en",
          email: applicantEmail,
          phone: applicantPhone,
          i18n: {
            document: {
              subTitles: {
                IDENTITY: "Upload a document that proves your identity",
              },
            },
          },
          onMessage: (type, payload) => {
            console.log("WebSDK onMessage", type, payload);
          },
          uiConf: {
            customCssStr:
              ":root {\n  --black: #000000;\n   --grey: #F5F5F5;\n  --grey-darker: #B2B2B2;\n  --border-color: #DBDBDB;\n}\n\np {\n  color: var(--black);\n  font-size: 16px;\n  line-height: 24px;\n}\n\nsection {\n  margin: 40px auto;\n}\n\ninput {\n  color: var(--black);\n  font-weight: 600;\n  outline: none;\n}\n\nsection.content {\n  background-color: var(--grey);\n  color: var(--black);\n  padding: 40px 40px 16px;\n  box-shadow: none;\n  border-radius: 6px;\n}\n\nbutton.submit,\nbutton.back {\n  text-transform: capitalize;\n  border-radius: 6px;\n  height: 48px;\n  padding: 0 30px;\n  font-size: 16px;\n  background-image: none !important;\n  transform: none !important;\n  box-shadow: none !important;\n  transition: all 0.2s linear;\n}\n\nbutton.submit {\n  min-width: 132px;\n  background: none;\n  background-color: var(--black);\n}\n\n.round-icon {\n  background-color: var(--black) !important;\n  background-image: none !important;\n}",
          },
          onError: (error) => {
            console.error("WebSDK onError", error);
          },
        })
        .withOptions({ addViewportTag: false, adaptIframeHeight: true })
        .on("stepCompleted", (payload) => {
          console.log("stepCompleted", payload);
        })
        .on("applicantStatus", (payload) => {
          console.log("applicantStatus", payload.attemptId);
        })
        .on("onError", (payload) => {
          console.log("onError", payload);
        })
        .onMessage((type, payload) => {
          /*if (type == "idCheck.applicantStatus") {
            if (payload.reviewResult != null) {
              if (payload.reviewResult.reviewAnswer == "GREEN") {
                this.successMessage =
                  "your KYC check has been approved, please check the application for more details";
                this.errorMessage = ["", "", ""];
                this.iframe = false;
              } else if (
                payload.reviewResult.reviewAnswer == "RED" &&
                payload.reviewResult.reviewRejectType == "RETRY"
              ) {
                // this.successMessage = "";
                // this.errorMessage[0] =
                //   "we are sorry, your KYC check has been Rejected, some information is missed : ";
                // this.errorMessage[1] = payload.reviewResult.moderationComment;
                // this.errorMessage[2] =
                //   "please check the application to retry the KYC process";
                // this.iframe = false;
              } else if (
                payload.reviewResult.reviewAnswer == "RED" &&
                payload.reviewResult.reviewRejectType == "FINAL"
              ) {
                this.successMessage = "";
                this.errorMessage = ["", "", ""];
                this.errorMessage[0] =
                  "we are sorry, your KYC check has been Rejected, Please check the Application for more details";
                this.iframe = false;
              }
            }
          }*/
          console.log("onMessage", type, payload);
        })
        .build();
      snsWebSdkInstance.launch("#sumsub-websdk-container");
    },
    getNewAccessToken() {
      return Promise.resolve();
    },
  },
};
</script>
