<template>
  <div>
    <Multiselect
      v-model="value"
      mode="tags"
      id="alertselect"
      class="form-control"
      :close-on-select="false"
      :searchable="true"
      :create-option="true"
      :options="options"
    />
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";

export default {
  name: "Select2Component",
  props: {
    types: Array,
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      value: this.types,
      options: [
        { value: 1, label: "Severe " },
        { value: 2, label: "High " },
        { value: 3, label: "Medium " },
        { value: 4, label: "Low" },
        { value: 5, label: "Other" },
      ],
    };
  },
  mounted() {
    this.value = this.types;

    //this.value = null;
  },
  watch: {
    value() {
      this.$emit("selectValue", this.value);
    },
    types() {
      this.value = this.types;
    },
  },
};
</script>
<style scope>
.multiselect-tag {
  background: var(--primary-bg-hover) !important;
  font-size: 0.875rem !important;
  font-family: inherit !important;
}
.multiselect-option {
  font-size: 0.875rem !important;
  font-family: inherit !important;
}
</style>

<style src="@vueform/multiselect/themes/default.css"></style>
