const state = {
  type: null,// localStorage.getItem("access_type") || null,
  token: null,// localStorage.getItem("access_token") || null,
  roles: [],// localStorage.getItem("access_roles") || [],
  protoModule: "",// localStorage.getItem("access_module") || "",
  offMenu: false,// localStorage.getItem("access_off_menu") || false,
  adminDetailBack: {
    type: null,// localStorage.getItem("access_type_admin") || null,
    token: null,// localStorage.getItem("access_token_admin") || null,
    roles: [],// localStorage.getItem("access_roles_admin") || [],
    protoModule: "",// localStorage.getItem("access_module_admin") || "",
  },
  clientDetailBack: {
    type: null,// localStorage.getItem("access_type_client") || null,
    token: null,// localStorage.getItem("access_token_client") || null,
    roles: [],// localStorage.getItem("access_roles_client") || [],
    protoModule: "",// localStorage.getItem("access_module_client") || "",
    offMenu: false,// localStorage.getItem("access_off_menu_client") || false,
  },
  isClientImpresonate: false,// localStorage.getItem("access_impresonate") || false,
  protoModuleDashboardBoth: false,
  routeName: "",
  clients: {},
  clientLevels: {},
  selectedClientFilter: "",
  navActive: "",
  allRoles: {},
  alerts: {},
  totalAlerts: 0,
  users: {},
  errorLogs: {},
  usersKyc: {},
  levels: {},
  totalUsers: 0,
  totalErrorLogs: 0,
  totalUsersKyc: 0,
  transfers: {},
  totalTransfers: 0,
  profile: {},
  singleUser: {},
  singleUserKycDetails: {},
  singleErrorLog: {},
  singleUserAlerts: [],
  totalSingleUserAlerts: 0,
  singleUserAddresses: [],
  totalSingleUserAddresses: 0,
  singleUserTransfers: [],
  totalSingleUserTransfers: 0,
  apiKeys: [],
  webHooks: [],
  clientWebHooks: [],
  transfersFilter: {},
  clientUsersFilter: {},
  alertsFilter: {},
  addressesFilter: {},
  alertsOverview: {
    unreviewed: 0,
    approved: 0,
    rejected: 0,
    frozen: 0,
    automaticallyApproved: 0,
  },
  latestAlerts: [],
  clientsOverview: {
    kyc: 0,
    analysis: 0,
  },
  transferVolumePerCategory: [],
  userOverview: {
    SEVERE: {
      percentage: "<.1%",
      count: 0,
    },
    HIGH: {
      percentage: "<.1%",
      count: 0,
    },
    MEDIUM: {
      percentage: "<.1%",
      count: 0,
    },
    LOW: {
      percentage: "<.1%",
      count: 0,
    },
  },
  kycApplicantOverview: {
    APPROVED: {
      percentage: "<.1%",
      count: 0,
    },
    REJECTED: {
      percentage: "<.1%",
      count: 0,
    },
    RETRY: {
      percentage: "<.1%",
      count: 0,
    },
    ON_HOLD: {
      percentage: "<.1%",
      count: 0,
    },
    NOT_PROCESSED: {
      percentage: "<.1%",
      count: 0,
    },
  },
  singleAlert: {},
  admins: [],
  audits: [],
  singleUserComments: [],
  spinnerStatus: false,
};
export default state;
