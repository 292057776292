<template>
  <div
    class="modal fade"
    id="addWebHookModal"
    tabindex="-1"
    aria-labelledby="addWebHookModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addWebHookModalLabel">Add WebHook</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form action="#" @submit.prevent="storeWebHook">
            <div v-if="successMessage" class="success-message">
              {{ successMessage }}
            </div>
            <div v-if="serverError.length > 0" class="server-error">
              <ul
                style="list-style-type: none; margin: 0px; padding: 0px"
                v-for="(item, index) in serverError"
                :key="index"
              >
                <li>{{ item }}</li>
              </ul>
            </div>
            <div class="mb-3 text-start">
              <label for="hook_secret_key" class="col-form-label"
                >Secret Key:</label
              >
              <input
                type="text"
                class="form-control"
                id="hook_secret_key"
                name="hook_secret_key"
                placeholder="Enter Secret Key"
                v-model="hook_secret_key"
                required
              />
            </div>
            <div class="mb-3 text-start">
              <label for="hook_link" class="col-form-label">Link:</label>
              <input
                type="text"
                class="form-control"
                id="hook_link"
                name="hook_link"
                placeholder="Enter Link"
                v-model="hook_link"
                required
              />
            </div>
            <div
              v-if="isAdmin && Object.values(getClientsWebHooks).length > 0"
              class="mb-3 text-start"
            >
              <label for="client_id" class="col-form-label"
                >Select Client:</label
              >
              <select
                class="form-control"
                id="hook_user_id"
                v-model="hook_user_id"
                name="hook_user_id"
              >
                >
                <option
                  v-for="item in getClientsWebHooks"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>

            <div class="modal-footer">
              <button
                id="closeAddModal"
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                :disabled="!buttonVisiable"
                class="btn btn-primary"
              >
                Add
              </button>
              <div></div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var $ = require("jquery");
//var vue_select = require("vue-select2");
export default {
  name: "AddWebHook",
  data() {
    return {
      hook_secret_key: "",
      hook_link: "",
      hook_user_id: 0,
      successMessage: "",
      serverError: [],
      buttonVisiable: true,
    };
  },
  computed: {
    getClientsWebHooks() {
      return this.$store.getters.getClientsWebHooks;
    },
    getProfile() {
      return this.$store.getters.getProfile;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
  watch: {
    getClientsWebHooks() {
      this.hook_user_id = this.getClientsWebHooks.filter((el) => el.name == this.getProfile.name)[0]['id'];
    },
  },
  methods: {
    storeWebHook() {
      this.buttonVisiable = false;
      this.$store
        .dispatch("storeWebHook", {
          secret_key: this.hook_secret_key,
          link: this.hook_link,
          user_id: this.hook_user_id,
        })
        .then((response) => {
          this.successMessage = response.message[0];
          setTimeout(() => {
            this.hook_secret_key = "";
            this.hook_link = "";
          }, 2000);
          this.buttonVisiable = true;
          this.$emit("refresh", true);
          $("#closeAddModal").click();
        })
        .catch((error) => {
          if (Array.isArray(error.response.data.message))
            this.serverError = error.response.data.message;
          else if (error.response.data.message)
            this.serverError = [error.response.data.message];
          else if (typeof error.response.data.errors == "string")
            this.serverError = [error.response.data.errors];
          else this.serverError = Object.values(error.response.data.errors);
          setTimeout(() => {
            this.serverError = [];
          }, 2000);
          this.buttonVisiable = true;
        });
    },
  },
};
</script>
