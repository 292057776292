<template>
  <div class="row row-sm text-start mt-5">
    <AddClient @refresh="refreshTable" />
    <EditClient :client="current_client" @refresh="refreshTable" />
    <ShowClientKeys :client="current_client" />
    <ClientLevels :client="current_client" @refresh="refreshTable" />
    <div class="col-lg-12">
      <div class="py-2 col-12 row">
        <div class="col-12 col-md-6">
          <div class="py-2 d-flex align-items-center">
            <h2 class="main-content-label mb-1">Clients</h2>
          </div>
        </div>
        <div
          class="col-12 col-md-6 d-flex justify-content-end align-items-center"
        >
          <button
            type="button"
            class="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            Add Client
          </button>
        </div>
      </div>
      <div class="card custom-card overflow-hidden">
        <div class="card-body">
          <DataTableClient
            :getClients="this.getClients"
            :refreshTableNow="this.refreshTableVar"
            @currentClient="setCurrentClient"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<style scope>
.custom-switch-indicator {
  background: #ccc !important;
}
.custom-switch-input:checked ~ .custom-switch-indicator {
  background: var(--primary-bg-color) !important;
}
.access {
  width: 130px;
  margin: 5px;
  display: inline-block;
  padding: 6px 0px;
  background-color: rgb(255, 236, 224);
  color: rgb(194, 78, 0);
}
</style>
<script>
import DataTableClient from "@/components/UserManagement/DataTableClient.vue";
import AddClient from "@/components/UserManagement/AddClient.vue";
import EditClient from "@/components/UserManagement/EditClient.vue";
import ClientLevels from "@/components/UserManagement/ClientLevels.vue";
import ShowClientKeys from "@/components/UserManagement/ShowClientKeys.vue";
export default {
  name: "UserManagement",
  components: {
    DataTableClient,
    AddClient,
    EditClient,
    ClientLevels,
    ShowClientKeys
},
  data() {
    return {
      current_client: {},
      refreshTableVar: false,
    };
  },
  mounted() {
    this.$store.dispatch("changeNavActive", "clients");
    this.loading = true;
    document.title = "ProtoAML";
    this.$store.dispatch("retrieveClients", {
      param: this.$route.query,
    });
    this.$store.dispatch("retrieveRoles");
  },
  computed: {
    getClients() {
      return this.$store.getters.getClients;
    },
  },
  methods: {
    refreshTable() {
      this.refreshTableVar = !this.refreshTableVar;
      this.$store.dispatch("retrieveClients");
    },
    setCurrentClient(value) {
      this.current_client = value;
    },
    changeActive(id) {
      this.$store
        .dispatch("changeActive", {
          id: id,
        })
        .then((response) => {
          this.$notify({ type: "success", text: response.message[0] });
        })
        .catch((error) => {
          var serverError =
            error.response.data.message == null
              ? [error.response.data.errors]
              : [error.response.data.message];
          this.$notify({ type: "wronge", text: serverError });
        });
    },
  },
};
</script>
