<template>
  <li class="one-operation">
    <div class="tail"></div>
    <div :class="'icon-operation ' + this.title.replace(/\s+/g, '-').toLowerCase()">
      <span v-if="type == 0 || type == 1" style="display: inline-block"><svg viewBox="64 64 896 896" focusable="false"
          data-icon="folder-open" width="1em" height="1em" fill="currentColor" aria-hidden="true">
          <path
            d="M928 444H820V330.4c0-17.7-14.3-32-32-32H473L355.7 186.2a8.15 8.15 0 00-5.5-2.2H96c-17.7 0-32 14.3-32 32v592c0 17.7 14.3 32 32 32h698c13 0 24.8-7.9 29.7-20l134-332c1.5-3.8 2.3-7.9 2.3-12 0-17.7-14.3-32-32-32zM136 256h188.5l119.6 114.4H748V444H238c-13 0-24.8 7.9-29.7 20L136 643.2V256zm635.3 512H159l103.3-256h612.4L771.3 768z">
          </path>
        </svg></span>
      <span v-else-if="type == 2 || type == 3" style="display: inline-block"><svg viewBox="64 64 896 896"
          focusable="false" data-icon="folder" width="1em" height="1em" fill="currentColor" aria-hidden="true">
          <path
            d="M880 298.4H521L403.7 186.2a8.15 8.15 0 00-5.5-2.2H144c-17.7 0-32 14.3-32 32v592c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V330.4c0-17.7-14.3-32-32-32zM840 768H184V256h188.5l119.6 114.4H840V768z">
          </path>
        </svg></span>
      <span v-else style="display: inline-block"><svg viewBox="64 64 896 896" focusable="false" data-icon="folder-add"
          width="1em" height="1em" fill="currentColor" aria-hidden="true">
          <path
            d="M484 443.1V528h-84.5c-4.1 0-7.5 3.1-7.5 7v42c0 3.8 3.4 7 7.5 7H484v84.9c0 3.9 3.2 7.1 7 7.1h42c3.9 0 7-3.2 7-7.1V584h84.5c4.1 0 7.5-3.2 7.5-7v-42c0-3.9-3.4-7-7.5-7H540v-84.9c0-3.9-3.1-7.1-7-7.1h-42c-3.8 0-7 3.2-7 7.1zm396-144.7H521L403.7 186.2a8.15 8.15 0 00-5.5-2.2H144c-17.7 0-32 14.3-32 32v592c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V330.4c0-17.7-14.3-32-32-32zM840 768H184V256h188.5l119.6 114.4H840V768z">
          </path>
        </svg></span>
    </div>
    <div class="content-operation">
      <a href="#" style="color: #293972">{{ title }}</a>
      <div class="on-strong mb-3">
        <strong>{{ body }}</strong>
      </div>
      <div class="style-comment">{{ comment }}</div>
    </div>
  </li>
</template>
<style scope>
.on-strong {
  color: rgba(0, 0, 0, 0.85);
  overflow-wrap: break-word;
}

.on-strong strong {
  font-weight: 600;
}

.style-comment {
  white-space: pre-wrap;
}
</style>
<script>
export default {
  name: "CommentListSingleUser",
  props: {
    singleComment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      title: this.singleComment.title,
      body: this.singleComment.body,
      comment: this.singleComment.comment,
      type: this.singleComment.type,
    };
  },
};
</script>
