<template>
  <!-- Sidebar-right-->
  <div class="sidebar sidebar-right sidebar-animate">
    <div class="row" style="width: 100%; height: 100%">
      <TableSideBar
        v-if="visible1 && this.singleAlertId"
        :singleAlert="getSingleAlert"
        @event="closeSideBar"
        :alertId="this.singleAlertId"
      />
      <CommentSectionAlert
        v-if="visible2 && isAdmin && this.singleAlertId"
        :assigneeId="this.assigneeId"
        :reviewStatus="getSingleAlert.review_status"
        :allAdmin="getAllAdmin"
        :alertId="this.singleAlertId"
      />
      <SpinnerLoader :start="!visible2" />
    </div>
  </div>
  <!--/Sidebar-right-->
  <div class="overlay" @click="closeSideBar(true)"></div>
</template>
<style scope>
.sidebar {
  width: auto;
}
.overlay {
  display: none;
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  /* full screen */
  width: 100%;
  height: 100%;
  /* transparent black */
  background: rgba(0, 0, 0, 0.7);
  /* middle layer, i.e. appears below the sidebar */
  z-index: 1006;
  opacity: 0;
  /* animate the transition */
  transition: all 0.5s ease-in-out;
}
/* display .overlay when it has the .active class */
.overlay.active {
  display: block;
  opacity: 1;
}
</style>
<script>
var $ = require("jquery");
import "bootstrap";
import SpinnerLoader from "@/components/SpinnerLoader.vue";
import CommentSectionAlert from "@/components/Alerts/CommentSectionAlert.vue";
import TableSideBar from "@/components/Alerts/TableSideBar.vue";
export default {
  name: "SideBarRightAlert",
  props: {
    alertId: [String, Number],
  },
  emits: ["alertIdToZero"],
  data() {
    return {
      visible1: false,
      visible2: false,
      assigneeId: 0,
      singleAlertId: 0,
    };
  },
  components: {
    CommentSectionAlert,
    TableSideBar,
    SpinnerLoader,
  },
  mounted() {
    $(".sidebar-right").addClass("sidebar-open");
    $(".overlay").addClass("active");
    this.visible1 = false;
    this.$store.dispatch("retrieveSingleAlert", {
      singleAlertId: this.alertId,
    });
    if (this.isAdmin) {
      this.$store.dispatch("retrieveAllAdmin");
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    getSingleAlert() {
      return this.$store.getters.getSingleAlert;
    },
    getAllAdmin() {
      return this.$store.getters.getAllAdmin;
    },
  },
  watch: {
    getSingleAlert() {
      this.visible1 = true;
      setTimeout(() => {
        this.assigneeId = this.getSingleAlert.assignee_id??0
        this.singleAlertId = this.getSingleAlert.id??0
        this.visible2 = true;
      }, 400);
    },
  },
  methods: {
    closeSideBar(value) {
      if (value == true) {
        $(".sidebar-right").removeClass("sidebar-open");
        // hide overlay
        $(".overlay").removeClass("active");

        if (
          this.$route.name != "admin-single-user" &&
          this.$route.name != "single-user"
        ) {
          this.$router.go(-1);
        } else {
          this.$emit("alertIdToZero", 0);
        }
      }
    },
  },
};
</script>
