<template>
  <div class="panel panel-primary card mb-0 box-shadow p-0">
    <div
      class="tab-menu-heading card-img-top-1 border-0 p-3 m-0"
      style="position: relative; display: block; background: rgb(224 255 231)"
    >
      <div class="card-options ms-auto">
        <a
          href="javascript:void(0);"
          @click="backToClose"
          class="sidebar-remove"
          ><i class="fe fe-x"></i
        ></a>
      </div>
      <h2>
        Alert {{ this.alertId }}
        <a
          href="javascript:void(0)"
          class="conv-link"
          :id="'copy_alert_' + this.alertId"
          @click="defineCopyAlert(this.alertId)"
          @mouseout="backToOriginAlert(this.alertId)"
          ><svg
            viewBox="64 64 896 896"
            focusable="false"
            data-icon="link"
            width="1em"
            height="1em"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              d="M574 665.4a8.03 8.03 0 00-11.3 0L446.5 781.6c-53.8 53.8-144.6 59.5-204 0-59.5-59.5-53.8-150.2 0-204l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3l-39.8-39.8a8.03 8.03 0 00-11.3 0L191.4 526.5c-84.6 84.6-84.6 221.5 0 306s221.5 84.6 306 0l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3L574 665.4zm258.6-474c-84.6-84.6-221.5-84.6-306 0L410.3 307.6a8.03 8.03 0 000 11.3l39.7 39.7c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c53.8-53.8 144.6-59.5 204 0 59.5 59.5 53.8 150.2 0 204L665.3 562.6a8.03 8.03 0 000 11.3l39.8 39.8c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c84.5-84.6 84.5-221.5 0-306.1zM610.1 372.3a8.03 8.03 0 00-11.3 0L372.3 598.7a8.03 8.03 0 000 11.3l39.6 39.6c3.1 3.1 8.2 3.1 11.3 0l226.4-226.4c3.1-3.1 3.1-8.2 0-11.3l-39.5-39.6z"
            ></path></svg
        ></a>
      </h2>
      <br />
      <div class="card-title mb-0">
        User
        <router-link
          class="user-link"
          v-if="isAdmin"
          :to="{
            name: 'admin-single-user',
            params: { userId: this.singleAlert.internal_uid },
          }"
        >
          {{ this.singleAlert.internal_uid }}
        </router-link>
        <router-link
          class="user-link"
          v-if="!isAdmin"
          :to="{
            name: 'single-user',
            params: { userId: this.singleAlert.internal_uid },
          }"
        >
          {{ this.singleAlert.partner_uid }}
        </router-link>
      </div>
    </div>
    <div class="panel-body m-0 tabs-menu-body latest-tasks p-0 border-0">
      <table class="alert-detail">
        <tr>
          <td style="font-weight: bold">Severity</td>
          <td>{{ this.singleAlert.severity }}</td>
        </tr>
        <tr>
          <td style="font-weight: bold">Alert Amount</td>
          <td>{{ this.singleAlert.alert_amount }}</td>
        </tr>
        <tr>
          <td style="font-weight: bold">Asset Amount</td>
          <td>{{ this.singleAlert.asset_amount }}</td>
        </tr>
        <tr>
          <td style="font-weight: bold">Asset</td>
          <td>{{ this.singleAlert.asset }}</td>
        </tr>
        <tr>
          <td style="font-weight: bold">Alert Type</td>
          <td>{{ this.singleAlert.alert_type }}</td>
        </tr>
        <tr>
          <td style="font-weight: bold">Alert Category</td>
          <td>{{ this.singleAlert.alert_category }}</td>
        </tr>
        <tr>
          <td style="font-weight: bold">Service Name</td>
          <td>
            {{ this.singleAlert.service_name }}
          </td>
        </tr>
        <tr>
          <td style="font-weight: bold">Exposure</td>
          <td>{{ this.singleAlert.exposure }}</td>
        </tr>
        <tr>
          <td style="font-weight: bold">Direction</td>
          <td>{{ this.singleAlert.direction }}</td>
        </tr>
        <tr v-if="this.singleAlert.alert_created_at">
          <td style="font-weight: bold">Alert Created At</td>
          <td>
            {{
              this.singleAlert.alert_created_at
            }}
          </td>
        </tr>
        <tr v-if="this.singleAlert.attempt_at">
          <td style="font-weight: bold">Attempt At</td>
          <td>
            {{
              this.singleAlert.attempt_at
            }}
          </td>
        </tr>
        <tr>
          <td style="font-weight: bold">Alert Triggered By</td>
          <td>{{ this.singleAlert.alert_triggered_by }}</td>
        </tr>
        <tr v-if="this.singleAlert.withdrawal_address">
          <td style="font-weight: bold">Withdrawal Address</td>
          <td>
            <input
              :id="'myinput_' + this.alertId"
              type="text"
              style="display: none"
              readonly
              :value="this.singleAlert.withdrawal_address"
            />
            {{ this.singleAlert.withdrawal_address.slice(0, 10) + "..." }}
            <div class="toolpop">
              <i
                :id="'copy_' + this.alertId"
                class="fas fa-copy"
                @click="copy(this.alertId)"
                @mouseover="defineCopy(this.alertId)"
                @mouseout="backToOrigin(this.alertId)"
                ><!--span
                  class="toolpoptext"
                  :id="'myToolpop_' + this.alertId"
                  >Copy to clipboard</span
                --></i
              >
            </div>
          </td>
        </tr>
        <tr v-if="this.singleAlert.tx_hash">
          <td style="font-weight: bold">Tx Hash</td>
          <td>
            <input
              :id="'myinput_' + this.alertId"
              type="text"
              style="display: none"
              readonly
              :value="this.singleAlert.tx_hash"
            />
            {{ this.singleAlert.tx_hash.slice(0, 10) + "..." }}
            <div class="toolpop">
              <i
                :id="'copy_' + this.alertId"
                class="fas fa-copy"
                @click="copy(this.alertId)"
                @mouseover="defineCopy(this.alertId)"
                @mouseout="backToOrigin(this.alertId)"
                ><!--span
                  class="toolpoptext"
                  :id="'myToolpop_' + this.alertId"
                  >Copy to clipboard</span
                --></i
              >
            </div>
          </td>
        </tr>
        <tr v-if="this.singleAlert.output_address">
          <td style="font-weight: bold">Output Address</td>
          <td>
            <input
              :id="'myinput_' + this.alertId + '_out'"
              type="text"
              style="display: none"
              readonly
              :value="this.singleAlert.output_address"
            />
            {{ this.singleAlert.output_address.slice(0, 10) + "..." }}
            <div class="toolpop">
              <i
                :id="'copy_' + this.alertId + '_out'"
                class="fas fa-copy"
                @click="copy(this.alertId + '_out')"
                @mouseover="defineCopy(this.alertId + '_out')"
                @mouseout="backToOrigin(this.alertId + '_out')"
                ><!--span
                  class="toolpoptext"
                  :id="'myToolpop_' + this.alertId"
                  >Copy to clipboard</span
                --></i
              >
            </div>
          </td>
        </tr>
        <tr v-if="this.isAdmin">
          <td style="font-weight: bold">Reference Url</td>
          <td>
            <a
              style="text-decoration: underline"
              target="_blank"
              :href="
                'https://kyt.chainalysis.com/alerts/' +
                this.singleAlert.external_id
              "
              >Click here</a
            >
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<style scope>
.user-link {
  text-decoration: underline;
}
.alert-detail {
  margin-left: 20px;
  margin-top: 20px;
}
.alert-detail tr td {
  padding-top: 11px;
  padding-bottom: 12px;
  font-size: 14px;
  line-height: 1.5715 !important;
  width: 300px;
  overflow: hidden;
}
.conv-link {
  cursor: pointer;
}
.alert-detail tr td:nth-child(odd) {
  text-align: left;
}
tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: #ededf5;
  border-style: none;
  border-width: 0;
  border-bottom: 1px solid #f0f0f0;
}
</style>
<script>
var $ = require("jquery");
export default {
  name: "TableSideBar",
  props: {
    singleAlert: {
      type: Object,
      required: true,
    },
    alertId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
  mounted() {
    if (this.isAdmin) $(".panel-primary").addClass("col-6");
  },
  methods: {
    copy(ind) {
      var text = document.getElementById("myinput_" + ind).value;
      var listener = function (ev) {
        ev.clipboardData.setData("text/plain", text);
        ev.preventDefault();
      };
      document.addEventListener("copy", listener);
      document.execCommand("copy");
      document.removeEventListener("copy", listener);
      $("#copy_" + ind).removeClass("fa-copy");
      $("#copy_" + ind).addClass("fa-check");
      document.getElementById("copy_" + ind).innerHTML = " Copied";
      /*var toolpop = document.getElementById("myToolpop_" + ind);
      toolpop.innerHTML = "Copied: " + text.slice(0, 10);
      toolpop.style.visibility = "visible";
      toolpop.style.opacity = 1;*/
    },
    defineCopy(ind) {
      $("#copy_" + ind).removeClass("fa-copy");
      $("#copy_" + ind).addClass("fa-check");
    },
    backToOrigin(ind) {
      $("#copy_" + ind).removeClass("fa-check");
      $("#copy_" + ind).addClass("fa-copy");
      document.getElementById("copy_" + ind).innerHTML = "";
    },
    backToClose() {
      this.$emit("event", true);
    },
    defineCopyAlert(ind) {
      var text = window.location.href;
      var listener = function (ev) {
        ev.clipboardData.setData("text/plain", text);
        ev.preventDefault();
      };
      document.addEventListener("copy", listener);
      document.execCommand("copy");
      document.removeEventListener("copy", listener);
      document.getElementById("copy_alert_" + ind).innerHTML =
        '<i class="fas fa-check"> Copied</i>';
    },
    backToOriginAlert(ind) {
      document.getElementById("copy_alert_" + ind).innerHTML =
        '<svg viewBox="64 64 896 896" focusable="false" data-icon="link" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M574 665.4a8.03 8.03 0 00-11.3 0L446.5 781.6c-53.8 53.8-144.6 59.5-204 0-59.5-59.5-53.8-150.2 0-204l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3l-39.8-39.8a8.03 8.03 0 00-11.3 0L191.4 526.5c-84.6 84.6-84.6 221.5 0 306s221.5 84.6 306 0l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3L574 665.4zm258.6-474c-84.6-84.6-221.5-84.6-306 0L410.3 307.6a8.03 8.03 0 000 11.3l39.7 39.7c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c53.8-53.8 144.6-59.5 204 0 59.5 59.5 53.8 150.2 0 204L665.3 562.6a8.03 8.03 0 000 11.3l39.8 39.8c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c84.5-84.6 84.5-221.5 0-306.1zM610.1 372.3a8.03 8.03 0 00-11.3 0L372.3 598.7a8.03 8.03 0 000 11.3l39.6 39.6c3.1 3.1 8.2 3.1 11.3 0l226.4-226.4c3.1-3.1 3.1-8.2 0-11.3l-39.5-39.6z"></path></svg>';
    },
  },
};
</script>
