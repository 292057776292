<template>
  <div class="page-single">
    <div class="container">
      <div class="row">
        <div
          class="col-xl-5 col-lg-6 col-md-8 col-sm-8 col-xs-10 card-sigin-main mx-auto my-auto py-4 justify-content-center"
        >
          <div class="card-sigin">
            <!-- Demo content-->
            <div class="main-card-signin d-md-flex">
              <div class="w-100">
                <div class="d-flex mb-4 justify-content-center">
                  <a href="index.html"
                    ><img
                      src="../../assets/img/PROTOAML.png"
                      class="sign-favicon ht-40"
                      alt="logo"
                  /></a>
                </div>
                <div class="">
                  <div class="main-signup-header">
                    <h2>Whoops!</h2>
                    <h6 class="font-weight-semibold mb-4">
                      404 Page Not Found
                    </h6>
                    <SpinnerLoader :start="true" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SpinnerLoader from "@/components/SpinnerLoader.vue";

export default {
  name: "NotFound",
  mounted() {
    document.body.classList.add("bg-primary");
    document.title = "404 Not Found";
  },
  components: {
    SpinnerLoader,
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
  },
  created() {
    setTimeout(() => {
      if(this.loggedIn)
        this.$router.push({
          name: (this.isAdmin ? "admin-" : "") + "dashboard",
        });
      else
        this.$router.push({
          name: "home",
        });
    }, 2000);
  },
};
</script>
