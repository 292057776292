<template>
  <div class="page-single">
    <div class="container">
      <div class="row">
        <div
          class="col-xl-5 col-lg-6 col-md-8 col-sm-8 col-xs-10 card-sigin-main mx-auto my-auto py-4 justify-content-center"
        >
          <div class="card-sigin">
            <!-- Demo content-->
            <div class="main-card-signin d-md-flex">
              <div class="w-100">
                <div class="d-flex mb-4 justify-content-center">
                  <a href="index.html"
                    ><img
                      :src="'../../assets/img/PROTOAML.png'"
                      class="sign-favicon ht-40"
                      alt="logo"
                  /></a>
                </div>
                <div class="">
                  <div class="main-signup-header">
                    <h2>Welcome back!</h2>
                    <h6 class="font-weight-semibold mb-4">
                      Please sign in to continue.
                    </h6>
                    <div class="panel panel-primary">
                      <div class="panel-body tabs-menu-body border-0 p-3">
                        <div class="tab-content">
                          <form action="#" @submit.prevent="login">
                            <div
                              v-if="serverError.length > 0"
                              class="server-error"
                            >
                              <ul
                                style="
                                  list-style-type: none;
                                  margin: 0px;
                                  padding: 0px;
                                "
                                v-for="(item, index) in serverError"
                                :key="index"
                              >
                                <li>{{ item }}</li>
                              </ul>
                            </div>
                            <div class="form-group">
                              <label>Email</label>
                              <input
                                v-model="email"
                                class="form-control"
                                placeholder="Enter your email"
                                type="email"
                                name="email"
                                id="email"
                                require
                              />
                            </div>
                            <div class="form-group">
                              <label>Password</label>
                              <input
                                v-model="password"
                                class="form-control"
                                placeholder="Enter your password"
                                type="password"
                                name="password"
                                id="password"
                                require
                              />
                            </div>
                            <button class="btn btn-primary btn-block">
                              <div class="lds-ellipsis" v-if="loading">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                              <span v-if="!loading">Sign In</span>
                            </button>
                            <div
                              class="mt-4 d-flex mx-auto text-center justify-content-center mb-2"
                            ></div>
                          </form>
                        </div>
                      </div>
                    </div>

                    <div class="main-signin-footer text-center mt-3">
                      <p>
                        <a href="/forgot/password" class="mb-3"
                          >Forgot password?</a
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.form-group label {
  display: block;
  text-align: start;
}
</style>
<script>
export default {
  name: "AdminLoginView",
  props: {
    logoutSuccess: {
      type: String,
    },
  },
  data() {
    return {
      email: "",
      password: "",
      serverError: [],
      loading: false,
    };
  },
  mounted() {
    document.body.classList.add("bg-primary");
    if (this.logoutSuccess)
      if (this.$store.type != "")
        this.$notify({ type: "success", text: this.logoutSuccess });
  },
  methods: {
    login() {
      this.loading = true;
      this.$store
        .dispatch("retrieveToken", {
          email: this.email,
          password: this.password,
          type: "admins",
        })
        .then((response) => {
          this.loading = false;
          this.$store.dispatch("changeModule", "KYT");
          this.$router.push({
            name: "admin-dashboard",
            params: { loginSuccess: response.message },
          });
        })
        .catch((error) => {
          this.loading = false;
          if (Array.isArray(error.response.data.message))
            this.serverError = error.response.data.message;
          else if (error.response.data.message)
            this.serverError = [error.response.data.message];
          else if (typeof error.response.data.errors == "string")
            this.serverError = [error.response.data.errors];
          else this.serverError = Object.values(error.response.data.errors);
          this.password = "";
        });
    },
  },
};
</script>
