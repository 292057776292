<template>
  <div
    class="panel panel-primary card mb-0 box-shadow col-12"
    style="padding: 25px"
  >
    <div class="scro">
      <div
        v-if="visible"
        class="panel-body tabs-menu-body latest-tasks p-0 border-0"
      >
        <div v-if="successMessage" class="success-message">
          {{ successMessage }}
        </div>
        <div v-if="serverError.length > 0" class="server-error">
          <ul
            style="list-style-type: none; margin: 0px; padding: 0px"
            v-for="(item, index) in serverError"
            :key="index"
          >
            <li>{{ item }}</li>
          </ul>
        </div>
        <div class="form-group">
          <textarea
            v-model="comment"
            class="form-control"
            id="exampleFormControlTextarea1"
            rows="3"
            placeholder="Write your commit here"
            name="comment"
          ></textarea>
        </div>
        <div class="form-inline justify-content-end">
          <button
            @click="postStatus"
            id="btn-status"
            class="btn btn-primary mb-2 mx-2"
          >
            {{ this.wordButton }}
          </button>
          <button
            @click="postComment"
            id="btn-form"
            class="btn btn-primary mb-2"
            :disabled="this.comment == ''"
          >
            Comment
          </button>
        </div>
        <div v-if="visible" style="max-width: 300px">
          <ul class="operation">
            <CommentListSingleUser
              v-for="item in getAllComments"
              :key="item.id"
              :singleComment="item"
            />
          </ul>
        </div>
      </div>
      <SpinnerLoader :start="!visible" />
    </div>
  </div>
</template>

<style scoped>
.scro {
  flex-grow: 1;
  padding: 10px;
  height: 150px;
  overflow: auto;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}

.operation {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  -webkit-font-feature-settings: "tnum", "tnum";
  font-feature-settings: "tnum", "tnum";
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: left;
}

.one-operation {
  position: relative;
  margin: 0;
  padding-bottom: 20px;
  font-size: 14px;
  list-style: none;
  box-sizing: border-box;
}

.icon-operation {
  position: absolute;
  top: 5.5px;
  left: 5px;
  width: auto;
  height: auto;
  margin-top: 0;
  padding: 3px 1px;
  line-height: 1;
  text-align: center;
  border: 0;
  border-radius: 0;
  transform: translate(-50%, -50%);
}

.tail {
  position: absolute;
  top: 18px;
  left: 4px;
  height: calc(100% - 25px);
  border-left: 2px solid #c7c7c7;
}

.one-operation:last-child .tail {
  display: none;
}

.content-operation {
  position: relative;
  top: -7.001px;
  margin: 0 0 0 26px;
  word-break: break-word;
}

.form-control {
  border-color: gray;
}

.char-ad {
  background: rgb(204, 204, 204);
  border-radius: 50px;
  width: 39px;
  height: 35px;
  display: flex;
  /* padding: 3px; */
  color: rgb(255, 255, 255);
  font-size: 19px;
  justify-content: center;
  align-items: center;
  margin-bottom: 13px;
  margin-top: 10px;
}
</style>

<script>
import SpinnerLoader from "@/components/SpinnerLoader.vue";
import CommentListSingleUser from "@/components/SingleUser/CommentListSingleUser.vue";
export default {
  name: "CommentSectionSingleUser",
  props: {
    loading: Boolean,
  },
  components: {
    SpinnerLoader,
    CommentListSingleUser,
  },
  data() {
    return {
      comment: "",
      wordButton: "",
      status: 0,
      successMessage: "",
      serverError: [],
      visible: false,
    };
  },
  computed: {
    getAllComments() {
      return this.$store.getters.getAllComments;
    },
    getSingleUser() {
      return this.$store.getters.getSingleUser;
    },
  },
  watch: {
    getSingleUser() {
      this.status = this.getSingleUser.comment_status == 0 ? 2 : 0;
      this.wordButton = this.status == 0 ? "Open" : "Close";
      this.$store.dispatch("retrieveAllSingleUserComments", {
        userId: this.getSingleUser.id,
      });
    },
    getAllComments() {
      this.visible = true;
      this.serverError = [];
      this.successMessage = "";
    },
    loading() {
      this.visible = false;
      this.$store.dispatch("retrieveAllSingleUserComments", {
        userId: this.getSingleUser.id,
      });
    },
    comment() {
      if (this.comment != "") {
        this.wordButton =
          this.status == 0 || this.status == 1
            ? "Open and comment"
            : "Close and comment";
        this.status = this.status == 0 || this.status == 1 ? 1 : 3;
      } else {
        this.wordButton =
          this.status == 0 || this.status == 1 ? "Open" : "Close";
        this.status = this.status == 0 || this.status == 1 ? 0 : 2;
      }
    },
  },
  methods: {
    postStatus() {
      document.getElementById("btn-status").innerHTML =
        "<div class='lds-ellipsis' ><div></div><div></div><div></div><div></div></div>";
      this.$store
        .dispatch("addCommentToSingleUser", {
          userId: this.getSingleUser.id,
          status: this.status,
          comment: this.comment,
        })
        .then((response) => {
          this.comment = "";
          this.status = this.status == 0 || this.status == 1 ? 2 : 0;
          this.wordButton = this.status == 0 ? "Open" : "Close";
          this.successMessage = response.message[0];
          this.$store.dispatch("retrieveAllSingleUserComments", {
            userId: this.getSingleUser.id,
          });
        })
        .catch((error) => {
          console.log(error);
          this.serverError = ["something wrong"];
        });
    },
    postComment() {
      document.getElementById("btn-form").innerHTML =
        "<div class='lds-ellipsis' ><div></div><div></div><div></div><div></div></div>";
      this.$store
        .dispatch("addCommentToSingleUser", {
          userId: this.getSingleUser.id,
          status: 4,
          comment: this.comment,
        })
        .then((response) => {
          this.comment = "";
          document.getElementById("btn-form").innerHTML = "Comment";
          this.successMessage = response.message[0];
          this.$store.dispatch("retrieveAllSingleUserComments", {
            userId: this.getSingleUser.id,
          });
        })
        .catch((error) => {
          console.log(error);
          this.serverError = ["something wrong"];
        });
    },
  },
};
</script>
