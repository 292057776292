<template>
  <div>
    <table
      id="datatable_transfers"
      class="table table-bordered text-nowrap key-buttons border-bottom"
      style="text-align: center"
    >
      <thead>
        <tr>
          <th class="border-bottom-0" style="padding-left: 3rem">Date</th>
          <th class="border-bottom-0">Category</th>
          <th class="border-bottom-0">Name</th>
          <th class="border-bottom-0">Direction</th>
          <th class="border-bottom-0">Risky Amount</th>
          <th class="border-bottom-0">Amount</th>
          <th class="border-bottom-0">Asset</th>
        </tr>
      </thead>
      <tbody v-if="loading">
        <tr class="old">
          <td valign="top" colspan="7" class="dataTables_empty">
            <SpinnerLoader :start="loading" />
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="Object.values(getTransfers).length">
        <tr
          v-for="(transfer, index) in getTransfers"
          :key="index"
          :id="'transfer_' + transfer.id"
        >
          <td class="toggle_icon row align-items-center" style="padding-right: 1px;">
            <li
              @click="showDetails(transfer, transfer.id)"
              class="icons-list-item cursor-pointer"
              style="cursor: pointer;border: 0px;"
            >
              <i class="fa fa-plus-circle text-primary"></i>
            </li>
            {{ transfer.timestamp }}
            <div class="update-toolpop">
              <i
                v-if="transfer.updatedAt == null"
                :id="'updateId_' + transfer.id"
                @click="updateTransfer(transfer.id, transfer.externalId)"
                @mouseover="defineCopyUpdate(transfer.id)"
                @mouseout="backToOriginUpdate(transfer.id)"
                class="fa fa-refresh"
                aria-hidden="true"
                >
              </i>
              <span
                  class="update-toolpoptext"
                  :id="'updateMyToolpop_' + transfer.id"
                ></span>
              <span
                v-if="transfer.updatedAt == null"
                style="color: red; font-size: 10px; margin-left: 10px"
                >Not Proccesed!!</span
              >
            </div>
          </td>
          <td @click="showDetails(transfer, transfer.id)">
            {{ transfer.category }}
          </td>
          <td @click="showDetails(transfer, transfer.id)">
            {{ transfer.name }}
          </td>
          <td @click="showDetails(transfer, transfer.id)">
            {{ transfer.direction }}
          </td>
          <td @click="showDetails(transfer, transfer.id)">
            {{ transfer.risky_amount }}
          </td>
          <td @click="showDetails(transfer, transfer.id)">
            {{ transfer.amount }}
          </td>
          <td @click="showDetails(transfer, transfer.id)">
            {{ transfer.asset }}
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr class="old">
          <td valign="top" colspan="7" class="dataTables_empty">
            No matching records found
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<style scope>
.link-hover {
  text-decoration: underline;
}
.link-hover:hover {
  text-decoration: none;
}
.toolpop {
  position: relative;
  display: inline-block;
}

.toolpop .toolpoptext {
  visibility: hidden;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 11px;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.toolpop .toolpoptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.pop {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}
.fa-refresh {
  font-size: 20px;
}
.update-toolpop {
  position: relative;
  display: inline-block;
}

.update-toolpop .update-toolpoptext {
  visibility: hidden;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 10%;
  left: 108px;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
  font-family: Avenir,Helvetica,Arial,sans-serif;
  font-size: 11px;
}
.update-toolpop .update-toolpoptext::after {
  content: "";
  position: absolute;
  top: 31%;
  left: 0%;
  margin-left: -10px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #555 transparent transparent;
}
</style>
<script>
import SpinnerLoader from "@/components/SpinnerLoader.vue";

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
var $ = require("jquery");

export default {
  name: "DataTableTransfer",
  props: {
    getTransfers: Object,
    visible: Boolean,
  },
  components: {
    SpinnerLoader,
  },
  data() {
    return {
      table: null,
      loading: true,
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    getRouteName() {
      return this.$store.getters.getRouteName;
    },
  },
  watch: {
    getTransfers() {
      if (this.table == null && Object.values(this.getTransfers).length)
        setTimeout(() => {
          this.table = $("#datatable_transfers").DataTable({
            scrollX: true,
            searching: false,
            paging: false,
            info: false,
            ordering: false,
            dom: "Bfrltip",
            buttons: ["csv", "print", "copy"],
          });
        }, 100);
      else if (this.table != null && Object.values(this.getTransfers).length) {
        //this.table = null;
        $('#datatable_transfers').DataTable().clear().destroy();
        setTimeout(() => {
          this.table = $("#datatable_transfers").DataTable({
            scrollX: true,
            searching: false,
            paging: false,
            info: false,
            ordering: false,
            dom: "Bfrltip",
            buttons: ["csv", "print", "copy"],
          });
        }, 100);
      }
      this.loading = false;
    },
    visible() {
      this.loading = true;
    },
  },
  methods: {
    format(item) {
      let timestamp = "—";
      let amount = "—";
      let output_address = "—";
      let tx_hash = "—";
      let path = "";
      if (item.timestamp != null) timestamp = item.timestamp;
      if (item.amount != null) amount = item.amount;
      if (item.output_address != null) output_address = item.output_address;
      if (item.tx_hash != null) tx_hash = item.tx_hash;
      if (this.isAdmin) {
        path = "/admin/users/" + item.user_id;
      } else {
        path = "/users/" + item.user_id;
      }

      return (
        "<tr id='child_transfer_" +
        item.id +
        "'>" +
        "<td colspan='7' >" +
        "<div class='container mx-4' >" +
        "<div class='row col-12'>" +
        "<div class='col-12 mb-5'>" +
        "<h3 class='pb-3'>Details</h3>" +
        "<div class='row col-12'>" +
        "<div class='col-12 col-md-6'>" +
        "<ul class='list-unstyled'>" +
        "<li class='d-flex text-start mb-3'>" +
        "<span class='text-bold'>Timestamp</span>" +
        "<span class='px-5 '>" +
        timestamp +
        "</span></li>" +
        "<li class='d-flex text-start mb-3'>" +
        "<span class='text-bold'>User ID</span>" +
        "<span class='px-5'><a class='link-hover' href=" +
        path +
        ">" +
        (this.isAdmin == true ? item.user_id : item.partner_id) +
        "</a></span></li>" +
        "<li class='d-flex text-start mb-3'>" +
        "<span class='text-bold'>Direction</span>" +
        "<span class='px-5'>" +
        item.direction +
        "</span></li>" +
        "<li class='special d-flex text-start mb-3'>" +
        "<span class='text-bold'>Tx Hash</span>" +
        "<span class='mx-5'" +
        ">" +
        "<input " +
        "id=myinput_tx_" +
        item.id +
        " type=text style='display: none' readonly " +
        "value=" +
        tx_hash +
        " />" +
        tx_hash.slice(0, 20) +
        "..." +
        "<div class='toolpop'>" +
        "<i class='fas fa-copy' " +
        "id=handle_tx_" +
        item.id +
        ">" +
        "<span class='toolpoptext'" +
        " id=myToolpop_tx_" +
        item.id +
        ">Copy to clipboard</span>" +
        "</i></div>" +
        "</span>" +
        "</li></ul></div>" +
        "<div class='col-12 col-md-6'>" +
        "<ul class='list-unstyled'>" +
        "<li class='d-flex text-start mb-3'>" +
        "<span class='text-bold'>Risky Amount</span>" +
        "<span class='px-5'>" +
        item.risky_amount +
        "</span></li>" +
        "<li class='d-flex text-start mb-3'>" +
        "<span class='text-bold'>Amount</span>" +
        "<span class='px-5'>" +
        amount +
        "</span></li>" +
        "<li class='d-flex text-start mb-3'>" +
        "<span class='text-bold'>Asset</span>" +
        "<span class='px-5'>" +
        item.asset +
        "</span></li>" +
        "<li class='d-flex text-start mb-3'>" +
        "<span class='text-bold'>Output Address</span>" +
        "<span class='mx-5'" +
        ">" +
        "<input " +
        "id=myinput_address_" +
        item.id +
        " type=text style='display: none' readonly " +
        "value=" +
        output_address +
        " />" +
        output_address.slice(0, 20) +
        "..." +
        "<div class='toolpop'>" +
        "<i class='fas fa-copy' " +
        "id=handle_address_" +
        item.id +
        ">" +
        "<span class='toolpoptext'" +
        " id=myToolpop_address_" +
        item.id +
        ">Copy to clipboard</span>" +
        "</i></div>" +
        "</span>" +
        "</li></ul></div></div></div></div>" +
        "<div class='row col-12'>" +
        /*"<div class='col-12 mb-5'>" +
        "<h3 class='pb-3'>Indirect Exposure</h3>" +
        "<table class='col-12'>" +
        "<thead>" +
        "<tr>" +
        "<th>Category</th>" +
        "<th>Name</th>" +
        "<th>Amount</th>" +
        "</tr></thead>" +
        "<tbody>" +
        "<tr>" +
        "<td>" +
        item.category +
        "</td>" +
        "<td>" +
        item.name +
        "</td>" +
        "<td>" +
        item.riskyAmountUSD +
        "</td></tr></tbody></table></div>" +*/
        "</div></div></td></tr>"
      );
    },
    showDetails(item, id) {
      /*var tr = $("#transfer_" + id).closest("tr");
      var row = this.table.row(tr);
      //$("#handle_" + id).click(this.copy(id));
      console.log(tr);
      var toggle = "#transfer_" + id + " td.toggle_icon li i";
      if (row.child.isShown()) {
        row.child.hide();
        tr.removeClass("shown");
        $(toggle).removeClass("fa fa-minus-circle");
        $(toggle).addClass("fa fa-plus-circle text-primary");
      } else {
        console.log(row);
        row.child(this.format(item)).show();
        tr.addClass("shown");

        $(toggle).removeClass("fa fa-plus-circle text-primary");
        $(toggle).addClass("fa fa-minus-circle");*/
      var tr = document.getElementById("child_transfer_" + id);
      var toggle = "#transfer_" + id + " td.toggle_icon li i";
      if (tr != null) {
        $("#child_transfer_" + id).remove();
        $(toggle).removeClass("fa fa-minus-circle");
        $(toggle).addClass("fa fa-plus-circle text-primary");
      } else {
        $("#transfer_" + id).after(this.format(item));
        $(toggle).removeClass("fa fa-plus-circle text-primary");
        $(toggle).addClass("fa fa-minus-circle");
        var special_id = "";
        for (var i = 0; i < 2; i++) {
          if (i == 0) special_id = "tx_" + id;
          else special_id = "address_" + id;
          var clipboard = document.getElementById("handle_" + special_id);

          clipboard.addEventListener("click", function () {
            special_id = event.target.id.substring(7);

            var text = document.getElementById("myinput_" + special_id).value;
            var listener = function (ev) {
              ev.clipboardData.setData("text/plain", text);
              ev.preventDefault();
            };
            document.addEventListener("copy", listener);
            document.execCommand("copy");
            document.removeEventListener("copy", listener);
            var toolpop = document.getElementById("myToolpop_" + special_id);
            toolpop.innerHTML = "Copied: " + text.slice(0, 20);
            toolpop.style.visibility = "visible";
            toolpop.style.opacity = 1;
          });

          clipboard.addEventListener("mouseover", function () {
            special_id = event.target.id.substring(7);
            var toolpop = document.getElementById("myToolpop_" + special_id);
            if (toolpop) {
              toolpop.innerHTML = "Copy to clipboard";
              toolpop.style.visibility = "visible";
              toolpop.style.opacity = 1;
            }
          });

          clipboard.addEventListener("mouseout", function () {
            special_id = event.target.id.substring(7);
            var toolpop = document.getElementById("myToolpop_" + special_id);
            if (toolpop) {
              toolpop.style.visibility = "hidden";
              toolpop.style.opacity = 0;
            }
          });
        }
      }
      //var clipboard = $("#handle_" + id);
    },
    updateTransfer(transferId, extId) {
      var toolpoph = document.getElementById("updateMyToolpop_" + transferId);
      toolpoph.style.visibility = "hidden";
      toolpoph.style.opacity = 0;
      $("#updateId_" + transferId).addClass("fa-spin");
      this.$store
        .dispatch( (this.getRouteName == "transfers" || this.getRouteName == "admin-transfers") ? "retrieveTransferSummary" : "retrieveSingleUserTransferSummary" , {
          externalId: extId,
        })
        .then(() => {
          $("#updateId_" + transferId).removeClass("fa-spin");
          var toolpop2 = document.getElementById(
            "updateMyToolpop_" + transferId
          );
          if (toolpop2) {
            toolpop2.innerHTML = "Not Updated Yet!!";
            toolpop2.style.visibility = "visible";
            toolpop2.style.opacity = 1;
          }
        })
        .catch(() => {
          $("#updateId_" + transferId).removeClass("fa-spin");
          var toolpop = document.getElementById(
            "updateMyToolpop_" + transferId
          );
          toolpop.innerHTML = "Something Wronge!!";
          toolpop.style.visibility = "visible";
          toolpop.style.opacity = 1;
        });
    },
    defineCopyUpdate(ind) {
      var toolpop = document.getElementById("updateMyToolpop_" + ind);
      toolpop.innerHTML = "Update Transfer Content";
      toolpop.style.visibility = "visible";
      toolpop.style.opacity = 1;
    },
    backToOriginUpdate(ind) {
      var toolpop = document.getElementById("updateMyToolpop_" + ind);
      toolpop.style.visibility = "hidden";
      toolpop.style.opacity = 0;
    },
  },
};
</script>
